import { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { SimpleTableLayout } from "../../../components/layouts";
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ActionsButton } from "../../../components";
import Tabla from "../../../components/Tabla";

const Estatus = () => {
  const endPoint = 'estatus';
  let tablaRef = useRef(null);

  const [buscarParams, setBuscarParams] = useState({});

  const titulo = "Estatus";
  const url = "/gestion-general/estatus";
  const history = useHistory();

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/estatus",
    },
  ];

  const buttonData = {
    text: "Agregar Estatus",
    to: () => history.push(url + "/nuevo"),
    icon: <PlusOutlined />,
    props: { disabled: false, type: "primary" },
  };

  const columns = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      width: 120,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idEstatus}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              //onClick: () => modalEliminar(item),
              styleProps: { color: "#ff4d4f" },
            },
          ]}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idEstatus}`}>
          {`${item?.nombre}`}
        </Link>
      )
    },
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idEstatus}`}>
          {`${item?.clave}`}
        </Link>
      )
    },
  ];

  const onSearch = async (search) => {
    let params = {...buscarParams};
    if (search.length > 0) {
      params.buscar = search
    } else {
      delete params.buscar;
    }
    setBuscarParams(params)
  };

  // const modalEliminar = (valor) => {
  //   Modal.confirm({
  //     title: "Eliminar",
  //     icon: <CloseCircleOutlined style={{ color: "red" }} />,
  //     content: `¿Desea eliminar el estatus "${valor?.nombre}"?`,
  //     okText: "Sí",
  //     cancelText: "No",
  //     okButtonProps: { danger: true },
  //     closable: true,
  //     onOk: async () => {
  //       const res = await StatusResponse.delete("estatus/eliminar",{ idEstatus: valor.idEstatus});
  //       return new Promise((resolve, reject)=>{
  //         try {
  //           if (valor.idEstatus > 0) {
  //             if (res && res.status === 200) {
  //               refreshModels(true, {
  //                 ordenar: 'idEstatus-asc',
  //                 pagina: 1,
  //                 limite: limite
  //               });
  //               resolve();
  //             } else if ( res?.status === 400 ) {
  //               Modal.error({
  //                 title: "Error: no se logro eliminar la Vía",
  //                 content: res?.mensaje,
  //               });
  //               reject();
  //             }
  //             reject();
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       })
  //     },
  //   });
  // };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder="Buscar..."
      onSearchClicked={onSearch}
      emptyText='Aún no hay registros'
    >
      <Tabla 
        columns={columns}
        innerRef={tablaRef}
        nameURL={endPoint}
        expand=""
        order="idEstatus-desc"
        scroll={{ x: '30vw' }}
        extraParams={buscarParams}
      />
    </SimpleTableLayout>
  );
};

export default Estatus;
