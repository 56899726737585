import React, {useState, useEffect, useMemo, useRef} from 'react';
import {SimpleTableLayout} from '../../components/layouts';
import {Table, Form, Modal, Row, Col, Spin} from 'antd';
import {
  SnippetsOutlined,
  EditOutlined,
  FilePdfOutlined,
  BorderlessTableOutlined,
  DatabaseOutlined, DeleteRowOutlined, HddOutlined
} from '@ant-design/icons';
import {useModels, useApp, useSortColumns, useAuth} from '../../hooks';
import ActionsButton from '../../components/actionsbutton/ActionsButton';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import {inventarioConcentracion, Respuestas} from '../../utilities';
import {Search} from './form/Search';
import StatusResponse from '../../services/statusResponse';
import {RenderEstatus} from "../archivo-tramite/RenderEstatus";
import {Tabla as TableComponent} from "../../components";

const {REACT_APP_API_URL: baseUrl} = process.env;

const ExpedienteConcetracion = () => {

  const titulo = 'Expedientes en Concentración';

  let tablaRef = useRef(null);
  const history = useHistory();
  const [searchForm] = Form.useForm();
  const {token} = useApp();
  const {user} = useAuth();
  const url = '/archivo/tramite';

  const _baseUrl = baseUrl.replace("v1/", "")

  const [columnsData, setColumnsData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [actuales, setActuales] = useState(0);
  const [totalArchivos, setTotalArchivos] = useState(0);
  const [buscarParams, setBuscarParams] = useState({});

  const extraParamsMemo = useMemo(() => ({
    estatus: 'ACO',
    ...buscarParams
  }), [buscarParams]);


  const breadcrumbItems = [
    {
      name: titulo,
      to: '/archivo/concentracion',
      icon: <SnippetsOutlined/>,
    },
  ];

  const onSearch = React.useCallback((v) => {
    setSearchLoading(true);
    const {buscar, seccion, serie, subFondoArea, fecha, ano} = searchForm.getFieldsValue(true);
    let params = {};
    if (fecha && fecha[0] && fecha[0]) {
      params.inicio = moment(fecha[0]).format("YYYY-MM-DD");
      params.fin = moment(fecha[1]).format("YYYY-MM-DD");
    }
    if (ano) {
      params.anio = moment(ano).format('YYYY');
    }
    if (buscar) {
      params.buscar = buscar;
    }
    if (seccion) {
      params.idSeccion = seccion;
    }
    if (serie) {
      params.idSerie = serie;
    }
    if (subFondoArea) {
      params.idSubFondoArea = subFondoArea;
    }

    setBuscarParams(params);
    setSearchLoading(false)
  }, [searchForm])


  const cambiarEstatusExpediente = async (modelo, estatus, innerRef) => {
    try {
      let body = {
        ...modelo,
        estatus: estatus,
      }

      const res = await StatusResponse.post('expediente/guardar', body);
      if (Respuestas(res))
        innerRef.current.refresh();
    } catch (e) {
      Respuestas();
      console.log(e)
    }
  }

  const onClean = () => {
    searchForm.resetFields();
    onSearch();
  }

  const columnasExcel = [
    {
      title: 'Número Consecutivo',
      key: 'numeroCaja',
      width: 5,
      dataIndex: 'numeroCaja'
    },
    {
      title: 'Sección/Serie',
      key: 'serieValor',
      dataIndex: 'serieValor'
    },
    {
      title: 'Número de Expediente por Serie',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Legajos',
      key: 'legajos',
      dataIndex: 'legajos',
      width: 10
    },
    {
      title: 'Fojas',
      key: 'numeroHojas',
      dataIndex: 'numeroHojas',
      width: 10
    },
    {
      title: 'Título',
      key: 'titulo',
      dataIndex: 'titulo',
      alignment: {wrapText: true}
    },
    {
      title: 'Título descripcion',
      key: 'asunto',
      dataIndex: 'asunto',
      alignment: {wrapText: true}
    },
    {
      title: 'Apertura',
      key: 'fechaApertura',
      dataIndex: 'fechaApertura'
    },
    {
      title: 'Cierre',
      key: 'fechaCierre',
      dataIndex: 'fechaCierre'
    },
    {
      title: 'Valoración Primaria',
      key: 'valoracionPrimaria',
      dataIndex: 'valoracionPrimaria'
    },
    {
      title: 'Valoración Secundaria',
      key: 'valoracionSecundaria',
      dataIndex: 'valoracionSecundaria'
    },
    {
      title: 'AT',
      key: 'vigenciaAt',
      dataIndex: 'vigenciaAt'
    },
    {
      title: 'AC',
      key: 'vigenciaAC',
      dataIndex: 'vigenciaAC'
    },
    {
      title: 'Destino Final',
      key: 'destinoFinal',
      dataIndex: 'destinoFinal'
    },
    {
      title: 'stringClasificacionInformacion',
      key: 'stringClasificacionInformacion',
      dataIndex: 'stringClasificacionInformacion'
    },
    {
      title: 'Archivos',
      key: 'archivo',
      dataIndex: 'archivo'
    },
    {
      title: 'soporte',
      key: 'stringTipoExpediente',
      dataIndex: 'stringTipoExpediente'
    },
  ];

  const infoToExport = async () => {
    let obj = {
      expand: 'serie,subfondoarea,seccion,ubicacion,archivos',
      estatus: 'ACO',
      inicio: buscarParams.inicio ? buscarParams.inicio : '',
      fin: buscarParams.fin ? buscarParams.fin : '',
      anio: buscarParams.anio ? buscarParams.anio : '',
      idSerie: searchForm.getFieldValue("serie"),
      idSeccion: searchForm.getFieldValue("seccion"),
    };
    let flag = true;
    let pagina = 1, total = 0, limite = 100;
    let registros = [];
    setImportLoading(true);
    while (flag) {
      obj["pagina"] = pagina;
      obj["limite"] = limite;
      const params = new URLSearchParams(obj).toString();
      const res = await StatusResponse.get(`expediente?${params}`);
      const resultado = res?.resultado;

      registros = [
        ...registros,
        ...resultado
      ];
      let _paginacion = res?.paginacion;
      total = _paginacion.total;
      setTotalArchivos(_paginacion.total);

      const actual = pagina * limite;
      setActuales(actual);
      pagina = parseInt(_paginacion?.pagina) + 1;

      if (total < actual) {
        flag = false;
        setImportLoading(false);
      }
    }
    registros.sort((a, b) => a.folio > b.folio ? 1 : -1)
    let array = [];

    for (let i = 0, l = registros.length; i < l; i++) {
      let _folio = registros[i]?.folio?.split("/")
      registros[i].numeroCaja = i + 1
      registros[i].folio = _folio[3];
      registros[i].serieValor = `${registros[i].serie?.codigo}`;
      registros[i].fechaApertura = moment(registros[i].fechaApertura).format('DD/MM/YYYY');
      registros[i].fechaCierre = registros[i].fechaCierre ? moment(registros[i].fechaCierre).format('DD/MM/YYYY') : "sin fecha definida";

      registros[i].stringClasificacionInformacion = registros[i]?.clasificacionInformacion?.split(',').map((clasificacionInformacion) => {
        if (clasificacionInformacion === 'R') {
          return 'Reservada';
        } else if (clasificacionInformacion === 'C') {
          return 'Confidencial';
        } else if (clasificacionInformacion === 'P') {
          return 'Pública';
        }
      }).toString().replace(",", ", ");

      registros[i].stringTipoExpediente = registros[i]?.tipoExpediente?.split(',').map((item) => {
        if (item === 'PO') {
          return 'Papel/Original';
        } else if (item === 'DE') {
          return 'Digital/Electrónico';
        } else if (item === 'AU') {
          return 'Audiovisual';
        }
      }).toString().replace(",", ", ");

      registros[i].valoracionPrimaria = registros[i]?.valoracionPrimaria?.split(',').map((item) => {
        if (item === 'A') {
          return 'Administrativo, ';
        } else if (item === 'L') {
          return 'Legal';
        } else if (item === 'CF') {
          return 'Fiscal o Contable';
        }
      }).toString().replace(",", ", ");

      registros[i].valoracionSecundaria = registros[i]?.valoracionSecundaria?.split(',').map((item) => {
        if (item === 'INFO') {
          return 'Informativo';
        } else if (item === 'ET') {
          return 'Evidencial / Testimonial';
        }
      }).toString().replace(",", ", ");

      if (registros[i]?.serie?.destinoFinal === "D")
        registros[i].destinoFinal = "B";
      else if (registros[i]?.serie?.destinoFinal === "CC")
        registros[i].destinoFinal = "H";
      else if (registros[i]?.serie?.destinoFinal === "CF")
        registros[i].destinoFinal = "M";
      else
        registros[i].destinoFinal = "-";

      registros[i].archivo = registros[i]?.archivos?.length > 0 ? `${registros[i]?.archivos?.length}` : "0"

      for (let j = 0, l = registros[i].legajosExpediente.length; j < l; j++) {
        array.push({
          ...registros[i],
          legajos: registros[i].legajosExpediente[j]?.legajos,
          numeroHojas: registros[i].legajosExpediente[j]?.numeroHojas,
        })
      }
    }

    return [
      ...array
    ]
  }

  const descargarExcel = async () => {
    await inventarioConcentracion(
      columnasExcel,  // columnsCOntent
      await infoToExport(),
      "Inventario_General_Concentración_" + moment().format('DD-MM-YYYY'),  // nombre del archivo
      "Área Coordinadora de Archivos", // titulo
      "Inventario Documental de Archivo de Concentración", // subtitulo
      "/logo.png", // img,
      "concentracion"
    )
  }

  const columnas = useMemo(() => [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.idExpediente}
          text={""}
          options={[
            {
              name: 'Detalle',
              icon: <EditOutlined/>,
              onClick: () => history.push(`/archivo/concentracion/detalle?id=${item.idExpediente}`)
            },
            user?.permisoExtra?.includes(56) && {
              name: 'Pasar a Trámite',
              icon: <DatabaseOutlined/>,
              onClick: () => cambiarEstatusExpediente(item, 'ATR', tablaRef)
            },
            /**
             user?.permisoExtra?.includes(55) && {
                name: 'Pasar a Concentración',
                icon: <BorderlessTableOutlined/>,
                onClick: () => cambiarEstatusExpediente(item, 'ACO', refreshModels)
              },
             */
            user?.permisoExtra?.includes(58) && {
              name: 'Dar de Baja',
              icon: <DeleteRowOutlined/>,
              onClick: () => cambiarEstatusExpediente(item, 'BAJ', tablaRef)
            },
            user?.permisoExtra?.includes(57) && {
              name: 'Conservación Historica',
              icon: <HddOutlined/>,
              onClick: () => cambiarEstatusExpediente(item, 'HIS', tablaRef)
            },
            {
              name: 'Imprimir',
              icon: <FilePdfOutlined/>,
              onClick: () => window.open(`${_baseUrl}/pdf/reportes/caratula?id=${item.idExpediente}&access-token=${token}`, '_blank')
            },
          ]}
        />
      )
    },
    {
      title: 'Estatus',
      dataIndex: 'estatus',
      key: 'estatus',
      align: 'center',
      width: 90,
      render: (_, item) => <RenderEstatus key={item.idExpediente} item={item} url={url} token={token}/>
    },
    {
      title: 'Fecha Apertura',
      dataIndex: 'fechaApertura',
      key: 'fechaApertura',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.fechaApertura && moment(item?.fechaApertura).format('DD/MM/YYYY')}
        </Link>
      )
    },
    {
      title: 'Fecha Cierre',
      dataIndex: 'fechaCierre',
      key: 'fechaCierre',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.fechaCierre && moment(item?.fechaCierre).format('DD/MM/YYYY')}
        </Link>
      )
    },
    {
      title: 'Serie',
      dataIndex: 'idSerie',
      key: 'idSerie',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.serie?.codigo}
        </Link>
      )
    },
    {
      title: 'Número de expediente',
      dataIndex: 'folio',
      key: 'folio',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.folio}
        </Link>
      )
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.titulo}
        </Link>
      )
    },
    {
      title: 'Área',
      dataIndex: 'SubFondoArea',
      key: 'SubFondoArea',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          <>{item?.subfondoarea?.codigo} / {item?.subfondoarea?.titulo}</>
        </Link>
      )
    },
    {
      title: 'Asunto',
      dataIndex: 'asunto',
      key: 'asunto',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.asunto}
        </Link>
      )
    },
  ], [_baseUrl, history, token])


  useEffect(() => {
    setColumnsData(columnas);
  }, [columnas])

  /*useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);*/

  useEffect(() => {
    setTotalArchivos(totalArchivos);
  }, [totalArchivos]);

  return (
    <>
      <SimpleTableLayout
        title='Expedientes en Concentración'
        breadcrumbItems={breadcrumbItems}
        withSearchButton={false}
        searchLoading={searchLoading}
        emptyText='No se encuentra ningún expediente.'
        children={
          <>
            <Search
              form={searchForm}
              onClean={onClean}
              onSearch={onSearch}
              onExcel={descargarExcel}
              user={user}
            />
            <Modal
              open={importLoading}
              footer={null}
              closable={false}
            >
              <Row justify="center">
                <Col>
                  <Spin spinning={importLoading} tip={<>Obteniendo
                    expedientes... <br/> {actuales > totalArchivos ? totalArchivos : actuales} de {totalArchivos}</>}
                        size="large"/>
                </Col>
              </Row>
            </Modal>
            <TableComponent
              nameURL='expediente'
              columns={columnas}
              rowKey={'idExpediente'}
              innerRef={tablaRef}
              order={'fechaApertura'}
              expand={'serie, subfondoarea,  archivos'}
              extraParams={extraParamsMemo}
            />
          </>
        }
      />
    </>
  );
}

export default ExpedienteConcetracion