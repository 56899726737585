import React, {useState, useMemo, useCallback, useRef} from "react";
import {Table, Modal, Form, Row, Col, Input, Tooltip, Button} from "antd";
import {Link, useHistory} from "react-router-dom";
import ActionsButton from "../../../components/actionsbutton/ActionsButton";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";

import {
  DeleteOutlined,
  EditOutlined,
  ContactsOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";

import {useModels} from "../../../hooks";
import StatusResponse from "../../../services/statusResponse";
import Tabla from "../../../components/Tabla";
import {modalEliminar} from "../../../utilities";

const Cargos = () => {
  const endPoint = 'cargo';
  let tablaRef = useRef(null);

  const [buscarParams, setBuscarParams] = useState({});

  const titulo = "Cargos";
  const url = "/gestion-general/cargos";
  const {form} = Form.useForm();
  const history = useHistory();
  //# const [searching, setSearching] = useState(false);
  const [limite, setLimite] = useState(10);
  const [buscar, setBuscar] = useState("");
  const extraParamsMemo = useMemo(() => {
    let params = {};
    if (buscar && buscar.trim() !== "") {
      params.buscar = buscar.trim();
    }
    return params;
  }, [buscar]);


  const breadcrumbItems = [
    {
      name: titulo,
      to: url,
      icon: <ContactsOutlined/>,
    },
  ];

  const defaultText = (prop) =>
    prop || <span style={{color: "#c7c3c3"}}>---</span>;

  const columns = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      width: 120,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={"Acción"}
          options={[
            {
              name: "Editar",
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idCargo}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar("idCargo", item.idCargo, item.nombre, endPoint, tablaRef),
              styleProps: {color: "#ff4d4f"},
            },
          ]}
        />
      ),
    },
    {
      title: "#",
      dataIndex: "idCargo",
      key: "idCargo",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idCargo}`}>
          {`${item?.idCargo}`}
        </Link>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idCargo}`}>{`${item?.nombre}`}</Link>
      ),
    },
    {
      title: "Área",
      dataIndex: "idArea",
      key: "idArea",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idArea}`}>
          {`${item?.area?.nombre}`}
        </Link>
      ),
    },
  ];


  const SearchForm = () => {
    const onFinishButton = async (values) => {
      let params = {};
      const {buscar} = values;

      if (buscar && buscar.trim() !== "") {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
      setBuscarParams(params);
    };

    return (
      <Form form={form} layout="vertical" onFinish={onFinishButton}>
        <Row gutter={0}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="" name="buscar">
              <Input
                size="large"
                placeholder="BUSCAR..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Tooltip tittle="Buscar">
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                htmlType="submit"
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip tittle="Limpiar">
              <Button
                icon={<ClearOutlined/>}
                type="default"
                style={{minWidth: "50px", minHeight: "40px"}}
                onClick={() => {
                  setBuscar("");
                  tablaRef?.current?.refresh();
                }}
              />
            </Tooltip>
          </Col>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            xxl={{span: 4, offset: 0}}
          >
            <Button
              onClick={() => history.push(url + "/nuevo")}
              icon={<PlusOutlined/>}
              style={{width: "100%", minWidth: "150px", minHeight: "40px"}}
              type="primary"
            >
              Agregar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      emptyText="Aún no hay áreas registrados."
      withSearchButton={false}
      children={
        <>
          <SearchForm/>
          <Tabla
            columns={columns}
            innerRef={tablaRef}
            nameURL={endPoint}
            expand="area"
            order="idCargo-desc"
            scroll={{x: '30vw'}}
            extraParams={buscarParams}
          />
        </>
      }
    />
  );
};

export default Cargos;
