import React, {useEffect, useState} from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  message, Modal, Select, Popover,
} from 'antd';
import {
  SaveOutlined,
  AppstoreOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {ViewLoading} from '../../../components';
import {DefaultLayout} from '../../../components/layouts'
import {useQuery, useModels, useModel} from "../../../hooks";
import StatusResponse from "../../../services/statusResponse";

const PermisosForm = () => {

  const titulo = 'Áreas';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const {Option} = Select;

  const [loading, setLoading] = useState(false);

  // Permiso
  const {
    model,
  } = useModel({
    name: 'permiso',
    id: id,
  });

  const [
    permisoModulos,
  ] = useModels({
    name: 'permiso-modulo',
  });

  useEffect(() => {
    if (model) {
      form.setFieldsValue(model);
    }
  }, [form, model]);

  const breadcrumbItems = [
    {
      name: "Permisos",
      to: '/gestion-general/permisos/',
      icon: <AppstoreOutlined/>,
    },
    {
      name: editing ? "Permisos" : "Nuevo",
      to: '/gestion-general/permisos/editar?id=' + id,
      icon: <AppstoreOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/permisos/'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const onFinish = async (values) => {
    const {
      permiso,
      descripcion,
      idPermisoModulo,
    } = values;
    let body = {
      permiso,
      descripcion,
      idPermisoModulo,
    }

    if (editing) {
      body.idPermiso = id;
    }

    try {
      setLoading(true)
      const res = await StatusResponse.post('permiso/guardar', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{ arrayError.map((m,i) =>
              <span key={(i+1)}> -{m} <br />
              </span> )
              }</div>
            )
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {

          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'}
          });

          history.push('/gestion-general/permisos');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  };
  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  if (loading) return <ViewLoading/>

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        {/*  Nombre  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Módulo del permiso"
              name="idPermisoModulo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                size='large'
              >
                {permisoModulos?.map((item, index) => (
                  <Option key={index} value={item.idPermisoModulo}>
                    <Popover content={item.descripcion}>{item.nombre}</Popover>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Nombre del permiso"
              name="permiso"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Descripción del permiso"
              name="descripcion"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider/>

        {/* Boton de guardar  */}
        <Row gutter={10}>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </DefaultLayout>
  )
}

export default PermisosForm