import React from "react";
import moment from "moment";
import {Tag, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {
  CloudDownloadOutlined
} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";

const {REACT_APP_API_URL: baseUrl} = process.env;

export const RenderEstatus = ({item, url, token}) => {
  if (!item) return "error";

  const _baseUrl = baseUrl.replace("v1/", "")
  const estatus = item?.estatus;
  const destinoFinal = item?.serie?.destinoFinal;
  const fechaTramite = moment(item?.fechaArchivoTramite || null);
  let tag = null;
  let descarga = null;

  if (estatus === "ACO") {
    if (item?.serie?.destinoFinal === 'CC')
      tag = <Tooltip title={"Archivo en concentración (Conservación Completa)"}>
        <Tag color="geekblue">H</Tag>
      </Tooltip>
    if (item?.serie?.destinoFinal === 'CF')
      tag = <Tooltip title={"Archivo en concentración (Conservación por Muestreo)"}>
        <Tag color="geekblue">M</Tag>
      </Tooltip>
  } else if (estatus === "HIS") {
    tag = <Tooltip title={"Archivo en concentración (Conservación Completa)"}>
      <Tag color="geekblue">Histórico</Tag>
    </Tooltip>
  } else if (estatus === "BAJ") {
    tag = <Tooltip title={"Archivo para destrucción"}>
      <Tag color="red">Baja</Tag>
    </Tooltip>
  } else if (fechaTramite.isBefore(moment())) {
    tag = <Tooltip title={"Archivo listo para transferencia"}>
      <Tag color="red">T</Tag>
    </Tooltip>
  } else {
    tag = <Tooltip title={"Archivo que no está en término"}>
      <Tag color="green">T</Tag>
    </Tooltip>
  }


  if (item.archivos.length > 0)
    descarga = <Tooltip
      title={<>
        El expediente cuenta
        con {item.archivos.length} archivo{item.archivos.length > 1 ? "s" : ""} digital{item.archivos.length > 1 ? "es" : ""}
        <ul>
          {item.archivos.map(archivo => <span
            className={"link-pointer"}
            onClick={() => window.open(`${_baseUrl}/v1/descarga/documento?uuid=${archivo?.uuid}&access-token=${token}&t=exp`)}
          >
            {archivo.descripcion}
          </span>)}
        </ul>
      </>}>
      <Tag color="blue">D</Tag>
    </Tooltip>

  return (
    <p key={item.idExpediente} >
      <Link to={`${url}/editar?id=${item?.idExpediente}`}>
        {tag}
      </Link>
      {descarga}
    </p>
  )
}