import React, {useEffect, useMemo, useState} from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Divider,
  message,
} from 'antd';
import {
  SaveOutlined,
  FileAddOutlined,
  ArrowLeftOutlined,
  PartitionOutlined, SettingOutlined, DatabaseOutlined, EditOutlined,
} from '@ant-design/icons';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import {ViewLoading, Select as SelectComponent} from '../../../components';
import {useQuery, useModel} from "../../../hooks";
import {Respuestas} from "../../../utilities";


const FirmantesForm = () => {

  const titulo = 'Áreas';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const url = "/gestion-general/firmantes"

  const [loading, setLoading] = useState(false);
  const [appendUsuarios, setApendUsuarios] = useState([]);

  // Adscripcion
  const {
    model, modelLoading,
  } = useModel({
    name: 'sub-fondo-area-firmantes',
    id: id,
  });

  const usuarios = {
    name: 'usuario',
    ordenar: 'nombre-desc',
  };

  const breadcrumbItems = [
    {
      name: 'Administración',
      icon: <SettingOutlined/>
    },
    {
      name: 'Archivo',
      icon: <DatabaseOutlined/>
    },
    {
      name: "Firmantes",
      to: url,
      icon: <EditOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? url + '/editar?id=' + id : url + '/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push(url),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const onFinish = async (values) => {

    let body = {...values}
    if (editing) {
      body.idFirmante = id;
    }
    try {
      setLoading(true)

      const res = await StatusResponse.post('sub-fondo-area-firmantes/guardar', body);
      if (Respuestas(res))
        history.push(url)
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      setApendUsuarios([
        model?.coordinador,
        model?.rat,
        model?.rac
      ]);
      form.setFieldsValue({...model})
    }
    return () => mounted = false;
  }, [form, modelLoading, model]);

  console.log(appendUsuarios)

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={10}>
          <Divider>Firmantes de Archivos</Divider>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Responsable de Archivo en Trámite"
              name="idRat"
            >
              <SelectComponent
                size="large"
                modelsParams={usuarios}
                labelProp="nombreCompleto"
                valueProp="idUsuario"
                idModel="idUsuario"
                placeholder="Selecciona una opción"
                append={appendUsuarios}
                render={(_, row) => `${row.nombreCompleto}`}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Responsable de Archivo en Concentración"
              name="idRac"
            >
              <SelectComponent
                size="large"
                modelsParams={usuarios}
                labelProp="nombreCompleto"
                valueProp="idUsuario"
                idModel="idUsuario"
                placeholder="Selecciona una opción"
                append={appendUsuarios}
                render={(_, row) => `${row.nombreCompleto}`}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Responsable de Coordinación de Archivos"
              name="idCoordinador"
            >
              <SelectComponent
                size="large"
                modelsParams={usuarios}
                labelProp="nombreCompleto"
                valueProp="idUsuario"
                idModel="idUsuario"
                placeholder="Selecciona una opción"
                append={appendUsuarios}
                render={(_, row) => `${row.nombreCompleto}`}
              />
            </Form.Item>
          </Col>
          <Divider/>
        </Row>
        {/* Boton de guardar  */}
        <Row gutter={10}>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </DefaultLayout>
  );
};

export default FirmantesForm;
