const ATR = "ATR";
const ACO = "ACO";
const HIS = "HIS";
const BAJ = "BAJ";

export const estatusExpediente = [
  { value: ATR, label: "Trámite" },
  { value: ACO, label: "Concentración" },
  { value: HIS, label: "Histórico" },
  { value: BAJ, label: "Baja" }
];