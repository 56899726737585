import DefaultLayout from "./DefaultLayout";
import { Input, ConfigProvider, Empty, Col, Row, Button } from "antd";

const { Search } = Input;

const SimpleTableLayout = ({
  breadcrumbItems,
  buttonData,
  searchPlaceholder = "Búsqueda",
  searchLoading,
  onSearchClicked,
  children,
  emptyText = "Aún no hay registros.",
  withSearchButton = true,
  searchProps,
}) => {
  return (
    <DefaultLayout breadcrumbItems={breadcrumbItems}>
      {withSearchButton && (
        <Row style={{ marginBottom: 20 }}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 18, offset: 0 }}
            lg={{ span: 18, offset: 0 }}
            xxl={{ span: 18, offset: 0 }}
            style={{ marginRight: "8%" }}
          >
            <Search
              placeholder={searchPlaceholder}
              enterButton="Buscar"
              style={{ width: "50%", }}
              loading={searchLoading}
              onSearch={onSearchClicked}
              {...searchProps}
            />
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xxl={{ span: 4, offset: 0 }}
          >
            <Col>
              {Boolean(buttonData) && (
                <Button
                  onClick={buttonData.to ? buttonData.to : undefined}
                  {...buttonData.props}
                  icon={buttonData.icon}
                  style={{ width: "100%", }}
                >
                  {buttonData.text}
                </Button>
              )}
            </Col>
          </Col>
        </Row>
      )}

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            style={{ height: 300, paddingTop: "5%" }}
            description={emptyText}
          />
        )}
      >
        {children}
      </ConfigProvider>
    </DefaultLayout>
  );
};

export default SimpleTableLayout;
