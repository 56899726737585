import React, { useState, useRef } from 'react';
import moment from "moment";
import { DeleteOutlined, EditOutlined, PlusOutlined, BankOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import { SimpleTableLayout } from '../../../components/layouts';
import Tabla from '../../../components/Tabla';
import { modalEliminar } from '../../../utilities';

const DiaFestivoListado = () => {
  const endPoint = 'dia-festivo';
  const tablaRef = useRef(null);
  const [buscarParams, setBuscarParams] = useState({});
  const titulo = "Días Festivos";
  const url = '/gestion-general/dias-festivos';
  const history = useHistory();

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/dias-festivos/',
      icon: <BankOutlined />,
    },
  ];

  const buttonData = {
    text: "Agregar Día Festivo",
    to: () => history.push(`${url}/nuevo`),
    icon: <PlusOutlined />,
    props: { disabled: false, type: 'primary' }
  };

  const linkText = (value, row, key) => (
    <Link to={`${url}/editar?id=${row.idDiaFestivo}`}>
      <Tooltip title={value}>
        {value}
      </Tooltip>
    </Link>
  );

  const renderDateColumn = (_, item) => (
    <Link to={`${url}/editar?id=${item.idDiaFestivo}`}>
      <Tooltip title={item?.fecha}>
        {item?.fecha ? moment(item?.fecha).format('DD/MM/YYYY') : "---"}
      </Tooltip>
    </Link>
  );

  const renderActionsColumn = (_, item) => (
    <ActionsButton
      key={item.id}
      text={""}
      options={[
        {
          name: 'Editar',
          icon: <EditOutlined />,
          onClick: () => history.push(`${url}/editar?id=${item.idDiaFestivo}`)
        },
        {
          name: "Eliminar",
          icon: <DeleteOutlined />,
          onClick: () => modalEliminar("idDiaFestivo", item.idDiaFestivo, item.nombre, endPoint, tablaRef),
          styleProps: { color: '#ff4d4f' }
        }
      ]}
    />
  );

  const columns = [
    {
      title: '',
      dataIndex: 'idDiaFestivo',
      key: 'idDiaFestivo',
      width: 120,
      align: 'center',
      render: renderActionsColumn
    },
    {
      title: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      render: linkText,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      render: renderDateColumn,
    },
    {
      title: 'Día Festivo',
      dataIndex: 'nombre',
      key: 'nombre',
      render: linkText,
    }
  ];

  const onSearch = (search) => {
    const params = { ...buscarParams };
    if (search.length > 0) {
      params.buscar = search;
    } else {
      delete params.buscar;
    }
    setBuscarParams(params);
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      onSearchClicked={onSearch}
      emptyText='Aún no hay Días registrados.'
    >
      <Tabla
        columns={columns}
        innerRef={tablaRef}
        nameURL={endPoint}
        expand=""
        order="idDiaFestivo-desc"
        scroll={{ x: '30vw' }}
        extraParams={buscarParams}
      />
    </SimpleTableLayout>
  );
};

export default DiaFestivoListado;
