import React from 'react';
import { useQuery } from '../../hooks';
import NotFound from '../shared/NotFound';
import { useAuth, useApp } from '../../hooks';

const {REACT_APP_API_URL: baseUrl} = process.env;

const CorrespondenciaQR = () => {

  document.querySelector("meta")?.remove();
  const q = useQuery();
  const id = q.get("id");
  const _baseUrl = baseUrl.replace("v1/", "")

  const { user } = useAuth();
  const { token } = useApp();

  const [ idCorrespondencia, setIdCorrespondencia ] = React.useState('');
  const [ idUsuario, setIdUsidUsuario ] = React.useState('');
  const [ tokenUsuario, setTokenUsuario ] = React.useState('');

  const b64_to_utf8 = ( str ) => {
    return decodeURIComponent(escape(window.atob( str )));
  }

  React.useEffect(() => {
    try {
      setIdCorrespondencia(b64_to_utf8(id));
      setIdUsidUsuario(b64_to_utf8(user?.idUsuario));
      setTokenUsuario(b64_to_utf8(token));
    } catch (e) {
      console.log(e)
    }
  }, [id, token, user?.idUsuario])

  if (idCorrespondencia === '') return <NotFound />

  return (
    <>
      <helmet><meta name="viewport" content="maximum-scale=1"/></helmet>
      <div style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
        <iframe
          title='Correspondencia'
          src={`${_baseUrl}pdf/reportes/correspondencia?id=${idCorrespondencia}Us-${idUsuario}&f=1&access-token=${tokenUsuario}`}
          style={{ width: '100%', height: '100%'}}
        />
      </div>
    </>
  )
}

export default CorrespondenciaQR