import React, {useMemo, useState, useCallback, useEffect} from "react";
import {
  Col,
  Row,
  Tag,
  Tabs,
  Form,
  Table,
  Modal,
  Input,
  Select,
  Button,
  Tooltip,
  DatePicker,
  message,
  Popover,
} from 'antd';
import {useHistory} from 'react-router-dom';
import {
  EditOutlined,
  PlusOutlined,
  SendOutlined,
  ClearOutlined,
  InboxOutlined,
  DeleteOutlined,
  SearchOutlined,
  PrinterOutlined,
  FileTextOutlined,
  CloseCircleOutlined,
  HighlightOutlined,
  FileDoneOutlined,
  SolutionOutlined,
  MailOutlined,
  FilePdfTwoTone,
  EyeOutlined,
} from "@ant-design/icons";
import moment from 'moment';

import ActionsButton from '../../components/actionsbutton/ActionsButton';
import StatusResponse from "../../services/statusResponse";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useApp, useAuth, useModels, useQuery} from "../../hooks";

const {REACT_APP_API_URL: baseUrl} = process.env;
const {TabPane} = Tabs;

const Documentos = () => {

  const {RangePicker} = DatePicker;
  const {Option} = Select;
  const titulo = "Documentos";
  const history = useHistory();
  const [form] = Form.useForm();
  const {token} = useApp();
  const query = useQuery();
  const opt = query.get("opt");
  const _baseUrl = baseUrl.replace("/v1/", "");

  const {user} = useAuth();

  const [selectedPageSize, setSelectedPageSize] = useState(20);
  const [tab, setTab] = useState("2");

  const [buscar, setBuscar] = useState(undefined);
  const [inicio, setInicio] = useState(undefined);
  const [fin, setFin] = useState(undefined);
  const [docTipo, setDocTipo] = useState(undefined);
  //# const [selectionType, setSelectionType] = useState('checkbox');

  const [pin, setPin] = useState('');

  const extraParamsMemo = useMemo(() => {
    let params = {
      tipo: tab === '2' ? 'recibido' : 'elaborado',
    }

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio.format('YYYY-MM-DD');
      params.fin = fin.format('YYYY-MM-DD');
    }

    if (opt)
      params.opt = opt;

    if (docTipo && docTipo !== 100)
      params.docTipo = docTipo;

    params.idUsuario = user?.idUsuario;

    return params

  }, [tab, buscar, inicio, fin, opt, docTipo, user?.idUsuario]);

  // Documentos enviados
  const [
    tipos,
    tiposLoading,
  ] = useModels({
    name: 'documento-tipo',
    ordenar: 'nombre-desc',
    limite: -1,
  });

  // Documentos enviados
  const [
    Documentos,
    DocumentosLoading,
    ,
    DocumentosPage,
    DocumentosRefresh
  ] = useModels({
    name: 'documento',
    ordenar: 'creado-desc',
    extraParams: extraParamsMemo,
    limite: selectedPageSize,
    expand: 'emisores,receptores,documentoResponsable,areasEmisora'
  });

  // Establecer paginación con Hook useModels
  const onSetPageCallback = useCallback(async (page, size) => {

    setSelectedPageSize(size);
    await DocumentosRefresh(true, {
      ordenar: 'idDocumento-desc',
      pagina: page,
      limite: size,
    });

  }, [DocumentosRefresh]);

  /* //#
  const imprimirDocumento = (documento, firma) => {
    if (firma === 1) {
      if (user?.pin === null) {
        message.warning('El usuario no tiene un PIN asignado.');
        return;
      }

      if (user?.pin + '' !== pin) {
        message.warning('El PIN ingresado no es correcto.');
        return;
      }
      firmarDocumento(documento?.idDocumento);
      window.open(`${_baseUrl}/pdf/reportes/documento?id=${documento.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')
    } else {
      window.open(`${_baseUrl}/pdf/reportes/documento?id=${documento.idDocumento}Us-${user.idUsuario}&f=0&access-token=${token}`, '_blank')
    }
  } // */

  const firmarDocumento = async (id) => {

    if (user?.pin === null) {
      message.warning('El usuario no tiene un PIN asignado.');
      return;
    }

    if (user?.pin + '' !== pin) {
      message.warning('El PIN ingresado no es correcto.');
      return;
    }

    let body = {};
    body.idDocumento = id;
    try {
      const res = await StatusResponse.post('documento/guardar-firma', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: 'Error al guardar: datos incompletos.',
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          window.location.reload();
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    }
  }

  const configPagination = useMemo(() => {

    const modelsPage = DocumentosPage?.limite

    if (modelsPage) {
      let size = selectedPageSize;

      return {
        total: modelsPage?.total,
        pageSize: modelsPage?.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} documentos.`,
        locale: {items_per_page: 'página'},
        pageSizeOptions: [10, 20, 30].filter(val => val <= modelsPage?.total),
        showSizeChanger: true
      }
    }

    return null;

  }, [DocumentosPage?.limite, onSetPageCallback, selectedPageSize]);

  const breadcrumbItems = [
    {name: titulo, to: '/documento', icon: <FileTextOutlined/>}
  ];

  const defaultText = (prop, item) => prop 
    ? <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>{prop}</span>
    : <span style={{color: '#c7c3c3'}}>---</span>;


  const columnsSend = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          size="large"
          type="default"
          options={[
            {
              name: 'Ver detalles',
              icon: <EditOutlined/>,
              onClick: () => history.push(`/documento/editar-documento?id=${item.idDocumento}`)
            },
            {
              name: 'Imprimir',
              icon: <PrinterOutlined/>,
              onClick: () => window.open(`${_baseUrl}/pdf/reportes/documento?id=${item.idDocumento}Us-${user.idUsuario}&access-token=${token}`, '_blank')

            },
            {
              name: 'Imprimir firmado',
              icon: <PrinterOutlined/>,
              onClick: () => window.open(`${_baseUrl}/pdf/reportes/documento?id=${item.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank'),
              hidden: !(item?.firmado === 1)
            },
            {
              name: <Popover placement="right" content={<div>
                {user?.pin !== null ? <Input
                  type="password"
                  value={pin}
                  onChange={e => setPin(e.target.value)}
                /> : <span onClick={() => history.push("/")}
                           style={{margin: "0 8px"}}>Introducir un PIN para firma.</span>}
                <Button
                  type="primary"
                  block size='large'
                  name="estatus"
                  value="NUEVO"
                  onClick={() => firmarDocumento(item.idDocumento, 1)}
                  icon={<FilePdfTwoTone/>}
                  style={{margin: '3px 0'}}
                >
                  Firmar
                </Button>
              </div>} title="Digite PIN de impresión">
                Incluir firma
              </Popover>,
              icon: <HighlightOutlined/>,
              hidden: (item?.firmado === 1)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: '',
      dataIndex: 'estatus',
      key: 'estatus',
      render: (_, item) => {
        // let responsable = item?.documentoResponsable?.find(item => item.idUsuarioReceptor === user?.idUsuario);

        if (item?.estatus === "ENVIADO") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            {item?.firmado === 1 ?
              <HighlightOutlined style={{fontSize: "22px"}}/> :
              <SendOutlined style={{fontSize: "22px"}}/>} Enviado
          </span>
        } else if (item?.estatus === "CANCELADO") {
          return <span className="link-pointer"  onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <DeleteOutlined style={{fontSize: "22px"}}/> Cancelado
          </span>
        } else if (item?.estatus === "NUEVO") {
          console.log(item?.estatus)
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            {item?.firmado === 1 ?
              <HighlightOutlined style={{fontSize: "22px"}}/> :
              <SolutionOutlined style={{fontSize: "22px"}}/>}
            Borrador
          </span>;
        }
        if (item?.estatus === "ATENDIDO") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <FileDoneOutlined style={{fontSize: "22px"}}/> Atendido
          </span>
        }
        if (item?.estatus === "TRAMITE") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <SolutionOutlined style={{fontSize: "22px"}}/> En Trámite
          </span>
        } else {
          console.log("ninguna")
        }
      }
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      render: (_, item) => moment(item.fecha).parseZone().local().format('DD-MM-YYYY')
    },
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      render: defaultText
    },
    {
      title: 'Asunto',
      dataIndex: 'asunto',
      key: 'nombre',
      render: defaultText
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Dirigido a',
      dataIndex: 'idAreaReceptora',
      key: 'idAreaReceptora',
      render: (_, item) => renderArea(item)
    },
  ];

  console.log(Documentos)

  const columnsRecived = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      style: {backgroundColor: '#d92727'},
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: 'Ver',
              icon: <EyeOutlined/>,
              onClick: () => history.push(`/documento/editar-documento?id=${item.idDocumento}`)
            },
            {
              name: 'Imprimir',
              icon: <PrinterOutlined/>,
              onClick: () => window.open(`${_baseUrl}/pdf/reportes/documento?id=${item.idDocumento}Us-${user.idUsuario}&access-token=${token}`, '_blank')

            },
            {
              name: 'Imprimir firmado',
              icon: <PrinterOutlined/>,
              onClick: () => window.open(`${_baseUrl}/pdf/reportes/documento?id=${item.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank'),
              hidden: !(item?.firmado === 1)
            },
          ]}
        />
      )
    },
    {
      title: '',
      dataIndex: 'idCreador',
      key: 'idCreador',
      render: (_, item) => {

        let responsable = item?.documentoResponsable.find(item => item.idUsuarioReceptor === user?.idUsuario);

        if (responsable?.estatus === "NUEVO") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <span style={{fontWeight: "bolder"}}>
              <MailOutlined style={{fontSize: "22px"}}/> Nuevo
            </span>
          </span>
        }
        if (responsable?.estatus === "ENVIADO") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <SendOutlined style={{fontSize: "22px"}}/> Enviado
          </span>
        }
        if (responsable?.estatus === "ATENDIDO") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <FileDoneOutlined style={{fontSize: "22px"}}/> Atendido
          </span>
        }
        if (responsable?.estatus === "TRAMITE") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <SolutionOutlined style={{fontSize: "22px"}}/> En Trámite
          </span>
        }
        if (responsable?.estatus === "RECIBIDO") {
          return <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
            <InboxOutlined style={{fontSize: "22px"}}/> Recibido
          </span>
        }
      }
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      render: (_, item) => tipoDocumento(item) === "NUEVO" ?
        <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          <span style={{fontWeight: "bolder"}}>{moment(item.fecha).parseZone().local().format('DD-MM-YYYY')}</span>
        </span>
        : <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          {moment(item.fecha).parseZone().local().format('DD-MM-YYYY')}
        </span>
    },
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      render: (_, item) => tipoDocumento(item) === "NUEVO" ?
        <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          <span style={{fontWeight: "bolder"}}>{item.folio}</span>
        </span> :
        <span onClick={() => history.push(`/editar-documento?id=${item.idDocumento}`)}>
          {item.folio}
        </span>
    },
    {
      title: 'Área Emisora',
      dataIndex: 'idAreaEmisora',
      key: 'idAreaEmisora',
      render: (_, item) => tipoDocumento(item) === "NUEVO" ?
        <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          <span style={{fontWeight: "bolder"}}>{item?.areasEmisora?.codigo} - {item?.areasEmisora?.nombre}</span>
        </span> :
        <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          {item?.areasEmisora?.codigo} - {item?.areasEmisora?.nombre}
        </span>
    },
    {
      title: 'Asunto',
      dataIndex: 'asunto',
      key: 'nombre',
      render: (_, item) => tipoDocumento(item) === "NUEVO" ?
        <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          <span style={{fontWeight: "bolder"}}>{item.asunto}</span>
        </span> :
        <span className="link-pointer" onClick={() => history.push(`/documento/editar-documento?id=${item.idDocumento}`)}>
          {item.asunto}
        </span>
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Dirigido a',
      dataIndex: 'idAreaReceptora',
      key: 'idAreaReceptora',
      render: (_, item) => tipoDocumento(item) === "NUEVO" ?
        <span style={{fontWeight: "bolder"}}>{renderArea(item)} </span> : renderArea(item)
    },
  ];

  const renderArea = (item) => {
    const receptores = item?.receptores;
    return (
      <Tag color="magenta">Detalles: {receptores?.length} </Tag>
    )
  };

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el el documento com folio "${valor?.folio}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("documento/eliminar", {idDocumento: valor.idDocumento});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idDocumento > 0) {
              if (res && res.status === 200) {
                DocumentosRefresh(true, {
                  ordenar: 'idDocumento-asc',
                  pagina: 1,
                  limite: -1
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar cargo",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const tipoDocumento = (item) => {
    return item?.documentoResponsable?.filter(item => item.idUsuarioReceptor === user.idUsuario)[0]?.estatus;

  }

  const SearchForm = () => {

    const onFinishButton = async values => {
      let params = {}
      const {buscar, rango, docTipo} = form.getFieldsValue(true);
      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
      if (rango && rango.length > 0) {
        setInicio(
          moment(rango[0].format('YYYY-MM-DD') + ' 00:00:00')
        );
        setFin(
          moment(rango[1].format('YYYY-MM-DD') + ' 23:59:59')
        );
        params.inicio = rango[0].format('YYYY-MM-DD') + ' 00:00:00';
        params.fin = rango[1].format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (docTipo) {
        setDocTipo(docTipo);
        params.docTipo = docTipo;
      }

    }

    return (
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item
              label=""
              name="buscar"
            >
              <Input
                disabled={DocumentosLoading}
                placeholder="Folio, asunto, ETC..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Form.Item
              label=""
              name="rango"
            >
              <RangePicker
                disabled={DocumentosLoading}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                placeholder={['FECHA INICIO', 'FECHA LÍMITE']}
                style={{width: '100%'}}
                defaultValue={[inicio, fin]}
                value={[inicio, fin]}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item
              label=""
              name="docTipo"
            >
              <Select
                allowClear
                placeholder="SELECCIONE UN TIPO"
                style={{width: '100%',}}
                disabled={tiposLoading}
                defaultValue={docTipo}
                value={docTipo}
              >
                {tipos?.map(item => (
                  <Option key={item?.idDocumentoTipo} value={item?.idDocumentoTipo}>{item?.nombre}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <Tooltip title="Buscar">
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                // htmlType="submit"
                loading={DocumentosLoading}
                disabled={DocumentosLoading}
                onClick={onFinishButton}
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                icon={<ClearOutlined/>}
                type="default"
                disabled={DocumentosLoading}
                onClick={() => {
                  form.resetFields();
                  setBuscar('');
                  setInicio(null);
                  setFin(null);
                  setDocTipo(undefined);
                  DocumentosRefresh();
                }}
              />
            </Tooltip>
          </Col>

        </Row>
      </Form>
    )
  };

  useEffect(() => {
    tipos.push({
      codigo: "TO",
      descripcion: "Todos",
      idDocumentoTipo: 100,
      nombre: "Todos",
    });
  }, [tipos])

  return (
    <SimpleTableLayout
      title={titulo}
      withSearchButton={false}
      breadcrumbItems={breadcrumbItems}
      emptyText='no tienes ningún Documento.'
      children={
        <>

          <Row>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 20, offset: 0}}
              lg={{span: 20, offset: 0}}
              xxl={{span: 20, offset: 0}}
            >
              <SearchForm/>
            </Col>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 4, offset: 0}}
              lg={{span: 4, offset: 0}}
              xxl={{span: 4, offset: 0}}
            >
              <Button
                onClick={() => history.push('/documento/nuevo-documento')}
                icon={<PlusOutlined/>}
                style={{width: "100%", minWidth: "150px", minHeight: "40px"}}
                type="primary"
              >
                Agregar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey={tab} onChange={setTab}
              >
                <TabPane
                  tab={<span><InboxOutlined/>Recibidos</span>}
                  key="2"
                >
                </TabPane>
                <TabPane
                  tab={<span><SendOutlined/>Enviados</span>}
                  key="1"
                >
                </TabPane>
              </Tabs>

              <Table
                key="documentos"
                rowKey="idDocumento"
                pagination={configPagination}
                dataSource={Documentos}
                loading={DocumentosLoading}
                rowSelection={{
                  type: 'checkbox'
                }}
                style={{width: "100%"}}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      Receptores: &nbsp;
                      {record?.receptores?.map(item => (
                        <Tag
                          key={item?.idDocumento}>{item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno} </Tag>
                      ))}
                      <br/> <br/>
                      Emisores: &nbsp;
                      {record?.emisores?.map(item => (
                        <Tag
                          key={item?.idDocumento}>{item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno} </Tag>
                      ))}

                    </>
                  ),
                }}
                columns={tab === '2' ? columnsRecived : columnsSend}
                size='small'
                scroll={{x: 1000}}
              />

            </Col>
          </Row>
        </>
      }
    />
  );
}

export default Documentos;