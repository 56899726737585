import { useCallback, useMemo, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useModels } from "../../../hooks";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { ActionsButton } from "../../../components";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import Tabla from "../../../components/Tabla";

const ActoOmision = () => {

  const endPoint = 'acto-omision';
  let tablaRef = useRef(null);
  const [ buscarParams, setBuscarParams ] = useState({});

  const titulo = "Acto de Omisión";
  const url = "/gestion-general/acto-omision";
  const history = useHistory();

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/acto-omision/'
    },
  ];

  const buttonData = {
    text: "Agregar Acto",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idActoOmision}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              //onClick: () => modalEliminar(item),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idActoOmision}`}>
          {`${item?.nombre}`}
        </Link>
      )
    },
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idActoOmision}`}>
          {`${item?.clave}`}
        </Link>
      )
    },
  ]

  // const modalEliminar = (valor) => {
  //   Modal.confirm({
  //     title: "Eliminar",
  //     icon: <CloseCircleOutlined style={{ color: "red" }} />,
  //     content: `¿Desea eliminar el acto "${valor?.nombre}"?`,
  //     okText: "Sí",
  //     cancelText: "No",
  //     okButtonProps: { danger: true },
  //     closable: true,
  //     onOk: async () => {
  //       const res = await StatusResponse.delete("acto-omision/eliminar",{ idActoOmision: valor.idActoOmision});
  //       return new Promise((resolve, reject)=>{
  //         try {
  //           if (valor.idActoOmision > 0) {
  //             if (res && res.status === 200) {
  //               refreshModels(true, {
  //                 ordenar: 'idActoOmision-asc',
  //                 pagina: 1,
  //                 limite: limite
  //               });
  //               resolve();
  //             } else if ( res?.status === 400 ) {
  //               Modal.error({
  //                 title: "Error: no se logro eliminar área",
  //                 content: res?.mensaje,
  //               });
  //               reject();
  //             }
  //             reject();
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       })
  //     },
  //   });
  // };

  const onSearch = async (search) => {
    let params = {...buscarParams};
    if (search.length > 0) {
      params.buscar = search
    } else {
      delete params.buscar;
    }
    setBuscarParams(params)
  };

  return (
    <SimpleTableLayout 
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      onSearchClicked={onSearch}
      emptyText='Aún no hay actos registrados.'
    >
      <Tabla 
        columns={columns}
        innerRef={tablaRef}
        nameURL={endPoint}
        expand=""
        order="idActoOmision-desc"
        scroll={{x: '30vw'}}
        extraParams={buscarParams}
      />
    </SimpleTableLayout>
  );
};

export default ActoOmision;
