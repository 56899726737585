import React, { useState, useMemo, useCallback } from "react";
import { Table, Modal } from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  PartitionOutlined,
  CloseCircleOutlined, SettingOutlined, UserSwitchOutlined, DatabaseOutlined,
} from "@ant-design/icons";
import {useHistory} from 'react-router-dom';
import { useModels } from '../../../hooks';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import StatusResponse from '../../../services/statusResponse';
import { SimpleTableLayout } from '../../../components/layouts';

const Firmantes = () => {

  const titulo = "Firmantes";
  const url = '/gestion-general/firmantes'
  const orden = 'idFirmante-asc'
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);

  const defaultText = (prop, item) => prop ? <span className={"link-pointer"} onClick={()=>history.push(`${url}/editar?id=${item?.idFirmante}`)}>
    {prop?.nombreCompleto}</span> : <span style={{color: '#c7c3c3'}}>---</span>;
  
  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'sub-fondo-area-firmantes',
    ordenar: orden,
    limite: limite
  });
  
  const breadcrumbItems = [
    {
      name: 'Administración',
      icon: <SettingOutlined />
    },
    {
      name: 'Archivo',
      icon: <DatabaseOutlined />
    },
    {
      name: titulo,
      to: url,
      icon: <EditOutlined />,
    },
  ];

  const buttonData = {
    text: "Agregar",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined />,
    props: { disabled: false, type: 'primary', }
  };
  
  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.idArea}
          text={"Acción"}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idFirmante}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar(item),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: 'RAT',
      dataIndex: 'rat',
      key: 'rat',
      render: defaultText,
    },
    {
      title: 'RAC',
      dataIndex: 'rac',
      key: 'rac',
      render: defaultText,
    },
    {
      title: 'Coordinador',
      dataIndex: 'coordinador',
      key: 'coordinador',
      render: defaultText,
    },
  ];

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = { ...params, buscar: search};
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    if(!modelsLoading) setSearching(false);
  };
  
  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setLimite(size);
      await refreshModels ( true, {
        ordenar: orden,
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );
  
  //configuracion de la paginación
  const configPagination = useMemo( ()=> {
    if (modelsPage){
      let size = limite;
      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: ( _, newSize)  => ( size = newSize ),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} áreas.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
        showSizeChanger: true
      }
    }return null;
  }, [ onSetPageCallback, limite, modelsPage ]);

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar los firmantes "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("firmantes/eliminar",{ idFirmante: valor.idFirmante});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.idArea > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: orden,
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error: no se logro eliminar área",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay firmantes registrados.'
      children={
        <>
          <Table
            dataSource={models}
            rowKey="idFirmante"
            loading={modelsLoading}
            pagination={configPagination}
            columns={columns}
            size='small'
            scroll={{ x: 1000 }}
          />
        </>
      }
    />
  );
}

export default Firmantes;