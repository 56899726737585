import React, {useState} from 'react'
import {Modal, Upload as UploadAntd} from 'antd'
import {useApp} from "../hooks";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import StatusResponse from "../services/statusResponse";
import {Respuestas} from "../utilities";

const {REACT_APP_API_URL: baseUrl} = process.env;
const _baseUrl = baseUrl.replace("v1/", "")

const Upload = ({
                  action,
                  onChange,
                  fileList,
                  listType,
                  className,
                  accept = ".xml, .pdf, .png, .jpg, .doc, .xls, .docx, .xlsx",
                  text,
                  mostrarSubida = true,
                  onRemove
                }) => {

  const {token} = useApp();

  const props = {
    name: 'file',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return <UploadAntd
    {...props}
    action={action || _baseUrl + 'v1/media/guardar'}
    onChange={onChange}
    multiple={true}
    fileList={fileList}
    onRemove={onRemove}
    accept={accept}
    listType={listType}
    className={className}
    style={{width: "100%"}}
  >
    {mostrarSubida && text}
  </UploadAntd>
}

export default Upload