import React, {useEffect, useState} from 'react'

import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Modal,
  InputNumber, Table
} from 'antd';

import {
  ArrowLeftOutlined,
  ProfileOutlined,
  SaveOutlined,
  FileAddOutlined,
  SubnodeOutlined, EditOutlined, DeleteOutlined, CloseCircleOutlined
} from '@ant-design/icons';

import {useHistory} from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import {useQuery, useModels, useModel} from "../../../hooks";
import StatusResponse from '../../../services/statusResponse';
import ActionsButton from "../../../components/actionsbutton";

const SerieDetalle = () => {

  const titulo = 'Serie';
  const [form] = Form.useForm();
  const history = useHistory();
  const url = '/gestion-general/series';

  const query = useQuery();
  const id = query.get("id");
  const idSerie = query.get("serie");
  const editing = !!id;
  const [loading, setLoading] = useState(false);
  const [limite/*, setLimite*/] = useState(10);

  const {Option} = Select;

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'serie',
    id: idSerie ? idSerie : id,
    expand: "series"
  });

  const [
    seccion,
    , , ,
    seccionesRecargar
  ] = useModels({
    name: 'seccion',
    ordenar: 'idSeccion-desc',
    limit: limite,
  });


  const clasificaciones = [
    {value: "R", descripcion: "Reservado"},
    {value: "C", descripcion: "Clasificado"},
    {value: "P", descripcion: "Público"}
  ];

  const destinos = [
    {value: "D", descripcion: "Destrucción (Baja)"},
    {value: "CC", descripcion: "Conservación Completa (Historico)"},
    {value: "CF", descripcion: "Conservación por Muestreo"}
  ];

  const valoraciones = [
    {value: "A", descripcion: "Administrativo"},
    {value: "L", descripcion: "Legal"},
    {value: "CF", descripcion: "Fiscal o Contable"}
  ];

  const breadcrumbItems = [
    {
      name: idSerie ? "(" + model?.codigo + " - " + model?.titulo + ") Sub Serie" : "Serie",
      to: '/gestion-general/series/',
      icon: <ProfileOutlined/>,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ?
        '/gestion-general/series/editar?id=' + id :
        "/gestion-general/series/nuevo",
      icon: <FileAddOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/series/'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const onFinish = async (values) => {
    const {
      titulo,
      codigo,
      descripcion,
      valoracionPrimaria,
      vigenciaAC,
      vigenciaAt,
      vigenciaT,
      destinoFinal,
      clasificacionInformacion,
      secuencialFolioExp,
      estatus,
      idSeccion,
    } = values;
    let body = {
      titulo,
      codigo,
      descripcion,
      valoracionPrimaria,
      vigenciaAC,
      vigenciaAt,
      vigenciaT,
      destinoFinal,
      clasificacionInformacion,
      secuencialFolioExp,
      estatus,
    }
    if (editing) {
      body.idSerie = id;
    }

    body.idSeccion = idSeccion.value
    body.vigenciaAt = values.vigenciaAt.toString();
    body.vigenciaT = values.vigenciaT.toString();
    body.vigenciaAC = values.vigenciaAC.toString();
    body.secuencialFolioExp = values.secuencialFolioExp.toString();

    if (idSerie) {
      body.idSeriePadre = idSerie;
    }

    try {
      setLoading(true)
      const res = await StatusResponse.post('serie/guardar', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{arrayError.map((m, i) =>
                <span key={(i + 1)}> -{m} <br/>
              </span>)
              }</div>
            )
          });
          console.log(arrayError)
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'}
          });
          history.push('/gestion-general/series');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  const buscarSecciones = async (value) => {
    if (value?.length > 0) {
      await seccionesRecargar(true, {
        buscar: value
      })
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading && !idSerie) {
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idArea,
      })
    }

    return () => mounted = false;
  }, [form, modelLoading, model, idSerie])

  useEffect(() => {

    form.setFieldsValue({
      secuencialFolioExp: 0,
      vigenciaAC: 0,
      vigenciaAt: 0,
      vigenciaT: 0
    })

  }, [form])


  const renderSimple = (item, row) => {
    return (
      <span className="link-pointer" onClick={() => history.push(`${url}/editar?id=${row?.idSerie}`)}> {item} </span>);
  }

  const renderSeccion = (item, row) => {
    return (<span className="link-pointer"
                  onClick={() => history.push(`${url}/editar?id=${row?.idSerie}`)}> {row?.seccion?.titulo} </span>);
  }

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar la serie "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("serie/eliminar", {idSerie: valor.idSerie});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idSerie > 0) {
              if (res && res.status === 200) {
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar La Serie",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const columns = [

    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: 'Detalles',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idSerie}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              styleProps: {color: '#ff4d4f'},
              onClick: () => modalEliminar(item)
            }
          ]}
        />
      )
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: renderSimple
    },
    {
      title: 'Sección',
      dataIndex: 'idSeccion',
      key: 'idSeccion',
      render: renderSeccion,
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: renderSimple
    },
    {
      title: 'AT',
      dataIndex: 'vigenciaAt',
      key: 'vigenciaAt',
      render: renderSimple
    },
    {
      title: 'AC',
      dataIndex: 'vigenciaAC',
      key: 'vigenciaAC',
      render: renderSimple
    },
    {
      title: 'TA',
      dataIndex: 'vigenciaT',
      key: 'vigenciaT',
      render: renderSimple
    },
  ];

  useEffect(() => {
    if (idSerie) {
      form.setFieldsValue({
        codigo: model?.codigo,
        titulo: null,
        descripcion: null,
        idSeccion: model?.idSeccion,
        destinoFinal: model?.destinoFinal,
        valoracionPrimaria: model?.valoracionPrimaria,
        clasificacionInformacion: model?.clasificacionInformacion,
        secuencialFolioExp: 0,
        vigenciaAt: model?.vigenciaAt,
        vigenciaAC: model?.vigenciaAC,
        vigenciaT: model?.vigenciaT,
      })
    }
  }, [form, idSerie, model])

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>
          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Código"
              name="codigo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size="large"
                placeholder="Escribir código"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Título de Serie"
              name="titulo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size="large"
                placeholder="Escribir Título"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Descripción de Serie"
              name="descripcion"
            >
              <Input
                size="large"
                placeholder="Descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Sección"
              name="idSeccion"
              rules={[{required: true, message: 'Requerido'}]}
              hasFeedback
            >
              <Select
                labelInValue
                size="large"
                disabled={idSerie}
                optionFilterProp="children"
                showSearch={true}
                allowClear={true}
                //filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                listItemHeight={10}
                listHeight={250}
                placeholder="Seleccionar una Sección"
                onSearch={buscarSecciones}
                style={{width: '100%'}}
              >
                {seccion.map((item, index) => (
                  <Option key={index} value={item.idSeccion}>{item.codigo} {item.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Valoración Primaria"
              name="valoracionPrimaria"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                optionFilterProp="children"
                size='large'
                listItemHeight={10}
                listHeight={250}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={valoraciones.map(item => ({
                  value: item?.value,
                  label: item?.descripcion
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Destino Final"
              name="destinoFinal"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                optionFilterProp="children"
                size='large'
                listItemHeight={10}
                listHeight={250}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={destinos.map(item => ({
                  value: item?.value,
                  label: item?.descripcion
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Clasificación"
              name="clasificacionInformacion"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                optionFilterProp="children"
                size='large'
                listItemHeight={10}
                listHeight={250}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={clasificaciones.map(item => ({
                  value: item?.value,
                  label: item?.descripcion
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xxl={12}
          >
            <Form.Item
              label="Secuencia Folio"
              name="secuencialFolioExp"
            >
              <InputNumber
                size="large"
                style={{width: "100%"}}
                min={1}
                max={100}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>
          <Col
            className="gutter-row"
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xxl={8}
          >
            <Form.Item
              tooltip="Vigencia Archivo de Trámite"
              label="Vigencia AT"
              name="vigenciaAt"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                size="large"
                style={{width: "100%"}}
                min={1}
                max={100}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xxl={8}
          >
            <Form.Item
              tooltip="Vigencia Archivo de Concentración"
              label="Vigencia AC"
              name="vigenciaAC"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                size="large"
                style={{width: "100%"}}
                min={1}
                max={100}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xxl={8}
          >
            <Form.Item
              label="Vigencia Total"
              name="vigenciaT"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                size="large"
                style={{width: "100%"}}
                min={1}
                max={100}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={idSerie || model?.idSeriePadre > 0}>
          <Col span={24}>
            <Table
              dataSource={model?.series}
              rowKey="idSerie"
              size='small'
              columns={columns}
              scroll={{x: 1000}}
            />
          </Col>
        </Row>
        <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 5, offset: 14}}
            lg={{span: 5, offset: 14}}
            xxl={{span: 5, offset: 14}}
            hidden={idSerie || model?.idSeriePadre > 0}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                onClick={() => history.push(`${url}/editar?serie=${id}`)}
                loading={loading}
                icon={<SubnodeOutlined/>}
              >Agregar Sub Serie</Button>
            </Form.Item>
          </Col>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 5, offset: idSerie || model?.idSeriePadre > 0 ? 19 : 0}}
            lg={{span: 5, offset: idSerie || model?.idSeriePadre > 0 ? 19 : 0}}
            xxl={{span: 5, offset: idSerie || model?.idSeriePadre > 0 ? 19 : 0}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  )
}

export default SerieDetalle