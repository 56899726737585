import React, {useEffect, useState} from 'react'

import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message, Select,
} from 'antd';

import {
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import {useHistory} from 'react-router-dom';
import {Select as SelectComponent} from '../../../components';
import {useQuery, useModel} from "../../../hooks";
import StatusResponse from '../../../services/statusResponse';
import {Respuestas, respuestas} from "../../../utilities";

const UbicacionForm = ({id, input = false, setItem, subFondoAreaForm, tipoExpediente}) => {

  const [form] = Form.useForm();
  const history = useHistory();
  const url = "/gestion-general/archivo/ubicacion";

  const query = useQuery();
  const editing = !!id;
  const [loading, setLoading] = useState(false)
  const [appendArea, setAppendArea] = useState([])

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'ubicacion',
    expand: 'subFondoArea',
    id: id,
  });

  const areas = {
    name: 'sub-fondo-area',
    ordenar: 'idSubFondoArea-desc',
  };

  const onFinish = async (values) => {
    let body = {...values}

    if (editing) {
      body.idUbicacion = id;
    }

    if (subFondoAreaForm)
      body.idSubFondoArea = subFondoAreaForm?.idSubFondoArea

    if (tipoExpediente)
      body.tipoExpediente = tipoExpediente

    if (body.idSubFondoArea === undefined) {
      Respuestas("error", "Defina el Área a la cual pertenece la Signatura Topográfica")
      return;
    }

    try {
      setLoading(true)
      const res = await StatusResponse.post('ubicacion/guardar', body);
      if (Respuestas(res)) {
        if (!input)
          history.push(url);
        else {
          form.setFieldsValue({});
          setItem((lista) => [...lista, res?.response?.detalle])
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    let _subFondoArea = []
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
      })
      _subFondoArea.push(model?.subFondoArea);
      if (subFondoAreaForm)
        _subFondoArea.push(subFondoAreaForm);
      setAppendArea(_subFondoArea)
    }

    return () => mounted = false;
  }, [form, modelLoading, model])

  useEffect(() => {

    if (subFondoAreaForm?.idSubFondoArea) {
      setAppendArea([subFondoAreaForm])
      form.setFieldValue('idSubFondoArea', subFondoAreaForm?.idSubFondoArea)
    }
    else
      Respuestas("error", "Falta agregar el area de archivo")
  }, [subFondoAreaForm])

  return (
    <Form
      form={form}
      name="form"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>

        <Col
          className="gutter-row"
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: 24, offset: 0}}
          lg={{span: 24, offset: 0}}
          xxl={{span: 24, offset: 0}}
        >
          <Form.Item
            label="Área archivo"
            name="idSubFondoArea"
          >
            <SelectComponent
              size="large"
              modelsParams={areas}
              labelProp="titulo"
              valueProp="idSubFondoArea"
              idModel="idSubFondoArea"
              placeholder="Selecciona una opción"
              append={appendArea}
              render={(_, row) => `${row.codigo} - ${row.titulo}`}
            />
          </Form.Item>
        </Col>

        <Col
          className="gutter-row"
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: input ? 24 : 12, offset: 0}}
          lg={{span: input ? 24 : 12, offset: 0}}
          xxl={{span: input ? 24 : 12, offset: 0}}
        >
          <Form.Item
            label="Ubicación"
            name="titulo"
            rules={[{required: true, message: 'Requerido'}]}
          >
            <Input
              placeholder="Escribir Título"
              autoComplete="off"
            />
          </Form.Item>
        </Col>

        <Col
          className="gutter-row"
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: input ? 24 : 12, offset: 0}}
          lg={{span: input ? 24 : 12, offset: 0}}
          xxl={{span: input ? 24 : 12, offset: 0}}
        >
          <Form.Item
            label="Descripción"
            name="tipoExpediente"
            rules={[{required: true, message: 'Requerido'}]}
          >
            <Input
              placeholder="Escribir código"
              autoComplete="off"
            />
          </Form.Item>
        </Col>

        <Col
          className="gutter-row"
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: input ? 24 : 12, offset: 0}}
          lg={{span: input ? 24 : 12, offset: 0}}
          xxl={{span: input ? 24 : 12, offset: 0}}
          hidden={input}
        >
          <Form.Item
            label="Tipo de Expediente"
            name="descripcion"
          >
            <Select
              placeholder="Seleccione"
              autoComplete="off"
              options={[
                {label: "Trámite", value: "TRAMITE"},
                {label: "Concentración", value: "CONCENTRACION"},
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>
        <Col
          xs={{span: 24, offset: 0}}
          sm={{span: 24, offset: 0}}
          md={{span: 5, offset: 19}}
          lg={{span: 5, offset: 19}}
          xxl={{span: 5, offset: 19}}
        >
          <Form.Item>
            {input ? <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                icon={<PlusOutlined/>}
              /> :
              <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                icon={<SaveOutlined/>}
              >
                Guardar
              </Button>
            }
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default UbicacionForm