import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { useAuth } from "../../hooks";
import "../../styles/LoginForm.less"

const SignIn = () => {

  const [form] = Form.useForm();
  const { signIn, sessionLoading } = useAuth();

  const onFinish = (values) => {
    const { email, clave } = values;
    if(!email || !clave) {
      message.error("Ingresa un email y la Clave");
      return
    }    signIn(email, clave);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  

  return (
    <>
    <div className="ck-app-login-wrap">
      <div className="ck-app-login-container">
          <div className="ck-app-login-main-content">
            <div className="ck-app-logo-content">
                <div className="ck-app-logo-content-bg">
                  <img src="/login-bg.png" alt="bg" />
                </div>
                <div className="ck-app-logo"><img src="/logo.png" alt="logo" width="100%" /></div>
            </div>
            <div className="ck-app-login-content">
              <Form 
                form={form}
                name="form"
                autoComplete='off'
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="ck-signin-form"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Correo electrónico"
                      name="email"
                    >
                      <Input
                        autoComplete="off"  
                        placeholder="Correo electrónico"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Ingrese su contraseña"
                      name="clave"
                    >
                      <Input.Password
                        autoComplete="off"  
                        placeholder="Ingresa su contraseña"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                        <Button 
                            className="ck-btn"
                            type="primary" 
                            htmlType="submit"
                            size="middle"
                            loading={sessionLoading}
                        >
                            Ingresar
                        </Button>
                    </Form.Item>
                  </Col>
                </Row>           
              </Form>
              <div className="ck-app-login-footer">
               
                <div style={{ borderBottom: '1px solid #e9e9e9',margin:'4px 0' }}></div>
                <Col span={24}>
                  Derechos reservados { new Date().getFullYear() } 
                </Col>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
};

export default SignIn;