export const AgregarFaltantes = (data, newData, campo, unShift) => {
  let ids = data.map(item => item[campo]);
  let aux = [...data];
  for (let i in newData) {
    let modelo = newData[i];
    if (!modelo || !modelo[campo]) {
      continue
    }
    const indice = ids.indexOf(modelo[campo]);
    if (modelo && indice === -1) {
      aux.push(modelo);
    } else {
      aux[indice] = modelo;
    }
  }

  if (unShift)
    unShift.map(item => aux.unshift(item))  // unshift: Agregar elementos al inicio del array

  return aux;
};