import {Content} from "antd/lib/layout/layout";
import BreadCrumb from "../breadcrumb";
import {Button, Col} from "antd";

const DefaultLayout = ({children, breadcrumbItems, buttonData, breadcrumbDisable = false}) => {
  const styles = {
    padding: {
      marginLeft: 15,
      marginRight: 15,
    },
    content: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      minHeight: 280,
      borderRadius: 5,
    }
  }

  return (
    <div style={styles.padding}>
      {!breadcrumbDisable && (<BreadCrumb breadcrumbItems={breadcrumbItems}/>)}
      <Content className="site-layout-background" style={styles.content}>
        <Col style={{textAlign:'end'}}>
          {Boolean(buttonData) && (
            <Button
              onClick={
                buttonData.to
                  ? buttonData.to
                  : undefined
              }
              {...buttonData.props}
              icon={ buttonData.icon }
            >
              {buttonData.text}
            </Button>
          )}
        </Col>
        {children}
      </Content>
    </div>
  )
}

export default DefaultLayout;