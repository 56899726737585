import React, {useState, useMemo, useCallback, useEffect, useRef} from "react";
import {Table, Modal, message, Form, Row, Col, Input, Tooltip, Button} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  TeamOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  SearchOutlined, ClearOutlined
} from "@ant-design/icons";
import {SimpleTableLayout} from '../../components/layouts';
import {ActionsButton, Tabla} from '../../components';
import {useHistory, Link} from 'react-router-dom';
import StatusResponse from '../../services/statusResponse';

const Usuarios = () => {

  const titulo = "Usuarios";
  const history = useHistory();
  const {confirm} = Modal;
  const url = 'usuario';
  const {form} = Form.useForm();

  let tablaRef = useRef(null);

  const [searching, setSearching] = useState(false);
  const [buscarParams, setBuscarParams] = useState({});
  const [buscar, setBuscar] = useState("");


  const RenderActionsButton = ({row}) => {
    if (!row) return "error";

    return (
      <ActionsButton
        key={row.idUsuario}
        options={[
          {
            name: 'Editar',
            icon: <EditOutlined/>,
            onClick: () => history.push(`/gestion-general/usuarios/editar?id=${row.idUsuario}`)
          },
          {
            name: 'Eliminar',
            icon: <DeleteOutlined/>,
            onClick: () => eliminarModal(row),
            styleProps: {color: '#ff4d4f'}
          }
        ]}
      />
    );
  };

  const columnsContent = useMemo(() => [
    {
      title: 'Acciones',
      align: 'center',
      render: (_, row) => <RenderActionsButton row={row}/>
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, item) => (
        <Link to={`/gestion-general/usuarios/editar?id=${item?.idUsuario}`}>
          {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
        </Link>
      )
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      render: (value, item) => (
        <Link to={`/gestion-general/usuarios/editar?id=${item?.idUsuario}`}>
          {value}
        </Link>
      )
    },
    {
      title: 'Télefono',
      dataIndex: 'telefono',
      key: 'telefono',
      render: (value, item) => (
        <Link to={`/gestion-general/usuarios/editar?id=${item?.idUsuario}`}>
          {value ? value : <i>No registrado</i>}
        </Link>
      )
    },
  ], []);

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/usuarios/',
      icon: <TeamOutlined/>,
    },
  ];

  const buttonData = {
    text: "Agregar",
    to: () => history.push('/gestion-general/usuarios/nuevo'),
    icon: <PlusOutlined/>,
    props: {type: 'primary',}
  };


  const eliminarModal = (item) => {
    confirm({
      title: `¿Estás seguro de eliminar el Usuario ${item.nombreCompleto}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: 'Si, Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          if (item.idUsuario !== '') {
            const res = await StatusResponse.delete('usuario/eliminar', {idUsuario: item.idUsuario});
            if (res) {
              if (res?.status === 400 && res?.errores !== null) {
                const arrayError = Object.values(res?.errores);
                Modal.error({
                  title: res?.mensaje,
                  content: (
                    <div>{arrayError.map((m, i) => <span key={(i + 1)}> - {m} <br/></span>)}</div>
                  )
                });
                console.log(arrayError)
              } else if (res?.status === 400 && res?.errores === null) {
                message.error({
                  content: res?.mensaje,
                  style: {marginTop: '20vh'},
                });
              } else if (res?.status === 200) {
                message.success({
                  content: res?.mensaje,
                  style: {marginTop: '20vh'},
                });
              }
            }
            tablaRef?.current?.refresh();
          }
        } catch (error) {
          console.log('Error al eliminar: ', error);
        }
      },
      onCancel() {
      },
    });
  };

  const SearchForm = () => {
    const onFinishButton = async (values) => {
      let params = {};
      const {buscar} = values;

      if (buscar && buscar.trim() !== "") {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
      setBuscarParams(params);
    };

    return (
      <Form form={form} layout="vertical" onFinish={onFinishButton}>
        <Row gutter={0}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="" name="buscar">
              <Input
                size="large"
                placeholder="BUSCAR..."
                defaultValue={buscar}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Tooltip tittle="Buscar">
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                htmlType="submit"
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip tittle="Limpiar">
              <Button
                icon={<ClearOutlined/>}
                type="default"
                style={{minWidth: "50px", minHeight: "40px"}}
                onClick={() => {
                  setBuscar("");
                  tablaRef?.current?.refresh();
                }}
              />
            </Tooltip>
          </Col>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            xxl={{span: 4, offset: 0}}
          >
            <Button
              onClick={() => history.push("/gestion-general/usuarios/nuevo")}
              icon={<PlusOutlined/>}
              style={{width: "100%", minWidth: "150px", minHeight: "40px"}}
              type="primary"
            >
              Agregar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumbItems}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      withSearchButton={false}
      children={<>
        <SearchForm/>
        <Tabla
          columns={columnsContent}
          innerRef={tablaRef}
          nameURL={url}
          expand=""
          extraParams={buscarParams}
          order="nombre"
          scroll={{x: '30vw'}}
        />
      </>
      }
    />
  );
}

export default Usuarios;