import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form, Input, message, Modal, DatePicker } from 'antd';
import { ArrowLeftOutlined, BankOutlined, SaveOutlined, FileAddOutlined } from '@ant-design/icons';
import moment from "moment";
import locale from 'antd/lib/date-picker/locale/es_ES';
import { useHistory } from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import { useQuery, useModel } from "../../../hooks";
import { ViewLoading } from '../../../components';
import StatusResponse from '../../../services/statusResponse';

const DiaFestivoDetalle = () => {
  const titulo = 'Días Festivos';
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();
  const id = query.get("id") || "";
  const editing = Boolean(id);
  const [loading, setLoading] = useState(false);

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'dia-festivo',
    id: id,
  });

  const breadcrumbItems = [
    {
      name: "Díá Festivo",
      to: '/gestion-general/dias-festivos/',
      icon: <BankOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ?
        `/gestion-general/dias-festivos/editar?id=${id}` :
        '/gestion-general/dias-festivos/nuevo',
      icon: <FileAddOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/dias-festivos/'),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: 'primary' },
  };

  const onFinish = async (values) => {
    let body = {
      nombre: values?.nombre,
      clave: values?.clave,
      fecha: values?.fecha,
    };

    if (editing) {
      body.idDiaFestivo = id;
    }

    console.log(body);

    try {
      setLoading(true);
      const res = await StatusResponse.post('dia-festivo', body);
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: 'Error al guardar: datos incompletos.',
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
          history.push('/gestion-general/dias-festivos');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning('Error al guardar: datos incompletos.');
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        fecha: model?.fecha ? moment(model?.fecha) : form.getFieldValue('fecha'),
      });
    }
    return () => (mounted = false);
  }, [form, modelLoading, model]);
  if( modelLoading ) return (<ViewLoading /> );

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
        >

        <Form
          form={form}
          name="diaFestivoForm"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

          <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
            <Col
              className="gutter-row"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
            >
              <Form.Item
                label="Díá Festivo"
                name="nombre"
                rules={[{ required: true, message: 'Requerido' }]}
              >
                <Input
                  placeholder="Escribír Título"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
            >
              <Form.Item
                label="Clave"
                name="clave"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese la clave.',
                  },
                ]}
              >
                <Input placeholder="Ingrese la clave" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
            <Col
              className="gutter-row"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xxl={{ span: 12, offset: 0 }}
            >
              <Form.Item
                label="Fecha"
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione la fecha.',
                  },
                ]}
              >
                <DatePicker
                  locale={locale}
                  placeholder="Seleccione la fecha"
                  format="DD/MM/YYYY"
                size="large"
                style={{width: '100%'}}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 5, offset: 19 }}
              lg={{ span: 5, offset: 19 }}
              xxl={{ span: 5, offset: 19 }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  block
                  size='large'
                  htmlType="submit"
                  loading={loading}
                  icon={<SaveOutlined />}
                >Guardar</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
    </DefaultLayout>
  );
};

export default DiaFestivoDetalle;
