import React, {useState, useMemo, useCallback, useRef} from 'react'
import {Button, Col, Form, Input, Modal, Row, Table, Tooltip} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ProfileOutlined,
  SubnodeOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  ClearOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import {Link, useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {useApp, useModels} from "../../../hooks";
import StatusResponse from "../../../services/statusResponse";
import {Tabla} from "../../../components";
import {respuestas} from "../../../utilities";

const SerieListado = () => {

  const titulo = "Series";
  let tablaRef = useRef(null);
  const url = '/gestion-general/series';
  const history = useHistory();
  const {form} = Form.useForm();
  const {token} = useApp();

  const [buscar, setBuscar] = useState('');
  const extraParamsMemo = useMemo(() => {
    let params = {}

    params.sp = 1;

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
      params.sp = 0;
    }


    return params

  }, [buscar]);

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/series/',
      icon: <ProfileOutlined/>,
    },
  ];

  const columns = [
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: 'Detalles',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idSerie}`)
            },
            {
              name: 'Sub Series',
              icon: <SubnodeOutlined/>,
              hidden: item?.idSeriePadre,
              onClick: () => history.push(`${url}/editar?serie=${item.idSerie}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              styleProps: {color: '#ff4d4f'},
              onClick: () => modalEliminar(item)
            }
          ]}
        />
      )
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSerie}`}>
          {`${item?.codigo}`}
        </Link>
      ),
    },
    {
      title: 'Sección',
      dataIndex: 'idSeccion',
      key: 'idSeccion',
      render: (_, item) => {
        return <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.seccion?.titulo}`}
        </Link>
      },
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSerie}`}>
          {`${item?.titulo}`}
        </Link>
      ),
    },
    {
      title: 'AT',
      dataIndex: 'vigenciaAt',
      key: 'vigenciaAt',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.vigenciaAt}`}
        </Link>
      ),
    },
    {
      title: 'AC',
      dataIndex: 'vigenciaAC',
      key: 'vigenciaAC',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.vigenciaAC}`}
        </Link>
      ),
    },
    {
      title: 'TA',
      dataIndex: 'vigenciaT',
      key: 'vigenciaT',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.vigenciaT}`}
        </Link>
      ),
    },
  ];

  const Excel = () => {
    console.log("Excel")
    window.open(`https://archivo.api.transparenciasonora.org/pdf/reportes/excel-series?access-token=${token}`)
  }


  const SearchForm = () => {

    const onFinishButton = async values => {
      let params = {}
      const {buscar} = values;

      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
    }

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishButton}
      >
        <Row gutter={0}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item
              label=""
              name="buscar"
            >
              <Input
                size="large"
                placeholder="BUSCAR..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Tooltip
              tittle="Buscar"
            >
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                htmlType="submit"
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip
              tittle="Limpiar"
            >
              <Button
                icon={<ClearOutlined/>}
                type="default"
                style={{minWidth: "50px", minHeight: "40px"}}
                onClick={() => {
                  setBuscar('');
                }}
              />
            </Tooltip>
            <Tooltip
              tittle="Imprimir Excel">
              <Button
                icon={<FileExcelOutlined/>}
                style={{
                  background: "#1f6e43",
                  color: "#ffffff",
                  minWidth: "50px",
                  minHeight: "40px"
                }}
                type="default"
                onClick={() => Excel()}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form>
    );
  }

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar la serie "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("serie/eliminar", {idSerie: valor.idSerie});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idSerie > 0) {
              if (respuestas(res)) {
                tablaRef?.current?.refresh();
                resolve();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };


  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      emptyText='Aún no hay series registrados.'
      withSearchButton={false}
      children={
        <Row>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 20, offset: 0}}
            lg={{span: 20, offset: 0}}
            xxl={{span: 20, offset: 0}}
          >
            <SearchForm/>
          </Col>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            xxl={{span: 4, offset: 0}}
          >
            <Button
              onClick={() => history.push(url + '/nuevo')}
              icon={<PlusOutlined/>}
              style={{width: "100%", minWidth: "150px", minHeight: "40px"}}
              type="primary"
            >
              Agregar Serie
            </Button>
          </Col>
          <Col span={24}>
            <Tabla
              columns={columns}
              innerRef={tablaRef}
              nameURL={'serie'}
              expand="fondo, seccion.fondo"
              extraParams={extraParamsMemo}
              order="orden"
              scroll={{x: '30vw'}}
            />
          </Col>
        </Row>
      }
    />
  )
}

export default SerieListado