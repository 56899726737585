import React, { useEffect, useState }  from 'react'

import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Modal, Switch
} from 'antd';
import { 
  ArrowLeftOutlined,
  SaveOutlined,
  DollarOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import{ useHistory } from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import { useQuery, useModels, useModel} from "../../../hooks";
import { ViewLoading } from '../../../components';
import StatusResponse from '../../../services/statusResponse';

const FondoDetalle = () => {

  const titulo = 'Fondos';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false)
  const [estatus, setEstatus] = useState(false)

  const { Option } = Select;

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'fondo',
    id: id,
  });

  const [ 
    dependencia,
    dependenciaLoading,
  ] = useModels({
    name: 'dependencia',
    ordenar: 'idDependencia-desc',
    limit: 10,
  });

  const breadcrumbItems = [
    {
      name: "Fondos",
      to: '/gestion-general/fondos/',
      icon: <DollarOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: `/gestion-general/fondos/${editing ? `editar?id=${id}`: 'nuevo'}`,
      icon: <FileAddOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/fondos/'),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: 'primary', }
  };

  useEffect(()=>{
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idArea,
      })
      setEstatus(model.estatus === 1 ? true : false)
      console.log(model?.nombre)
    };
    return () => mounted = false;
  }, [form, modelLoading, model])

  const onFinish = async (values) => {
    const {
      titulo,
      codigo,
      descripcion,
      idDependencia,
    } = values;
    let body = {
      titulo,
      codigo,
      descripcion,
      idDependencia
    }
    if(editing) {
      body.idFondo = id;
    }
    body.estatus = (estatus === true ? 1 : 0);
    try {
      setLoading(true)
      const res = await StatusResponse.post('fondo/guardar', body);
      if(res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if( res?.status === 400 && res?.errores !== null ) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            /*title: res?.mensaje,
            content: (
              <div>{ arrayError.map((m,i) =>
              <span key={(i+1)}> -{m} <br />
              </span> )
              }</div>
            )*/
            title: 'Error al guardar: datos incompletos.',
          });
          console.log(arrayError);
          //cuando el dato ya existe no se puede guardar en BD
        } else if ( res?.status === 400 && res?.errores === null ) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
          //todo salió bien
        } else if ( res?.status === 200 ) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: '20vh' }
          });
          history.push('/gestion-general/fondos');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ',e);
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = ({ values, errorFields, outOfDate  }) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log( values, errorFields, outOfDate );
  };

  if( modelLoading ) return (<ViewLoading /> );

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>   
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Título del Fondo"
              name="titulo"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                placeholder="Escribir Título"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Código"
              name="codigo"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                placeholder="Escribir código"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Descripción del Fondo"
              name="descripcion"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                placeholder="Escribir descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col 
            className='gutter-row'
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Estatus"
              name="estatus"
              rules={[{ required: true, message: 'Requerido' }]}
              hasFeedback 
            >
              <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" checked={estatus} onChange={(value)=>{ setEstatus(value)}}/>
            </Form.Item>
          </Col>

          <Col 
            className='gutter-row'
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Dependencia"
              name="idDependencia"
              rules={[{ required: true, message: 'Requerido' }]}
              hasFeedback 
            >
              <Select
                loading={dependenciaLoading}
                disabled={dependenciaLoading}
                placeholder="Seleccionar dependencia"
              >
                { dependencia.map((item, index) => (
                  <Option key={index} value={item.idDependencia}>{ item.titulo }</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={{xs:10,sm:10,md:10,lg:10}}>
          <Col
            xs={{span:24,offset:0}}
            sm={{span:24,offset:0}}
            md={{span:5,offset:19}}
            lg={{span:5,offset:19}}
            xxl={{span:5,offset:19}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >Guardar</Button>
            </Form.Item>
          </Col>

        </Row>

      </Form>

    </DefaultLayout>
  )
}

export default FondoDetalle