import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import imageToBase64 from 'image-to-base64/browser';
import { message } from 'antd';

const obtenerExtensionImagen = (path) => {
  const ext = path.split('.').pop();
  return ext.toLowerCase();
}

export const inventarioConcentracion = async (cols, data, nombre = 'archivo-excel', titulo = '', subtitulo = '', path = null) => {
  try {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(titulo);

    if(path !== null && typeof path === 'string') {
      const img64 = await imageToBase64(path);
      const idImagen = workbook.addImage({
        base64: img64,
        extension: obtenerExtensionImagen(path),  // * jpg, gif, png
      });
      worksheet.addImage(idImagen, {  // * Aquí se acomoda la imagen
        tl: { col: 0.2, row: 0.2 }, // * midpoints
        ext: { width: 208, height: 111 },
      });
    }

    const header = cols?.map(c => (c.title));
    
    worksheet.columns = cols

    const styleTitle = { // * estilo para el título
      font: {
        bold: true,
        size: 18,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };
    const styleSub = { // * estilo para el título
      font: {
        bold: true,
        size: 12,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };
    const rowHeaderStyle = { // * estilo para el título
      font: {
        bold: true,
        size: 8,
        color: {argb:'FFFFFFFF'}
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
      fill: { 
        type: "pattern",
        pattern: "solid",
        bgColor: {argb: 'FFFFFFFF'},
        fgColor: {argb: '00736C'}
      }
    };
    const border = { //estilo de borde
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    }


    worksheet.mergeCells('A1:D5');  // * combinar celdas  (lugar imagen)

    const row1=worksheet.getRow("2");
    row1.height=18;
    const row2=worksheet.getRow("3");
    row2.height=23.25;
    const row3=worksheet.getRow("4");
    row3.height=16.5;

    // * Despues de mergeCells se debe aplicar estilos y valores
    worksheet.mergeCells('E3:K3')
    worksheet.getCell('E3').value = titulo; // * valor de la celda que se combinará
    worksheet.getCell('E3').style = styleTitle; // * estilo de la celda que se combinará

    // * Despues de mergeCells se debe aplicar estilos y valores
    worksheet.mergeCells('E4:K4')
    worksheet.getCell('E4').value = subtitulo; // * valor de la celda que se combinará
    worksheet.getCell('E4').style = styleSub; // * estilo de la celda que se combinará

    worksheet.addRow([]);
    worksheet.addRow(header);

    worksheet.mergeCells('A6:A7');
    worksheet.getCell('A6').value = 'Número Consecutivo CAJA';
    worksheet.getCell('A6').style = rowHeaderStyle;
    worksheet.getCell('A6').border = border;
    worksheet.getCell('A7').border = border;

    worksheet.mergeCells('B6');
    worksheet.getCell('B6').value = 'Clasificación Archivística';
    worksheet.getCell('B6').style = rowHeaderStyle;
    worksheet.getCell('B6').border = border;

    worksheet.mergeCells('B7');
    worksheet.getCell('B7').value = 'Sección/Serie';
    worksheet.getCell('B7').style = rowHeaderStyle;
    worksheet.getCell('B7').border = border;

    worksheet.mergeCells('C6:C7');
    worksheet.getCell('C6').value = 'Núm. de Expediente por Serie';
    worksheet.getCell('C6').style = rowHeaderStyle;
    worksheet.getCell('C6').border = border;
    worksheet.getCell('C7').border = border;

    worksheet.mergeCells('D6:D7');
    worksheet.getCell('D6').value = 'Fojas';
    worksheet.getCell('D6').style = rowHeaderStyle;
    worksheet.getCell('D6').border = border;
    worksheet.getCell('D7').border = border;

    worksheet.mergeCells('E6:E7');
    worksheet.getCell('E6').value = 'Legajos';
    worksheet.getCell('E6').style = rowHeaderStyle;
    worksheet.getCell('E6').border = border;
    worksheet.getCell('E7').border = border;

    worksheet.mergeCells('F6:F7');
    worksheet.getCell('F6').value = 'Título y Descripción del Expediente/Asunto';
    worksheet.getCell('F6').style = rowHeaderStyle;
    worksheet.getCell('F6').border = border;
    worksheet.getCell('F7').border = border;

    worksheet.mergeCells('G6:H6');
    worksheet.getCell('G6').value = 'Período';
    worksheet.getCell('G6').style = rowHeaderStyle;
    worksheet.getCell('G6').border = border;
    worksheet.getCell('H6').border = border;

    worksheet.getCell('G7').value = 'Apertura';
    worksheet.getCell('G7').style = rowHeaderStyle;
    worksheet.getCell('G7').border = border;

    worksheet.getCell('H7').value = 'Cierre';
    worksheet.getCell('H7').style = rowHeaderStyle;
    worksheet.getCell('H7').border = border;
    
    worksheet.mergeCells('I6:J6');
    worksheet.getCell('I6').value = 'Vigencia Documental';
    worksheet.getCell('I6').style = rowHeaderStyle;
    worksheet.getCell('I6').border = border;

    worksheet.getCell('I7').value = 'AT';
    worksheet.getCell('I7').style = rowHeaderStyle;
    worksheet.getCell('I7').border = border;
    worksheet.getCell('J6').border = border;

    worksheet.getCell('J7').value = 'AC';
    worksheet.getCell('J7').style = rowHeaderStyle;
    worksheet.getCell('J7').border = border;
    
    worksheet.mergeCells('K6:M6');
    worksheet.getCell('K6').value = 'Clasificación de la Información (Público, Confidencial o Reservado';
    worksheet.getCell('K6').style = rowHeaderStyle;
    worksheet.getCell('K6').border = border;
    worksheet.getCell('L6').border = border;
    worksheet.getCell('M6').border = border;

    worksheet.getCell('K7').value = 'P';
    worksheet.getCell('K7').style = rowHeaderStyle;
    worksheet.getCell('K7').border = border;

    worksheet.getCell('L7').value = 'C';
    worksheet.getCell('L7').style = rowHeaderStyle;
    worksheet.getCell('L7').border = border;

    worksheet.getCell('M7').value = 'R';
    worksheet.getCell('M7').style = rowHeaderStyle;
    worksheet.getCell('M7').border = border;

    worksheet.mergeCells('N6:N7');
    worksheet.getCell('N6').style = rowHeaderStyle;
    worksheet.getCell('N6').value = 'Valoración Primaria';
    worksheet.getCell('N6').border = border;
    worksheet.getCell('N7').border = border;

    worksheet.mergeCells('O6:O7');
    worksheet.getCell('O6').value = 'Ubicación Física/Topográfica';
    worksheet.getCell('O6').style = rowHeaderStyle;
    worksheet.getCell('O6').border = border;
    worksheet.getCell('O7').border = border;

    worksheet.mergeCells('P6:P7');
    worksheet.getCell('P6').value = 'Observaciones';
    worksheet.getCell('P6').style = rowHeaderStyle;
    worksheet.getCell('P6').border = border;
    worksheet.getCell('P7').border = border;

    for( let i = 0; i < data?.length; i++ ) { // * agregar datos (contenido)
      const row = data[i];
      worksheet.addRow(row);
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${nombre}.xlsx`);
    });
  } catch(error) {
    console.log(error);
    message.error('Ocurrió un Error al Importar a Excel');
  }
}

export const reporteSubFondoArea = async (cols, data, nombre = 'archivo-excel', titulo = '', subtitulo = '', path = null) => {
  try {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(titulo);

    if(path !== null && typeof path === 'string') {
      const img64 = await imageToBase64(path);
      const idImagen = workbook.addImage({
        base64: img64,
        extension: obtenerExtensionImagen(path),  // * jpg, gif, png
      });
      worksheet.addImage(idImagen, {  // * Aquí se acomoda la imagen
        tl: { col: 0.2, row: 0.2 }, // * midpoints
        ext: { width: 208, height: 111 },
      });
    }

    const header = cols?.map(c => (c.title));
    
    worksheet.columns = cols

    const styleTitle = { // * estilo para el título
      font: {
        bold: true,
        size: 18,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };
    const styleSub = { // * estilo para el título
      font: {
        bold: true,
        size: 12,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };
    const rowHeaderStyle = { // * estilo para el título
      font: {
        bold: true,
        size: 12,
        color: {argb:'FFFFFFFF'}
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
      fill: { 
        type: "pattern",
        pattern: "solid",
        bgColor: {argb: 'FFFFFFFF'},
        fgColor: {argb: '00736C'}
      }
    };

    worksheet.mergeCells('A1:B5');  // * combinar celdas  (lugar imagen)

    const colB=worksheet.getColumn("B");
    colB.width=26.71;
    const colC=worksheet.getColumn("C");
    colC.width=15;
    const colD=worksheet.getColumn("D");
    colD.width=15;

    const row1=worksheet.getRow("2");
    row1.height=18;
    const row2=worksheet.getRow("3");
    row2.height=23.25;
    const row3=worksheet.getRow("4");
    row3.height=16.5;

    // * Despues de mergeCells se debe aplicar estilos y valores
    worksheet.mergeCells('C3:D3')
    worksheet.getCell('C3').value = titulo; // * valor de la celda que se combinará
    worksheet.getCell('C3').style = styleTitle; // * estilo de la celda que se combinará

    // * Despues de mergeCells se debe aplicar estilos y valores
    worksheet.mergeCells('E4:K4')
    worksheet.getCell('E4').value = subtitulo; // * valor de la celda que se combinará
    worksheet.getCell('E4').style = styleSub; // * estilo de la celda que se combinará

    // worksheet.addRow([]);
    worksheet.addRow(header);

    let letras = "ABCDEFGHIJKLMNOPQRSTUVW"
    for (let i = 0; i < header.length; i++) {
      worksheet.getCell(`${letras.charAt(i)}6`).style = rowHeaderStyle
      
    }

    for( let i = 0; i < data?.length; i++ ) { // * agregar datos (contenido)
      const row = data[i];
      worksheet.addRow(row);
    }

    /* worksheet.columns.forEach((column) =>{
      var dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) =>{
        var columnLength = cell.value?.length;	
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax;
    }); */

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${nombre}.xlsx`);
    });
  } catch(error) {
    console.log(error);
    message.error('Ocurrió un Error al Importar a Excel');
  }
}

export const reporteExpediente = async (cols, data, nombre = 'archivo-excel', titulo = '', subtitulo = '', path = null) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(titulo);

    if(path !== null && typeof path === 'string') {
      const img64 = await imageToBase64(path);
      const idImagen = workbook.addImage({
        base64: img64,
        extension: obtenerExtensionImagen(path),  // * jpg, gif, png
      })

      worksheet.addImage(idImagen, {  // * Aquí se acomoda la imagen
        tl: { col: 0.2, row: 0.2 }, // * midpoints
        ext: { width: 208, height: 111 },
      })

    }

    const header = cols?.map(c => (c.title));
    worksheet.columns = cols;

    const styleTitle = {
      font: {
        bold: true,
        size: 18,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      },
    };

    const styleSubTitle = {
      font: {
        bold: true,
        size: 12,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };

    const stylesTotales = {
      font: {
        bold: false,
        size: 12,
        color: {argb: '693b7c'}
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };

    worksheet.mergeCells('A1:B6') // Logo
    worksheet.mergeCells('D1:E1') // Titulo
    worksheet.mergeCells('D2:E2') 
    worksheet.mergeCells('D3:E3') // Subtitulo
    worksheet.mergeCells('D4:E4') 
    worksheet.mergeCells('D5:E5') // Totales

    worksheet.addRow(header)
    for( let i = 0; i < data?.length; i++ ) {
      let row = data[i]
      worksheet.addRow(row)
    }
  
    worksheet.columns.forEach(( column, index ) => {

      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell, index) => {

        if((index % 2) === 0  && index > 7){
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "E7E7E7" },
          };
        }


        if(index === 7){
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "009688" },
          };
        }

        let columnLength = cell.value?.length;
        if( columnLength > dataMax ) {
          dataMax = columnLength;
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax;
    })


    const colA = worksheet.getColumn("A")
    colA.width = 15
    const colB = worksheet.getColumn("B")
    colB.width = 15
    const colC = worksheet.getColumn("C")
    colC.width = 15

    worksheet.getCell('D1').value = titulo
    worksheet.getCell('D1').style = styleTitle
    worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getCell('D3').value = subtitulo
    worksheet.getCell('D3').style = styleSubTitle
    worksheet.getCell('D3').alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getCell('D5').value = `Totales: ${data?.length}`
    worksheet.getCell('D5').style = stylesTotales
    worksheet.getCell('D5').alignment = { vertical: 'middle', horizontal: 'center' }

  
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${nombre}.xlsx`);
    });

  } catch(error) {
    console.log(error);
    message.error('Ocurrió un Error al Importar a Excel');
  }
}

export const reporteCorrespondencia = async (cols, data, nombre = 'archivo-excel', titulo = '', path = null) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(titulo);

    if(path !== null && typeof path === 'string') {
      const img64 = await imageToBase64(path);
      const idImagen = workbook.addImage({
        base64: img64,
        extension: obtenerExtensionImagen(path),  // * jpg, gif, png
      })

      worksheet.addImage(idImagen, {  // * Aquí se acomoda la imagen
        tl: { col: 0.2, row: 0.2 }, // * midpoints
        ext: { width: 208, height: 111 },
      })

    }

    const header = cols?.map(c => (c.title));
    worksheet.columns = cols;

    const styleTitle = {
      font: {
        bold: true,
        size: 18,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      },
    };

    const stylesTotales = {
      font: {
        bold: false,
        size: 12,
        color: {argb: '693b7c'}
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true
      },
    };

    worksheet.mergeCells('A1:B6') // Logo
    worksheet.mergeCells('D1:E1') // Titulo
    worksheet.mergeCells('D2:E2') 
    worksheet.mergeCells('D3:E3') // Subtitulo
    worksheet.mergeCells('D4:E4') 
    worksheet.mergeCells('D5:E5') // Totales

    worksheet.addRow(header)
    for( let i = 0; i < data?.length; i++ ) {
      let row = data[i]
      worksheet.addRow(row)
    }
  
    worksheet.columns.forEach(( column, index ) => {

      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell, index) => {

        if((index % 2) === 0  && index > 7){
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "E7E7E7" },
          };
        }


        if(index === 7){
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "009688" },
          };
        }

        let columnLength = cell.value?.length;
        if( columnLength > dataMax ) {
          dataMax = columnLength;
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax;
    })


    const colA = worksheet.getColumn("A")
    colA.width = 15
    const colB = worksheet.getColumn("B")
    colB.width = 15
    const colC = worksheet.getColumn("C")
    colC.width = 15

    worksheet.getCell('D1').value = titulo
    worksheet.getCell('D1').style = styleTitle
    worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getCell('D5').value = `Totales: ${data?.length}`
    worksheet.getCell('D5').style = stylesTotales
    worksheet.getCell('D5').alignment = { vertical: 'middle', horizontal: 'center' }

  
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${nombre}.xlsx`);
    });

  } catch(error) {
    console.log(error);
    message.error('Ocurrió un Error al Importar a Excel');
  }
}