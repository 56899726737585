import React from "react";
import { useApp, useAuth } from "../hooks";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import { AppLoading } from "../components";

const AppRouting = () => {
  const { sessionLoading} = useAuth();
  const { token } = useApp();

  return (
    <AppLoading loading={sessionLoading}>
      {token ? <PrivateRouter /> : <PublicRouter />}
    </AppLoading>
  );
};

export default AppRouting;
