import React, {useEffect, useState} from 'react';
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Typography,
  Divider,
  Upload,
  DatePicker,
} from 'antd';
import {
  FileTextOutlined,
  FilePdfTwoTone,
  FolderOpenOutlined,
} from '@ant-design/icons';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import {useQuery, useModels, useModel, useAuth, useApp} from "../../hooks";
import {ViewLoading} from "../../components";
import moment from "moment";

const {REACT_APP_API_URL: baseUrl} = process.env;

const CorrespondenciaView = () => {

    const {Option} = Select;
    const {TextArea} = Input;
    const {Title} = Typography;
    const query = useQuery();
    const id = query.get("uuid");
    const editing = !!id;
    const _baseUrl = baseUrl.replace("v1/", "")
    const titulo = id ? 'Editar Correspondencia' : "Nueva Correspondencia";
    const [form] = Form.useForm();
    const {user} = useAuth();
   
    const [loading, setLoading] = useState(false)

    const {token} = useApp();

    const [usrEmisor, setUsrEmisor] = useState([]);

    const [esEmisor, setEsEmisor] = useState(true);
    const [codigo, setCodigo] = useState("");
    const [listaArchivos, setListaArchivos] = useState([]);

    const {
      model,
      modelLoading
    } = useModel({
      name: 'correspondencia/externo',
      id: id,
      expand: 'correspondenciaResponsable, emisores, receptores, correspondenciaMedia, bitacora',
    });

    const [
      usuarios
    ] = useModels({
      name: 'usuario',
      expand: 'areas'
    });

    const [
      areas
    ] = useModels({
      name: 'area',
      expand: 'usuarios'
    });

    const [
      correspondenciaTipos,
      correspondenciaTiposLoading
    ] = useModels({
      name: 'documento-tipo',
      ordenar: 'nombre-asc'
    });


    const breadcrumbItems = [
      {
        name: "Correspondencia",
        to: '/correspondencia',
        icon: <FolderOpenOutlined/>,
      },
      {
        name: id ? "Editar " + model?.folio : "Nueva",
        to: id ? '/correspondencia/ver-correspondencia?id=' + id : '/correspondencia/nuevo',
        icon: <FileTextOutlined/>,
      },
    ];


    useEffect(() => {
      let mounted = true;
      if (mounted && model && !modelLoading) {

        let _correspondenciaReceptor = model?.correspondenciaResponsable?.filter(item => item?.tipo === 1);
        let _correspondenciaEmisor = model?.emisores;
        let idAreasReceptoras = JSON.parse(model.idAreaReceptora);

        let areasSeleccionadas = idAreasReceptoras?.map((item) => {
          return areas.filter(area => item === area?.idArea)[0]?.idArea + "/" + areas.filter(area => item === area?.idArea)[0]?.nombre
        });

        form.setFieldsValue({
          ...model,
          fecha: moment(model?.fecha),
          confidencial: model?.confidencial === 0 ? false : true,
          envios: _correspondenciaReceptor?.map((item) => item?.tipo === 1 ? item?.idUsuarioReceptor + "/" + item.nombreResponsable : undefined).filter(function (item) {
            return item !== undefined
          }),
          idAreaReceptora: areasSeleccionadas,
          areaEmisora : model?.areaEmisora,
          emisor : model?.emisor,
        })

        let _listaArchivos = model?.correspondenciaMedia?.map(item => {
          return {
            name: item?.descripcion,
            uid: item?.uuid,
            url: `${_baseUrl}v1/descarga/correspondencia?uuid=${item?.uuid}&access-token=${token}`,
            status: 'done',
            thumbUrl: `${item?.ruta}`,
          }
        });

        let _usrEnvios = [];

        _correspondenciaReceptor?.forEach(itemEnv => {
          _usrEnvios.push(usuarios.filter(item => item.idUsuario === itemEnv.idUsuarioReceptor)[0])
        })

        setCodigo(model?.uuid + "/" + (model?.fecha ? model?.fecha : "0000-00-00 00:00:00").split(" ")[0].replaceAll("-", "/") + "/" + model?.folio);
        setListaArchivos(_listaArchivos);
        setUsrEmisor(_correspondenciaEmisor[0]);
        setEsEmisor(_correspondenciaEmisor[0]?.idUsuario === user?.idUsuario || model?.idCreador === user?.idUsuario);
      }

      return () => mounted = false;

    }, [_baseUrl, areas, form, model, modelLoading, token, user?.idUsuario, usuarios]);

    const imprimirCorrespondencia = (firma) => {
      if (firma === 1) {
        if (usrEmisor?.pin === null) {
          message.warning('El usuario no tiene un PIN asignado.');
          return;
        }

        window.open(`${_baseUrl}pdf/reportes/correspondencia?id=${model.idCorrespondencia}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')
      } else {
        window.open(`${_baseUrl}pdf/reportes/correspondencia?id=${model.idCorrespondencia}Us-${user.idUsuario}&f=0&access-token=${token}`, '_blank')
      }
    }

    if (loading) return (<ViewLoading/>);

    return (
      <DefaultLayout
        title={titulo}
        breadcrumbItems={breadcrumbItems}
      >
        <Form
          form={form}
          name="form"
          layout="vertical"
        >
          {editing ? <Row gutter={12}>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 4}}
              lg={{span: 4}}
              xxl={{span: 4}}
            >
              <img src={"https://chart.googleapis.com/chart?cht=qr&chs=250x250&choe=UTF-8&chl=" + codigo}
                   style={{width: "100%", marginTop: "-28%"}} alt=""/>
            </Col>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 16}}
              lg={{span: 16}}
              xxl={{span: 16}}
            >
              {codigo}
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 4}}
              lg={{span: 4}}
              xxl={{span: 4}}
              style={{textAlign: 'right'}}
            >
              <Button
                type="primary"
                block size='large'
                name="estatus"
                value="NUEVO"
                onClick={() => imprimirCorrespondencia(0)}
                icon={<FilePdfTwoTone/>}
                style={{margin: '3px 0'}}
              >
                Imprimir
              </Button>

              <Button
                type="primary"
                hidden={(!editing || model?.firmado !== 1)}
                block size='large'
                name="estatus"
                icon={<FilePdfTwoTone/>}
                style={{margin: '3px 0'}}
                onClick={() => window.open(`${_baseUrl}pdf/reportes/correspondencia?id=${model.idCorrespondencia}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')}
              >
                Imprimir Firmado
              </Button>
            </Col>
          </Row> : null}

          {/* Asunto | Área Emisora | Emitido por | Área receptora  */}
          <Row gutter={12}>

            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 12}}
              lg={{span: 12}}
              xxl={{span: 12}}
            >
              <Form.Item
                label="Folio"
                name="folio"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder=""
                  disabled={editing && !esEmisor}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 12}}
              lg={{span: 12}}
              xxl={{span: 12}}
            >
              <Form.Item
                label="Fecha"
                name="fecha"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <DatePicker
                  autoComplete="off"
                  placeholder="Seleccione una Fecha"
                  format="DD/MM/YYYY"
                  size="large"
                  style={{width: '100%'}}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 24}}
              lg={{span: 24}}
              xxl={{span: 24}}
            >
              <Form.Item
                label="Asunto"
                name="asunto"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <TextArea
                  placeholder=""
                  disabled={editing && !esEmisor}
                  autoSize={{minRows: 2, maxRows: 20}}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 9}}
              lg={{span: 9}}
              xxl={{span: 9}}
            >
              <Form.Item
                label="Área Emisora "
                name="areaEmisora"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <Input
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 9}}
              lg={{span: 9}}
              xxl={{span: 9}}
            >
              <Form.Item
                label="Emitido por"
                name="emisor"
                //rules={[{required: true, message: 'Requerido'}]}
              >
                <Input
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 6}}
              lg={{span: 6}}
              xxl={{span: 6}}
            >
              <Form.Item
                label="Tipo"
                name="idTipo"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <Select
                  size='large'
                  loading={correspondenciaTiposLoading}
                  disabled={editing && !esEmisor}
                  placeholder="Selecciona un tipo"
                  
                >
                  {correspondenciaTipos?.map(item =>
                    <Option
                      key={"tipo-" + item?.idDocumentoTipo}
                      value={item?.idDocumentoTipo}
                    >{item?.nombre}</Option>)}
                </Select>
              </Form.Item>
            </Col>

            {/* Descripción | Tipo | Confidencial */}
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 24}}
              lg={{span: 24}}
              xxl={{span: 24}}
            >
              <Form.Item
                label="Descripción"
                name="descripcion"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <TextArea
                  placeholder=""
                  disabled={editing && !esEmisor}
                  autoSize={{minRows: 4, maxRows: 20}}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider/>

          {/* Anexos  */}
          <Row gutter={10}>

            <Col className="gutter-row" span={24}>
              <Title level={5}>Anexos</Title>
            </Col>

            <Col
              className="gutter-row"
              span={24}
            >
              <Upload
                //previewFile={{showPreviewIcon:false}}
                beforeUpload={() => false}
                multiple={true}
                fileList={listaArchivos}
                showUploadList={{showRemoveIcon: true}}
                listType="picture-card"
              >
              </Upload>

            </Col>
          </Row>
        </Form>
      </DefaultLayout>
    );
  }
;

export default CorrespondenciaView;