import React, {useState, useMemo, useCallback, useRef} from "react";
import {Table, Modal, Row, Col, Button, Form, Input, Tooltip, Spin} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';

import {
  DeleteOutlined,
  EditOutlined,
  PartitionOutlined,
  PlusOutlined,
  CloseCircleOutlined, SearchOutlined, ClearOutlined, FileExcelOutlined
} from "@ant-design/icons";

import {useModels} from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import {reporteSubFondoArea} from "../../../utilities/excel";
import moment from 'moment';
import {Tabla} from "../../../components";
import {respuestas} from "../../../utilities";

const SubFondo = () => {

  const titulo = "Sub Fondo";
  const url = '/gestion-general/sub-fondo-areas'
  const history = useHistory();
  const {form} = Form.useForm();
  let tablaRef = useRef(null);

  const [limite, setLimite] = useState(10);
  const [buscar, setBuscar] = useState('');
  const [importLoading, setImportLoading] = useState(false);
  const [actuales, setActuales] = useState(0);
  const extraParamsMemo = useMemo(() => {
    let params = {}
    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }
    return params
  }, [buscar]);


  const breadcrumbItems = [
    {
      name: "SubFondoAreas",
      to: '/gestion-general/sub-fondo-areas/',
      icon: <PartitionOutlined/>,
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={"Acción"}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idSubFondoArea}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSubFondoArea}`}>
          {`${item?.codigo}`}
        </Link>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSubFondoArea}`}>
          {`${item?.titulo}`}
        </Link>
      ),
    },
  ];

  const columnasExcel = [
    {
      title: 'Código',
      key: 'codigo',
      dataIndex: 'codigo'
    },
    {
      title: 'Nombre',
      key: 'titulo',
      dataIndex: 'titulo'
    },
    {
      title: 'Fondo',
      key: 'fondoValor',
      dataIndex: 'fondoValor'
    },
    {
      title: 'Folio Documentos',
      key: 'secuencialFolioDocumento',
      dataIndex: 'secuencialFolioDocumento'
    },
    {
      title: 'Folio Expedientes',
      key: 'secuencialFolioExpediente',
      dataIndex: 'secuencialFolioExpediente'
    },
  ];

  const infoToExport = async () => {
    let obj = {
      expand: 'expedientes,usuarioTitular,creador,modificador,fondo,usuarios',
    };
    let flag = true;
    let pagina = 1, total = 0, limite = 100;
    let registros = [];
    setImportLoading(true);
    while (flag) {
      obj["pagina"] = pagina;
      obj["limite"] = limite;
      const params = new URLSearchParams(obj).toString();
      const res = await StatusResponse.get(`sub-fondo-area?${params}`);
      const resultado = res?.resultado;
      registros = [
        ...registros,
        ...resultado
      ];
      let _paginacion = res?.paginacion;
      total = _paginacion.total;

      const actual = pagina * limite;
      setActuales(actual);
      pagina = _paginacion?.pagina + 1;

      if (total < actual) {
        flag = false;
        setImportLoading(false);
      }
    }
    for (let i = 0, l = registros.length; i < l; i++) {
      registros[i].fondoValor = registros[i].fondo?.titulo;
    }

    return [
      ...registros
    ]
  }

  const descargarExcel = async () => {
    await reporteSubFondoArea(
      columnasExcel,  // columnas
      await infoToExport(),
      "Catálogo_Disposición_Documental_Vigente_" + moment().format('DD-MM-YYYY'),  // nombre del archivo
      "Sub. Fondo Áreas", // titulo
      "", // subtitulo
      "/logo.png", // img
    )
  }

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el área "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("sub-fondo-area/eliminar", {idSubFondoArea: valor.idSubFondoArea});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idSubFondoArea > 0) {
              if (respuestas(res)) {
                tablaRef?.current?.refresh();
                resolve();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const SearchForm = () => {
    const onFinishButton = async values => {
      let params = {}
      const {buscar} = values;

      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
    }

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishButton}
      >
        <Row gutter={0}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item
              label=""
              name="buscar"
            >
              <Input
                size="large"
                placeholder="BUSCAR..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Tooltip
              tittle="Buscar"
            >
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                htmlType="submit"
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip
              tittle="Limpiar"
            >
              <Button
                icon={<ClearOutlined/>}
                type="default"
                style={{minWidth: "50px", minHeight: "40px"}}
                onClick={() => {
                  setBuscar('');
                }}
              />
            </Tooltip>
            <Tooltip
              tittle="Imprimir Excel">
              <Button
                icon={<FileExcelOutlined/>}
                style={{
                  background: "#1f6e43",
                  color: "#ffffff",
                  minWidth: "50px",
                  minHeight: "40px"
                }}
                type="default"
                onClick={() => descargarExcel()}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      emptyText='Aún no hay Sub fondos registrados.'
      withSearchButton={false}
      children={
        <>
          <Row>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 20, offset: 0}}
              lg={{span: 20, offset: 0}}
              xxl={{span: 20, offset: 0}}
            >
              <SearchForm/>
            </Col>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 4, offset: 0}}
              lg={{span: 4, offset: 0}}
              xxl={{span: 4, offset: 0}}
            >
              <Button
                onClick={() => history.push(url + '/nuevo')}
                icon={<PlusOutlined/>}
                style={{width: "100%", minWidth: "150px", minHeight: "40px"}}
                type="primary"
              >
                Agregar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tabla
                columns={columns}
                innerRef={tablaRef}
                nameURL={'sub-fondo-area'}
                expand='codigo-asc'
                extraParams={extraParamsMemo}
                order="titulo"
                scroll={{x: '30vw'}}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
}

export default SubFondo;