import React from "react";
import EditorToolbar, {modules, formats} from "./EditorToolbar";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorTexto = ({value, onValue}) => {
  return (
    <>
      <EditorToolbar/>
      <ReactQuill
        style={{display: 'block', width: '100%', height: 300, fontSize: "16px"}}
        theme="snow"
        value={value}
        onChange={onValue}
        modules={modules}
        formats={formats}
      />
    </>
  );
};

export default EditorTexto;
