import React from "react";
import { Spin } from "antd";

const AppLoading = (props) => {
  const { text, loading, children: ChildComponents } = props;

  return (
    <Spin
      style={{ height: "100%", marginTop : "25%" }}
      spinning={loading}
      size="large"
      delay={5}
      tip={text || "Cargando aplicación..."}
    >
      {loading ? <div style={{ height: "100vh" }} /> : ChildComponents}
    </Spin>
  );
};

export default AppLoading;