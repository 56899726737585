import React, { useState, useMemo, useCallback, useRef} from "react";
import { Table, Modal } from 'antd';
import {
  EditOutlined, 
  PlusOutlined,
  DeleteOutlined, 
  PartitionOutlined, 
  CloseCircleOutlined,
} from "@ant-design/icons";
import {Link, useHistory} from 'react-router-dom';
import { useModels } from '../../../hooks';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import StatusResponse from '../../../services/statusResponse';
import { SimpleTableLayout } from '../../../components/layouts';
import Tabla from "../../../components/Tabla";
import { modalEliminar } from "../../../utilities";

const Areas = () => {

  const endPoint = 'area';
  let tablaRef = useRef(null);
  const [buscarParams, setBuscarParams] = useState({});

  const titulo = "Áreas";
  const url = '/gestion-general/areas'
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);

  const defaultText = (prop, item) => prop 
    ? <span className="link-pointer" onClick={()=>history.push(`${url}/editar?id=${item?.idArea}`)}>{prop}</span> 
    : <span style={{color: '#c7c3c3'}}>---</span>;
  
  const [
    models,
    modelsLoading,
    ,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'area',
    expand: 'fondo, adscripcion',
    ordenar: 'idArea-asc',
    limite: limite
  });
  
  const breadcrumbItems = [
    {
      name: "Áreas",
      to: '/gestion-general/areas/',
      icon: <PartitionOutlined />,
    },
  ];

  const buttonData = {
    text: "Agregar",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined />,
    props: { disabled: false, type: 'primary', }
  };
  
  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.idArea}
          text={"Acción"}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idArea}`)
            },
            {
              name:'Diagrama',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/diagrama?id=${item.idArea}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => {
                console.log('item: ',item);
                modalEliminar("idArea", item?.idArea, item?.nombre, endPoint, tablaRef)
              },
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: '#',
      dataIndex: 'idArea',
      key: 'idArea',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idArea}`}>
          {`${item?.idArea}`}
        </Link>
      )
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idArea}`}>
          {`${item?.nombre}`}
        </Link>
      )
    },
    {
      title: 'Fondo',
      dataIndex: 'idFondo',
      key: 'idFondo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idFondo}`}>
          {`${item?.fondo?.titulo}`}
        </Link>
      )
    },
  ];

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = { ...params, buscar: search};
    params = { ...params, pagina: 1 };
    await refreshModels(true, params);
    if(!modelsLoading) setSearching(false);
    setBuscarParams(params);
  };
  
  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setLimite(size);
      await refreshModels ( true, {
        ordenar: 'nombre-asc',
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );
  
  //configuracion de la paginación
  const configPagination = useMemo( ()=> {
    if (modelsPage){
      let size = limite;
      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: ( _, newSize)  => ( size = newSize ),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} áreas.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
        showSizeChanger: true
      }
    }return null;
  }, [ onSetPageCallback, limite, modelsPage ]);

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay áreas registrados.'
      children={
        <>
          <Tabla
          columns={columns}
          innerRef={tablaRef}
          nameURL={endPoint}
          expand="fondo, adscripcion"
          order="idArea-desc"
          scroll={{ x: '30vw' }}
          extraParams={buscarParams}
        />
        </>
      }
    />
  );
}

export default Areas;