import React, {useEffect, useMemo, useState} from 'react';
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  Modal,
  Table,
  Select,
  Divider,
  message,
  InputNumber,
} from 'antd';
import {
  SaveOutlined,
  DeleteOutlined,
  FileAddOutlined,
  UserAddOutlined,
  ArrowLeftOutlined,
  PartitionOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import ActionsButton from "../../../components/actionsbutton";
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import {ViewLoading, Select as SelectComponent} from '../../../components';
import {useQuery, useModels, useModel} from "../../../hooks";


const SubFondoForm = () => {

  const titulo = 'Sub Fondo Área';
  const [form] = Form.useForm();
  const history = useHistory();
  const {Option} = Select;
  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;

  const [loading, setLoading] = useState(false);
  const [idsUsuariosArea, setIdsUsuariosArea] = useState([]);
  const [usuariosTabla, setUsuariosTabla] = useState([]);


  const params = useMemo(() => ({
    name: 'usuario',
    ordenar: 'nombre-asc',
  }), []);

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'sub-fondo-area',
    id: id,
    expand: 'fondo,usuarios'
  });

  // Fondos
  const [
    fondos,
    fondosLoading,
  ] = useModels({
    name: 'fondo',
    ordenar: 'idFondo-desc',
    limite: 10,
  });

  const columns = [
    {
      title: '',
      key: 'Acciones',
      name: 'Remover',
      render: (_, usuario) => (<Button
        icon={<DeleteOutlined/>}
        onClick={(_, usuario) => modalEliminar(usuario)}
        style={{color: "red"}}
      />)
    },
    {
      title: 'Usuario',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, item) => {
        return item?.nombreCompleto;
      }
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
      render: (_, item) => {
        return item?.telefono;
      },
    },
  ];

  const breadcrumbItems = [
    {
      name: "Sub Fondo Áreas",
      to: '/gestion-general/sub-fondo-areas/',
      icon: <PartitionOutlined/>,
    },
    {
      name: editing ? "Sub Fondo Áreas" : "Nuevo",
      to: editing ? '/gestion-general/sub-fondo-areas/editar?id=' + id :
        '/gestion-general/sub-fondo-areas/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/sub-fondo-areas/'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const agregarUsuarios = async () => {

    // Validar si selecciono un unsuario
    if (idsUsuariosArea.length === 0) {
      message.info({
        content: 'Debes de seleccionar al menos un usuario',
        style: {marginTop: '14vh'}
      });
      return;
    }

    const _usuariosTabla = [...usuariosTabla, idsUsuariosArea];

    const resultUsuarios = _usuariosTabla.reduce((unico, o) => {
      if (!unico.some(obj => obj.idUsuario === o.idUsuario)) {
        unico.push(o)
      }
      return unico
    }, []);

    setUsuariosTabla(resultUsuarios)
    setIdsUsuariosArea([])
    form.setFieldsValue({
      usuarios: []
    });

  };

  const modalEliminar = (usuario) => {

    Modal.confirm({
      title: "Remover",
      icon: <CloseCircleOutlined style={{color: "#ff5722"}}/>,
      content: `¿Desea remover del sub fondo el usuario '${usuario?.nombre} ${usuario?.apellidoPaterno} ${usuario?.apellidoMaterno}' ?`,
      okText: "Sí",
      cancelText: "Cancelar",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {

        let res = null
        if (editing) {
          res = await StatusResponse.delete('sub-fondo-area/eliminar-usuario', {
            idSubFondoArea: id,
            idUsuario: usuario?.idUsuario
          });
        }

        let _usuariosTabla = [...usuariosTabla];
        const index = _usuariosTabla.findIndex(u => u.idUsuario === usuario.idUsuario)
        if (index > -1) {
          _usuariosTabla.splice(index, 1)
        }

        setUsuariosTabla(_usuariosTabla)

        try {
          if (res && res?.status === 200) {
            Modal.success({
              title: 'Eliminado',
              content: res?.mensaje,
            });
          } else if (res?.status === 400) {
            Modal.error({
              title: 'Error: no se logro eliminar el usuario',
              content: res?.mensaje,
            });
          }
        } catch (error) {
          console.log('error al eliminar usuario: ', error)
        }

      },
    });
  };

  const onFinish = async (values) => {

    let body = {...values}

    if (editing) {
      body.idSubFondoArea = id;
    }

    body.usuarioArea = usuariosTabla.map(u => {
      return u.idUsuario
    });

    try {
      setLoading(true)
      const res = await StatusResponse.post('sub-fondo-area/guardar', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{arrayError.map((m, i) =>
                <span key={(i + 1)}> -{m} <br/>
              </span>)
              }</div>
            )
          });
          console.log(arrayError)
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'}
          });
          history.push('/gestion-general/sub-fondo-areas');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }


  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      setUsuariosTabla(model?.usuarios)
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idCargo,
        usuarios: []
      })
    }

    return () => mounted = false;
  }, [form, modelLoading, model]);

  if (modelLoading) return <ViewLoading/>

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* Código del área | Nombre del área | Fondo  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 4}}
            lg={{span: 4}}
            xxl={{span: 4}}
          >
            <Form.Item
              label="Código"
              name="codigo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size='large'
                placeholder="Código"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 10}}
            lg={{span: 10}}
            xxl={{span: 10}}
          >
            <Form.Item
              label="Nombre"
              name="titulo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size='large'
                placeholder="Nombre"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 10}}
            lg={{span: 10}}
          >
            <Form.Item
              label="Fondo"
              name="idFondo"
              rules={[{required: true, message: 'Requerido'}]}
              hasFeedback
            >
              <Select
                size='large'
                loading={fondosLoading}
                disabled={fondosLoading}
                placeholder="Selecciona un fondo"
              >
                {fondos.map((item, index) => (
                  <Option key={index} value={item.idFondo}>{item.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* Folio documentos | folio expendiente  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Folio documentos"
              name="secuencialFolioDocumento"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Folio expedientes"
              name="secuencialFolioExpediente"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        {/* Selector de usuarios  */}
        <Row gutter={10}>
          <Col className="gutter-row" span={18}>
            <Form.Item
              label="Usuarios"
              name="usuarios"
            >
              <SelectComponent
                modelsParams={params}
                labelProp="nombre"
                size={"large"}
                placeholder="Seleccionar un Usuario"
                valueProp="idUsuario"
                allowClear={true}
                autoComplete="off"
                onChange={(id, row) => {
                  console.log(id)
                  setIdsUsuariosArea(row);
                }}
                render={(_, row) => `${row.nombre} ${row.apellidoPaterno} ${row.apellidoMaterno}`}
                append={[model?.seccion]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label="&nbsp;">
              <Button
                type="primary"
                block
                size='large'
                icon={<UserAddOutlined/>}
                onClick={agregarUsuarios}
              >Agregar</Button>
            </Form.Item>
          </Col>
        </Row>
        {/* Tabla usuarios */}
        <Row gutter={10}>
          <Col className="gutter-row" span={24}>
            <Table
              dataSource={usuariosTabla}
              rowKey="idUsuario"
              columns={columns}
              size='small'
            />
          </Col>
        </Row>
        <Divider/>
        {/* Boton de guardar  */}
        <Row gutter={10}>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default SubFondoForm;