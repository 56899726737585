import { useCallback, useMemo, useState , useRef} from "react";
import { Link, useHistory } from "react-router-dom";
import { useModels } from "../../../hooks";
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ActionsButton } from "../../../components";
import { Modal, Table } from "antd";
import StatusResponse from "../../../services/statusResponse";
import { SimpleTableLayout } from "../../../components/layouts";
import Tabla from "../../../components/Tabla";
import { modalEliminar } from "../../../utilities";

const Votacion = () => {
  const titulo = "Votación";
  const url = "/gestion-general/votacion";
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [buscarParams, setBuscarParams] = useState({});
  const [limite, setLimite] = useState(10);
  let tablaRef = useRef(null);

  const endPoint = 'votacion';

  const [models, modelsLoading, , modelsPage, refreshModels] = useModels({
    name: "votacion",
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/votacion",
    },
  ];

  const buttonData = {
    text: "Agregar Votación",
    to: () => history.push(url + "/nuevo"),
    icon: <PlusOutlined />,
    props: { disabled: false, type: "primary" },
  };

  const columns = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      width: 120,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idVotacion}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar("idVotacion", item.idVotacion, item.nombre, endPoint, tablaRef),
              styleProps: { color: "#ff4d4f" },
            },
          ]}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idVotacion}`}>
          {`${item?.nombre}`}
        </Link>
      ),
    },
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idVotacion}`}>
          {`${item?.clave}`}
        </Link>
      ),
    },
  ];

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = { ...params, buscar: search };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    if (!modelsLoading) setSearching(false);
    setBuscarParams(params);
  };

  const onSetPageCallback = useCallback(
    async (page, size) => {
      setLimite(size);
      await refreshModels(true, {
        ordenar: "idVotacion-desc",
        pagina: page,
        limite: size,
      });
    },
    [refreshModels]
  );

  const configPagination = useMemo(() => {
    if (modelsPage) {
      let size = limite;

      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} Votaciones.`,
        locale: { items_per_page: "/ página" },
        pageSizeOptions: [10, 20, 30].filter((val) => val <= modelsPage.total),
        showSizeChanger: true,
      };
    }
    return null;
  }, [onSetPageCallback, limite, modelsPage]);

  return(
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder="Buscar..."
      searchLoading={searching}
      onSearchClicked={onSearch}
      children={
        <>
          <Tabla 
            columns={columns}
            innerRef={tablaRef}
            nameURL={endPoint}
            expand=""
            order="idVotacion-desc"
            scroll={{ x: '30vw' }}
            extraParams={buscarParams}
          />
        </>
      }
    />
  );

};

export default Votacion;
