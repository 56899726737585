import React, {useEffect, useMemo, useState} from 'react';
import {Card, Col, Divider, Row, Statistic} from "antd";
import {
  StopOutlined,
  StockOutlined,
  InboxOutlined,
  FolderOutlined,
  HistoryOutlined,
  CalendarOutlined,
  FolderOpenOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {useAuth, useModels,} from "../../hooks";
import {ViewLoading} from "../../components";
import {DefaultLayout} from "../../components/layouts";
import {useHistory} from "react-router-dom";
import Title from "antd/lib/typography/Title";

const Inicio = () => {
  const styles = {
    siteStatisticDemoCard: {
      padding: 0,
    },
  };
  const {user} = useAuth();
  const idUsuarioMemo = useMemo(() => ({idUsuario: user?.idUsuario}), [user?.idUsuario]);

  const [tramiteDocumentos, setTramiteDocumentos] = useState(0)
  const [atendidoDocumentos, setAtendidoDocumentos] = useState(0)
  const [canceladoDocumentos, setCanceladoDocumentos] = useState(0)
  const [recibidoDocumentos, setRecibidoDocumentos] = useState(0)
  const [nuevoDocumentos, setNuevoDocumentos] = useState(0)
  const [todosDocumentos, setTodosDocumentos] = useState(0)
  const [eficienciaDocumentos, setEficienciaDocumentos] = useState(0)

  const [tramiteCorrespondencia, setTramiteCorrespondencia] = useState(0)
  const [atendidoCorrespondencia, setAtendidoCorrespondencia] = useState(0)
  const [canceladoCorrespondencia, setCanceladoCorrespondencia] = useState(0)
  const [recibidoCorrespondencia, setRecibidoCorrespondencia] = useState(0)
  const [nuevoCorrespondencia, setNuevoCorrespondencia] = useState(0)
  const [todosCorrespondencia, setTodosCorrespondencia] = useState(0)
  const [eficienciaCorrespondencia, setEficienciaCorrespondencia] = useState(0)
  const [elaboradosCorrespondencia, setElaboradosCorrespondencia] = useState(0)

  const history = useHistory();

  const [
    modelDocumentos,
    modelDocumentosLoading
  ] = useModels({
    name: 'perfil/dashboard-documento',
    extraParams: idUsuarioMemo,
  });

  const [
    modelCorrespondencia,
    modelCorrespondenciaLoading
  ] = useModels({
    name: 'perfil/dashboard-correspondencia',
    extraParams: idUsuarioMemo,
  });

  const Documento = (tipo) => {
    history.push("/documentos?opt=" + tipo)
  }

  const Correspondencia = (tipo) => {
    history.push("/correspondencia?opt=" + tipo)
  }

  useEffect(() => {
    let mounted = true;

    console.log("Documentos", modelDocumentos)
    if (mounted && modelDocumentos && !modelDocumentosLoading) {
      let _tramite = modelDocumentos.filter(item => item.estatusResponsable === "TRAMITE").length;
      let _atendido = modelDocumentos.filter(item => item.estatusResponsable === "ATENDIDO").length;
      let _cancelado = modelDocumentos.filter(item => item.estatusResponsable === "CANCELADO").length;
      let _recibido = modelDocumentos.filter(item => item.estatusResponsable === "RECIBIDO").length;
      let _nuevo = modelDocumentos.filter(item => item.estatusResponsable === "NUEVO").length;
      let _todos = _tramite + _atendido + _cancelado + _recibido + _nuevo;

      setEficienciaDocumentos(todosDocumentos !== 0 ? 100 - ((100 * _nuevo) / todosDocumentos) : 100);

      setTramiteDocumentos(_tramite);
      setAtendidoDocumentos(_atendido);
      setCanceladoDocumentos(_cancelado);
      setRecibidoDocumentos(_recibido);
      setNuevoDocumentos(_nuevo);
      setTodosDocumentos(_todos);
    }
    return () => mounted = false;
  }, [modelDocumentosLoading, modelDocumentos, todosDocumentos]);

  useEffect(() => {
    let mounted = true;
    console.log("Correspondencia", modelCorrespondencia)

    if (mounted && modelCorrespondencia && !modelCorrespondenciaLoading) {
      let _tramite = modelCorrespondencia.filter(item => item.estatusResponsable === "TRAMITE").length;
      let _atendido = modelCorrespondencia.filter(item => item.estatusResponsable === "ATENDIDO").length;
      let _cancelado = modelCorrespondencia.filter(item => item.estatusResponsable === "CANCELADO").length;
      let _recibido = modelCorrespondencia.filter(item => item.estatusResponsable === "RECIBIDO").length;
      let _nuevo = modelCorrespondencia.filter(item => item.estatusResponsable === "NUEVO").length;
      let _todos = _tramite + _atendido + _cancelado + _recibido + _nuevo;

      setEficienciaCorrespondencia(todosCorrespondencia !== 0 ? 100 - ((100 * _nuevo) / todosCorrespondencia) : 100);

      setTramiteCorrespondencia(_tramite);
      setAtendidoCorrespondencia(_atendido);
      setCanceladoCorrespondencia(_cancelado);
      setRecibidoCorrespondencia(_recibido);
      setNuevoCorrespondencia(_nuevo);
      setTodosCorrespondencia(_todos);
      setElaboradosCorrespondencia(_todos)
    }
    return () => mounted = false;
  }, [modelCorrespondenciaLoading, modelCorrespondencia, todosCorrespondencia]);

  useEffect(()=>{
    history.push('/archivo/tramite');
  },[history])


  if (modelDocumentosLoading || modelCorrespondenciaLoading) return <ViewLoading/>

  return (
    <DefaultLayout title="Documentación">
      <div style={styles.siteStatisticDemoCard}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={3}>Correspondencia</Title>
          </Col>
        </Row>
        <Row gutter={16}>

          {/* Columna izquierda  */}
          <Col span={18}>

            {/* Sin abrir | Recibidos | Todos  */}
            <Row gutter={16}>

              <Col span={8}>
                <Card
                  onClick={() => Correspondencia("NUEVO")}
                >
                  <Statistic
                    title="Sin abrir"
                    value={nuevoCorrespondencia}
                    valueStyle={{
                      color: "#e91e63",
                    }}
                    prefix={<FolderOpenOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Correspondencia("RECIBIDO")}
                >
                  <Statistic
                    title="Recibidos"
                    value={recibidoCorrespondencia}
                    valueStyle={{
                      color: "#3f51b5",
                    }}
                    prefix={<InboxOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Correspondencia("todos")}
                >
                  <Statistic
                    title="Todos"
                    value={todosCorrespondencia}
                    valueStyle={{
                      color: "#03a9f4",
                    }}
                    prefix={<FolderOutlined/>}
                  />
                </Card>
              </Col>

            </Row>

            {/* En trámite | Atendidos | Cancelados  */}
            <Row gutter={16} style={{marginTop: 10}}>

              <Col span={8}>
                <Card
                  onClick={() => Correspondencia("TRAMITE")}
                >
                  <Statistic
                    title="En trámite"
                    value={tramiteCorrespondencia}
                    valueStyle={{
                      color: "#ffc107",
                    }}
                    prefix={<HistoryOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Correspondencia("ATENDIDO")}
                >
                  <Statistic
                    title="Atendidos"
                    value={atendidoCorrespondencia}
                    valueStyle={{
                      color: "#ff5722",
                    }}
                    prefix={<CheckCircleOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Correspondencia("CANCELADO")}
                >
                  <Statistic
                    title="Cancelados"
                    value={canceladoCorrespondencia}
                    valueStyle={{
                      color: "#cf1322",
                    }}
                    prefix={<StopOutlined/>}
                  />
                </Card>
              </Col>

            </Row>

          </Col>

          {/* Columna derecha  */}
          <Col span={6}>

            {/* Eficiencia Documental  */}
            <Row gutter={16}>

              <Col span={24}>
                <Card style={{background: "#f8f8f8"}}>
                  <Statistic
                    title="Eficiencia Documental"
                    value={eficienciaCorrespondencia}
                    valueStyle={{
                      color: "#58a90f",
                    }}
                    prefix={<StockOutlined/>}
                    suffix="%"
                  />
                </Card>
              </Col>

            </Row>

            {/* Última interacción */}
            <Row gutter={16} style={{marginTop: 10}}>

              <Col span={24}>
                <Card style={{background: "#f8f8f8"}}>
                  <Statistic
                    title="Elaborados"
                    value={elaboradosCorrespondencia}
                    valueStyle={{
                      color: "#607d8b",
                    }}
                    prefix={<CalendarOutlined/>}
                  />
                </Card>
              </Col>

            </Row>

          </Col>

        </Row>
      </div>
      <Divider/>
      <div style={styles.siteStatisticDemoCard}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={3}>Documentación</Title>
          </Col>
        </Row>
        <Row gutter={16}>

          {/* Columna izquierda  */}
          <Col span={18}>

            {/* Sin abrir | Recibidos | Todos  */}
            <Row gutter={16}>

              <Col span={8}>
                <Card
                  onClick={() => Documento("NUEVO")}
                >
                  <Statistic
                    title="Sin abrir"
                    value={nuevoDocumentos}
                    valueStyle={{
                      color: "#e91e63",
                    }}
                    prefix={<FolderOpenOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Documento("RECIBIDO")}
                >
                  <Statistic
                    title="Recibidos"
                    value={recibidoDocumentos}
                    valueStyle={{
                      color: "#3f51b5",
                    }}
                    prefix={<InboxOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Documento("todos")}
                >
                  <Statistic
                    title="Todos"
                    value={todosDocumentos}
                    valueStyle={{
                      color: "#03a9f4",
                    }}
                    prefix={<FolderOutlined/>}
                  />
                </Card>
              </Col>

            </Row>

            {/* En trámite | Atendidos | Cancelados  */}
            <Row gutter={16} style={{marginTop: 10}}>

              <Col span={8}>
                <Card
                  onClick={() => Documento("TRAMITE")}
                >
                  <Statistic
                    title="En trámite"
                    value={tramiteDocumentos}
                    valueStyle={{
                      color: "#ffc107",
                    }}
                    prefix={<HistoryOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Documento("ATENDIDO")}
                >
                  <Statistic
                    title="Atendidos"
                    value={atendidoDocumentos}
                    valueStyle={{
                      color: "#ff5722",
                    }}
                    prefix={<CheckCircleOutlined/>}
                  />
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  onClick={() => Documento("CANCELADO")}
                >
                  <Statistic
                    title="Cancelados"
                    value={canceladoDocumentos}
                    valueStyle={{
                      color: "#cf1322",
                    }}
                    prefix={<StopOutlined/>}
                  />
                </Card>
              </Col>

            </Row>

          </Col>

          {/* Columna derecha  */}
          <Col span={6}>

            {/* Eficiencia Documental  */}
            <Row gutter={16}>

              <Col span={24}>
                <Card style={{background: "#f8f8f8"}}>
                  <Statistic
                    title="Eficiencia Documental"
                    value={eficienciaDocumentos}
                    valueStyle={{
                      color: "#58a90f",
                    }}
                    prefix={<StockOutlined/>}
                    suffix="%"
                  />
                </Card>
              </Col>

            </Row>

            {/* Última interacción */}
            <Row gutter={16} style={{marginTop: 10}}>

              <Col span={24}>
                <Card style={{background: "#f8f8f8"}}>
                  <Statistic
                    title="Última interacción"
                    value="21/06/2022"
                    valueStyle={{
                      color: "#607d8b",
                    }}
                    prefix={<CalendarOutlined/>}
                  />
                </Card>
              </Col>

            </Row>

          </Col>

        </Row>
      </div>
    </DefaultLayout>
  );
};

export default Inicio;
