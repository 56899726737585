import React from "react";

const DialogContext = React.createContext();

export function DialogProvider(props) {
  const [open, setOpen] = React.useState(false);
  const [dialogTitle] = React.useState("Advertencia!");
  const [dialogMessage] = React.useState(
    "Es necesario confirmar el correo para continuar:"
  );
  // const [position, setPosition] = React.useState({
  //   vertical: "bottom",
  //   horizontal: "right",
  // });
  // const [severity, setSeverity] = React.useState("info");
  // const [message, setMessage] = React.useState("");

  // React.useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 5000);
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [open]);

  const showDialog = React.useCallback(({ onCancel = null, onOk = null }) => {
    setOpen(true);
    if (onCancel) onCancel();
    if (onOk) onOk();
  }, []);

  const closeDialog = React.useCallback(() => {
    setOpen(false);
    // setMessage(message);
    // setSeverity(severity);
    // if (position) setPosition(position);
  }, []);

  const memData = React.useMemo(() => {
    // const closeAlert = () => {
    //   setOpen(false);
    //   setTimeout(() => {
    //     setPosition(defaultPlace);
    //     setSeverity(defaultColor);
    //     setIcon(defaultIcon);
    //     setMessage(defaultMessage);
    //   }, 2000);
    // };
    return {
      open,
      dialogTitle,
      dialogMessage,
      showDialog,
      closeDialog,
      // position,
      // severity,
      // message,
      // showDialog,
      // closeAlert,
    };
  }, [
    open,
    dialogTitle,
    dialogMessage,
    showDialog,
    closeDialog,
    // , position, severity, message, showDialog
  ]);

  return <DialogContext.Provider value={memData} {...props} />;
}

export function useConfirmDialog() {
  const context = React.useContext(DialogContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: confirm dialog context not defined.";
  }
  return context;
}
