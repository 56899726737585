import {  useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ActionsButton } from "../../../components";
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { SimpleTableLayout } from "../../../components/layouts";
import Tabla from "../../../components/Tabla";

const AccesoArco = () =>{
  const endPoint = 'acceso-arco';
  let tablaRef = useRef(null);

  const [buscarParams, setBuscarParams] = useState({});


  const titulo = "Acceso o ARCOP";
  const url = "/gestion-general/acceso-arcop";
  const history = useHistory();

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/acceso-arcop",
    },
  ];

  const buttonData = {
    text: "Agregar Acceso o Arcop",
    to: () => history.push(url + "/nuevo"),
    icon: <PlusOutlined />,
    props: { disabled: false, type: "primary" },
  };

  const columns = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      width: 120,
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: "Editar",
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idAccesoArco}`),
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              //onClick: () => modalEliminar(item),
              styleProps: { color: "#ff4d4f" },
            },
          ]}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idAccesoArco}`}>
          {`${item?.nombre}`}
        </Link>
      )
    },
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idAccesoArco}`}>
          {`${item?.clave}`}
        </Link>
      )
    },
  ];

  const onSearch = async (search) => {
    let params = {...buscarParams};
    if (search.length > 0) {
      params.buscar = search
    } else {
      delete params.buscar;
    }
    setBuscarParams(params)
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder="Buscar..."
      onSearchClicked={onSearch}
      emptyText='Aún no existen registros.'
    >
      <Tabla
        columns={columns}
        innerRef={tablaRef}
        nameURL={endPoint}
        expand=""
        order="idAccesoArco-desc"
        scroll={{x: '30vw'}}
        extraParams={buscarParams}
      />
    </SimpleTableLayout>
  );

}

export default AccesoArco;