import React, {useState, useMemo, useCallback, useRef} from 'react'
import {
  Modal,
  Table,
} from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  DollarOutlined, CloseCircleOutlined
} from '@ant-design/icons';

import {Link, useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {useModels} from "../../../hooks";
import StatusResponse from '../../../services/statusResponse';

import { modalEliminar } from '../../../utilities';
import Tabla from '../../../components/Tabla';

const FondoListado = () => {
  const endPoint = 'fondo';
  let tablaRef = useRef(null);

  const [buscarParams, setBuscarParams] = useState({});

  const titulo = "Fondos";
  const url = '/gestion-general/fondos';
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);

  const [
    models,
    modelsLoading,
    ,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'fondo',
    expand: 'dependencia',
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/fondos/',
      icon: <DollarOutlined/>,
    },
  ];

  const buttonData = {
    text: "Agregar Fondo",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary',}
  };
  
  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idFondo}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar("idFondo", item.idFondo, item.nombre, endPoint, tablaRef),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idFondo}`}>
          {`${item?.codigo}`}
        </Link>
      ),
    }, {
      title: 'Dependencia',
      dataIndex: 'idDependencia',
      key: 'idDependencia',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idDependencia}`}>
          {`${item?.dependencia?.titulo}`}
        </Link>
      ),
    }, {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idFondo}`}>
          {`${item?.titulo}`}
        </Link>
      ),
    }, {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idDependencia}`}>
          {`${item?.descripcion}`}
        </Link>
      ),
    },
  ];

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = {...params, buscar: search };
    params = {...params, pagina: 0};
    await refreshModels(true, params);
    if(!modelsLoading) setSearching(false);
    setBuscarParams(params);
  };

  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setLimite(size);
      await refreshModels ( true,  {
        ordenar: 'idFondo-desc',
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );

  const configPagination = useMemo( () => {
    if( modelsPage ) {
    let size = limite;
    
    return {        
      total: modelsPage.total,
      pageSize: modelsPage.limite,
      onShowSizeChange: ( _, newSize)  => ( size = newSize ),
      onChange: async (v) => await onSetPageCallback(v, size),
      showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} fondos.`,
      locale: { items_per_page: '/ página' },
      pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
      showSizeChanger: true
    }}
    return null;
  }, [ onSetPageCallback, limite, modelsPage ] );

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay áreas registrados.'
      children={
        <>
          <Tabla
            columns={columns}
            innerRef={tablaRef}
            nameURL={endPoint}
            expand="dependencia"
            order="idFondo-desc"
            scroll={{ x: '30vw' }}
            extraParams={buscarParams}
          />
        </>
      }
    />
  )
}

export default FondoListado