import {
  TeamOutlined,
  BankOutlined,
  BookOutlined,
  DollarOutlined,
  ProfileOutlined,
  SettingOutlined,
  SnippetsOutlined,
  ContactsOutlined,
  PartitionOutlined,
  FolderOpenOutlined,
  ScheduleOutlined,
  FileSearchOutlined, AlertOutlined, FileDoneOutlined,
  DragOutlined, QuestionCircleOutlined,
  HddOutlined, DeleteRowOutlined,
  NotificationOutlined, RiseOutlined, AuditOutlined, LockOutlined,
  GiftOutlined, DatabaseOutlined, BorderlessTableOutlined, EditOutlined
} from "@ant-design/icons";
import {
  ExpedienteTramite,
  ArchivoTramite,
} from "../views/archivo-tramite";
import {
  ExpedienteConcetracion,
  ExpedienteConcetracionDetalle
} from "../views/archivo-concentracion";
import {Perfil} from "../views/perfil";
import NotFoundView from "../views/shared/NotFound";
import NotAuthorizedView from "../views/shared/NotAuthorized";
import {Areas, AreaForm} from "../views/gestion-general/area";
import {Cargos, CargoForm} from "../views/gestion-general/cargo";
import {SubFondo, SubFondoForm} from "../views/gestion-general/sub-fondo";
import {Usuarios, UsuarioForm} from "../views/usuario";
import {Documentos, DocumentoForm} from "../views/documento";
import {FondoDetalle, FondoListado} from "../views/gestion-general/fondo";
import {SerieDetalle, SerieListado} from "../views/gestion-general/serie";
import {SeccionDetalle, SeccionListado} from "../views/gestion-general/seccion";
import {UbicacionListado, UbicacionDetalle} from "../views/gestion-general/ubicacion";
import {DependenciaDetalle, DependenciaListado} from "../views/gestion-general/dependencia";
import {Permisos, PermisosForm, PermisosModuloForm} from "../views/gestion-general/permisos";
import {DocumentoTipoListado, DocumentoTipoDetalle} from "../views/gestion-general/documento-tipo";
import {Correspondencia, CorrespondenciaForm, CorrespondenciaView} from "../views/correspondencia";
import {Via, ViaDetalle} from "../views/gestion-general/via";
import {DiaFestivoDetalle, DiaFestivoListado} from "../views/gestion-general/dias-festivos";
import {Inicio} from "../views/inicio";
import DocumentoView from "../views/documento/DocumentoView";
import Sentido from "../views/gestion-general/sentido/Sentido";
import SentidoDetalle from "../views/gestion-general/sentido/SentidoDetalle";
import {ActoOmision, ActoOmisionDetalle} from "../views/gestion-general/acto-omision";
import {EstatusRecurso, EstatusRecursoDetalle} from "../views/gestion-general/estatus-recurso";
import MotivoInconformidad from "../views/gestion-general/motivo-inconformidad/MotivoInconformidad";
import MotivoInconformidadDetalle from "../views/gestion-general/motivo-inconformidad/MotivoInconformidadDetalle";
import Ponencia from "../views/gestion-general/ponencia/Ponencia";
import PonenciaDetalle from "../views/gestion-general/ponencia/PonenciaDetalle";
import Votacion from "../views/gestion-general/votacion/Votacion";
import VotacionDetalle from "../views/gestion-general/votacion/VotacionDetalle";
import AccesoArco from "../views/gestion-general/acceso-arco/AccesoArco";
import AccesoArcoDetalle from "../views/gestion-general/acceso-arco/AccesoArcoDetalle";
import {Pr, PrDetalle} from "../views/gestion-general/pr";
import Estatus from "../views/gestion-general/estatus/Estatus";
import EstatusDetalle from "../views/gestion-general/estatus/EstatusDetalle";
import {ArchivoBaja, ExpedienteBajaDetalle,} from "../views/archivo-baja";
import ArchivoHistorico from "../views/archivo-historico/ArchivoHistorico";
import ExpedienteHistorico from "../views/archivo-historico/ArchivoHistorico";
import {Firmantes, FirmantesForm} from "../views/gestion-general/firmantes";


const sharedRoutes = [
  {
    path: "/no-autorizado",
    component: NotAuthorizedView,
  },
  {
    path: "*",
    component: NotFoundView,
  },
];

/**
 * Permisos;
 * se agrega la palabra ver seguido de dos puntos y el id del permiso
 * ejemplo ver:12
 */
const dashboardRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Dashboard",
    icon: <SnippetsOutlined/>,
    component: Inicio,
  },
  {
    layout: "dashboard",
    path: "/correspondencia",
    name: "Correspondencia",
    icon: <FolderOpenOutlined/>,
    sidebar: "single",
    ver: 14,
    routes: [
      {
        path: "/",
        component: Correspondencia,
      },
      {
        path: "/nuevo",
        component: CorrespondenciaForm
      },
      {
        path: "/editar",
        component: CorrespondenciaForm
      },
      {
        path: "/ver-correspondencia",
        component: CorrespondenciaView
      }
    ]
  },
  {
    layout: "dashboard",
    path: "/documento",
    name: "Documentos",
    icon: <FolderOpenOutlined/>,
    sidebar: "single",
    ver: 10,
    routes: [
      {
        path: "/",
        component: Documentos,
      },
      {
        path: "/nuevo-documento",
        component: DocumentoForm
      },
      {
        path: "/editar-documento",
        component: DocumentoForm
      },
      {
        path: "/ver-documento",
        component: DocumentoView
      }
    ]
  },
  {
    layout: "dashboard",
    path: "/archivo",
    name: "Archivo",
    icon: <DatabaseOutlined/>,
    sidebar: "collapse",
    ver: 86,
    routes: [

      {
        layout: "dashboard",
        path: "/tramite",
        name: "Trámite",
        icon: <DatabaseOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: ExpedienteTramite,
          },
          {
            path: "/nuevo",
            component: ArchivoTramite,
          },
          {
            path: "/editar",
            component: ArchivoTramite,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/concentracion",
        name: "Concentración",
        icon: <BorderlessTableOutlined/>,
        sidebar: "single",
        rol: ["ADMINISTRADOR", "RAC", "RGA"],
        routes: [
          {
            path: "/",
            component: ExpedienteConcetracion,
          },
          {
            path: "/detalle",
            component: ExpedienteConcetracionDetalle,
          },
          {
            path: "/editar",
            component: ExpedienteConcetracionDetalle,
          }
        ]
      },
      {
        layout: "dashboard",
        path: "/baja",
        name: "Baja",
        icon: <DeleteRowOutlined/>,
        sidebar: "single",
        rol: ["ADMINISTRADOR", "RAC", "RGA"],
        routes: [
          {
            path: "/",
            component: ArchivoBaja,
          },
          {
            path: "/detalle",
            component: ExpedienteBajaDetalle,
          },
          {
            path: "/editar",
            component: ExpedienteBajaDetalle,
          }
        ]
      },
      {
        path: "/historico",
        name: "Histórico",
        sidebar: "single",
        icon: <HddOutlined/>,
        //ver: 107,
        rol: ["ADMINISTRADOR", "RAC", "RGA"],
        routes: [
          {
            path: "/",
            sidebar: "single",
            component: ArchivoHistorico,
          },
          {
            path: "/detalle",
            component: ExpedienteHistorico,
          },
          {
            path: "/editar",
            component: ExpedienteHistorico,
          }
        ]
      },
    ]
  },
  {
    layout: "dashboard",
    path: "/gestion-general",
    name: "Administración",
    icon: <SettingOutlined/>,
    sidebar: "collapse",
    ver: 54,
    rol: ["ADMINISTRADOR"],
    routes: [
      {
        layout: "dashboard",
        path: "/acto-omision",
        name: "Actos Omisión",
        icon: <AlertOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: ActoOmision,
          },
          {
            path: "/nuevo",
            component: ActoOmisionDetalle,
          },
          {
            path: "/editar",
            component: ActoOmisionDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/acceso-arcop",
        name: "Acceso o ARCOP",
        icon: <LockOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: AccesoArco,
          },
          {
            path: "/nuevo",
            component: AccesoArcoDetalle,
          },
          {
            path: "/editar",
            component: AccesoArcoDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/areas",
        name: "Áreas",
        icon: <PartitionOutlined/>,
        sidebar: "single",
        ver: 27,
        routes: [
          {
            path: "/",
            component: Areas,
          },
          {
            path: "/nuevo",
            component: AreaForm,
          },
          {
            path: "/editar",
            component: AreaForm,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/cargos",
        name: "Cargos",
        icon: <ContactsOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: Cargos,
          },
          {
            path: "/nuevo",
            component: CargoForm,
          },
          {
            path: "/editar",
            component: CargoForm,
          },
        ]
      },
      {
        path: "/dependencias",
        name: "Dependencias",
        icon: <BankOutlined/>,
        sidebar: "single",
        ver: 23,
        routes: [
          {
            path: "/",
            component: DependenciaListado,
          },
          {
            path: "/nuevo",
            component: DependenciaDetalle,
          },
          {
            path: "/editar",
            component: DependenciaDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/documento-tipo",
        name: "Documento Tipos",
        icon: <FileDoneOutlined/>,
        sidebar: "single",
        ver: 30,
        routes: [
          {
            path: "/",
            component: DocumentoTipoListado,
          },
          {
            path: "/nuevo",
            component: DocumentoTipoDetalle,
          },
          {
            path: "/editar",
            component: DocumentoTipoDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/estatus",
        name: "Estatus",
        icon: <FileSearchOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: Estatus,
          },
          {
            path: "/nuevo",
            component: EstatusDetalle,
          },
          {
            path: "/editar",
            component: EstatusDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/estatus-recurso",
        name: "Estatus Recurso",
        icon: <FileSearchOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: EstatusRecurso,
          },
          {
            path: "/nuevo",
            component: EstatusRecursoDetalle,
          },
          {
            path: "/editar",
            component: EstatusRecursoDetalle,
          },
        ]
      },
      {
        path: "/firmantes",
        name: "Firmantes Archivos",
        icon: <EditOutlined/>,
        sidebar: "single",
        ver: 85,
        routes: [
          {
            path: "/",
            component: Firmantes,
          },
          {
            path: "/nuevo",
            component: FirmantesForm,
          },
          {
            path: "/editar",
            component: FirmantesForm,
          },
        ]
      },
      {
        path: "/fondos",
        name: "Fondos",
        icon: <DollarOutlined/>,
        sidebar: "single",
        ver: 25,
        routes: [
          {
            path: "/",
            component: FondoListado,
          },
          {
            path: "/nuevo",
            component: FondoDetalle,
          },
          {
            path: "/editar",
            component: FondoDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/motivo-inconformidad",
        name: "Motivo Inconformidad",
        icon: <QuestionCircleOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: MotivoInconformidad,
          },
          {
            path: "/nuevo",
            component: MotivoInconformidadDetalle,
          },
          {
            path: "/editar",
            component: MotivoInconformidadDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/permisos",
        name: "Permisos",
        icon: <ScheduleOutlined/>,
        sidebar: "single",
        ver: 35,
        routes: [
          {
            path: "/",
            component: Permisos
          },
          {
            path: "/nuevo",
            component: PermisosForm
          },
          {
            path: "/editar",
            component: PermisosForm
          },
          {
            path: "/modulo-nuevo",
            component: PermisosModuloForm
          },
          {
            path: "/modulo-editar",
            component: PermisosModuloForm
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/ponencia",
        name: "Ponencia",
        icon: <NotificationOutlined/>,
        sidebar: "single",
        ver: 35,
        routes: [
          {
            path: "/",
            component: Ponencia
          },
          {
            path: "/nuevo",
            component: PonenciaDetalle
          },
          {
            path: "/editar",
            component: PonenciaDetalle
          }
        ]
      },
      {
        layout: "dashboard",
        path: "/pr",
        name: "PR",
        icon: <NotificationOutlined/>,
        sidebar: "single",
        ver: 35,
        routes: [
          {
            path: "/",
            component: Pr
          },
          {
            path: "/nuevo",
            component: PrDetalle
          },
          {
            path: "/editar",
            component: PrDetalle
          }
        ]
      },
      {
        path: "/secciones",
        name: "Secciones",
        icon: <BookOutlined/>,
        sidebar: "single",
        ver: 28,
        routes: [
          {
            path: "/",
            component: SeccionListado,
          },
          {
            path: "/nuevo",
            component: SeccionDetalle,
          },
          {
            path: "/editar",
            component: SeccionDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/sentido",
        name: "Sentido",
        icon: <DragOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: Sentido,
          },
          {
            path: "/nuevo",
            component: SentidoDetalle,
          },
          {
            path: "/editar",
            component: SentidoDetalle,
          },
        ]
      },
      {
        path: "/series",
        name: "Series",
        icon: <ProfileOutlined/>,
        sidebar: "single",
        ver: 29,
        routes: [
          {
            path: "/",
            component: SerieListado,
          },
          {
            path: "/nuevo",
            component: SerieDetalle,
          },
          {
            path: "/editar",
            component: SerieDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/sub-fondo-areas",
        name: "Sub Fondo Áreas",
        icon: <PartitionOutlined/>,
        ver: 17,
        sidebar: "single",
        routes: [
          {
            path: "/",
            component: SubFondo,
          },
          {
            path: "/nuevo",
            component: SubFondoForm,
          },
          {
            path: "/editar",
            component: SubFondoForm,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/ubicacion",
        name: "Ubicaciones",
        icon: <PartitionOutlined/>,
        sidebar: "single",
        ver: 31,
        routes: [
          {
            path: "/",
            component: UbicacionListado,
          },
          {
            path: "/nuevo",
            component: UbicacionDetalle,
          },
          {
            path: "/editar",
            component: UbicacionDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/dias-festivos",
        name: "Días Festivos",
        sidebar: "single",
        icon: <GiftOutlined/>,
        ver: 54,
        routes: [
          {
            path: "/",
            component: DiaFestivoListado,
          },
          {
            path: "/nuevo",
            component: DiaFestivoDetalle,
          },
          {
            path: "/editar",
            component: DiaFestivoDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/usuarios",
        name: "Usuarios",
        icon: <TeamOutlined/>,
        sidebar: "single",
        ver: 33,
        routes: [
          {
            path: "/",
            component: Usuarios,
          },
          {
            path: "/nuevo",
            component: UsuarioForm,
          },
          {
            path: "/editar",
            component: UsuarioForm,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/via",
        name: "Via",
        icon: <RiseOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: Via,
          },
          {
            path: "/nuevo",
            component: ViaDetalle,
          },
          {
            path: "/editar",
            component: ViaDetalle,
          },
        ]
      },
      {
        layout: "dashboard",
        path: "/votacion",
        name: "Votación",
        icon: <AuditOutlined/>,
        sidebar: "single",
        ver: 32,
        routes: [
          {
            path: "/",
            component: Votacion,
          },
          {
            path: "/nuevo",
            component: VotacionDetalle,
          },
          {
            path: "/editar",
            component: VotacionDetalle,
          },
        ]
      },
    ],
  },
  {
    path: "/perfil",
    component: Perfil
  },
  ...sharedRoutes,
];

const publicRoutes = [...sharedRoutes];

export {dashboardRoutes, publicRoutes};
