import React, { useEffect, useState }  from 'react'

import { 
  Col, 
  Row, 
  Button, 
  Form, 
  Input,
  message,
  Select,
  Modal,
  Switch
} from 'antd';
import { 
  ArrowLeftOutlined, 
  FileAddOutlined,
  SaveOutlined,
  BookOutlined,
} from '@ant-design/icons';
import{ useHistory } from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import {useQuery, useModels, useModel, useAuth} from "../../../hooks";
import StatusResponse from '../../../services/statusResponse';
import numeros from '../../../constants/Numeros';

const SeccionDetalle = () => {

  const titulo = 'Sección';
  const [form] = Form.useForm();
  const history = useHistory();
  const {user} = useAuth();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false)
  const [estatus, setEstatus] = useState(false)

  const { Option } = Select;

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'seccion',
    id: id,
  });

  const [ 
    fondo,
    fondoLoading,
  ] = useModels({
    name: 'fondo',
    ordenar: 'idFondo-desc',
    limit: 10,
  });

  const [
    subFondoAreas,
    , , ,
    subFondoAreasRecargar
  ] = useModels({
    name: user?.permisoExtra?.includes(40) ? 'sub-fondo-area' : 'sub-fondo-area/mias',
    ordenar: 'titulo',
    limite: -1,
  });

  const buscarAreas = async (value) => {
    if (value?.length > 0) {
      await subFondoAreasRecargar(true, {
        buscar: value
      })
    }
  };

  const breadcrumbItems = [
    {
      name: "Secciones",
      to: '/gestion-general/secciones/',
      icon: <BookOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ?
        '/gestion-general/secciones/editar?id='+id :
        '/gestion-general/secciones/nuevo',
      icon: <FileAddOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/secciones/'),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: 'primary', }
  };

  useEffect(()=>{
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
      })
      setEstatus(model.estatus === 1 ? true : false)
    };
    return () => mounted = false;
  }, [form, modelLoading, model])

  const onFinish = async (values) => {
    const {
      titulo,
      codigo,
      descripcion,
      idFondo,
      orden
    } = values;
    let body = {
      titulo,
      codigo,
      descripcion,
      idFondo,
      orden: orden.value
    }
    if(editing) {
      body.idSeccion = id;
    }

    body.estatus = (estatus === true ? 1 : 0);

    try {
      setLoading(true)
      const res = await StatusResponse.post('seccion/guardar', body);
      if(res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if( res?.status === 400 && res?.errores !== null ) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            /*title: res?.mensaje,
            content: (
              <div>{ arrayError.map((m,i) =>
              <span key={(i+1)}> -{m} <br />
              </span> )
              }</div>
            )*/
            title: 'Error al guardar: datos incompletos.',
          });
          console.log(arrayError)
          //cuando el dato ya existe no se puede guardar en BD
        } else if ( res?.status === 400 && res?.errores === null ) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
          //todo salió bien
        } else if ( res?.status === 200 ) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: '20vh' }
          });
          history.push('/gestion-general/secciones');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ',e);
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = ({ values, errorFields, outOfDate  }) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log( values, errorFields, outOfDate );
  };


  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xxl={{ span: 6, offset: 0 }}
          >
            <Form.Item
              label="Código"
              name="codigo"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                size="large"
                placeholder="Escribir código"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 9, offset: 0 }}
            lg={{ span: 9, offset: 0 }}
            xxl={{ span: 9, offset: 0 }}
          >
            <Form.Item
              label="Título de la Sección"
              name="titulo"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                size="large"
                placeholder="Escribir Título"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 9, offset: 0 }}
            lg={{ span: 9, offset: 0 }}
            xxl={{ span: 9, offset: 0 }}
          >
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                size="large"
                placeholder="Escribir Descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
          >
            <Form.Item
              label="Fondo"
              name="idFondo"
              hasFeedback
            >
              <Select
                size="large"
                loading={fondoLoading}
                disabled={fondoLoading}
                placeholder="Seleccionar fondo"
                style={ { width: '100%' } }
              >
                {fondo.map((item, index)=>(
                  <Option key={index} value={item.idFondo}>{item.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
            xxl={{ span: 10, offset: 0 }}
          >
            <Form.Item
              label="Área"
              name="idSubFondo"
            >
              <Select
                labelInValue
                size="large"
                optionFilterProp="children"
                showSearch={true}
                allowClear={true}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                listItemHeight={10}
                listHeight={250}
                placeholder="Seleccionar un Área"
                onSearch={buscarAreas}
                style={{width: '100%'}}
              >
                {subFondoAreas.map((item, index) => (
                  <Option key={index} value={item.idSubFondoArea}>{item.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col 
            className='gutter-row'
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
          >
            <Form.Item
              label="Estatus"
              hasFeedback
            >
              <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" checked={estatus} onChange={(value)=>{ setEstatus(value)}}/>
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
          >
            <Form.Item name='orden' label='Órden'>
              <Select
                labelInValue
                size="large"
                optionFilterProp="children"
                showSearch={true}
                allowClear={true}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                listItemHeight={10}
                listHeight={250}
                placeholder="Seleccionar un Órden"
                style={{width: '100%'}}>
                  {numeros.map(item => (
                    <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                  ))}
                </Select>
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={{xs:10,sm:10,md:10,lg:10}}>
          <Col
            xs={{span:24,offset:0}}
            sm={{span:24,offset:0}}
            md={{span:5,offset:19}}
            lg={{span:5,offset:19}}
            xxl={{span:5,offset:19}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >Guardar</Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </DefaultLayout>  
  )
}

export default SeccionDetalle