import React, {useState, useMemo, useCallback, useEffect} from "react";
import {
  Table,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Tooltip,
  Button,
  Select,
  DatePicker,
  Spin,
  Tag,
  Collapse,
  notification
} from 'antd';
import {useHistory, Link} from 'react-router-dom';
import ActionsButton from '../../components/actionsbutton/ActionsButton';
import {DefaultLayout} from '../../components/layouts';
import {
  DeleteOutlined, EditOutlined, ContactsOutlined,
  PlusOutlined, CloseCircleOutlined, FilePdfOutlined,
  SearchOutlined, ClearOutlined, FileExcelOutlined, SubnodeOutlined, BorderlessTableOutlined
} from "@ant-design/icons";
import {useApp, useAuth, useModels} from '../../hooks';
import StatusResponse from '../../services/statusResponse';
import moment from "moment";
import {reporteExpediente, estatusExpediente, inventarioConcentracion, cambiarEstatusExpediente} from "../../utilities";
import {RenderEstatus} from "./RenderEstatus";

const {REACT_APP_API_URL: baseUrl} = process.env;
const {RangePicker} = DatePicker;
const {Option} = Select;
const {Panel} = Collapse;

const ExpedienteTramite = () => {

  const titulo = "Expedientes en trámite";
  let url = '/archivo/tramite';
  const [form] = Form.useForm();
  const {token} = useApp();
  const {user} = useAuth();
  const history = useHistory();

  const _baseUrl = baseUrl.replace("v1/", "");
  const [limite, setLimite] = useState(10);
  const [selectSeries, setSelectSeries] = useState([]);
  const [importLoading, setImportLoading] = useState(false);
  const [actuales, setActuales] = useState(0);
  const [totalArchivos, setTotalArchivos] = useState(0);
  const [paramsBuscar, setParamsBuscar] = useState({estatus: 'ATR'});

  const extraParamsMemo = useMemo(() => paramsBuscar, [paramsBuscar]);

  const [
    models,
    modelsLoading, ,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'expediente',
    expand: 'serie, subfondoarea, archivos',
    limite: limite,
    extraParams: extraParamsMemo,
    ordenar: "idExpediente-desc"
  });

  const [subFondoAreas, subFondoAreasLoading] = useModels({
    name: user?.permisoExtra?.includes(40) ? 'sub-fondo-area' : 'sub-fondo-area/mias',
    ordenar: 'codigo-asc',
    limite: -1,
  });

  const [secciones, seccionesLoading] = useModels({
    name: 'seccion',
    expand: 'series',
    ordenar: 'codigo-asc',
    limite: -1,
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: url,
      icon: <ContactsOutlined/>,
    },
  ];

  const columnasExcel = [
    {
      title: 'Número Consecutivo',
      key: 'numeroCaja',
      dataIndex: 'numeroCaja'
    },
    {
      title: 'Sección/Serie',
      key: 'serieValor',
      dataIndex: 'serieValor'
    },
    {
      title: 'Número de Expediente por Serie',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Título',
      key: 'titulo',
      dataIndex: 'titulo'
    },
    {
      title: 'Legajos',
      key: 'legajos',
      dataIndex: 'legajos'
    },
    {
      title: 'Fojas',
      key: 'numeroHojas',
      dataIndex: 'numeroHojas'
    },
    {
      title: 'Título descripcion',
      key: 'asunto',
      dataIndex: 'asunto'
    },
    {
      title: 'Apertura',
      key: 'fechaApertura',
      dataIndex: 'fechaApertura'
    },
    {
      title: 'Cierre',
      key: 'fechaCierre',
      dataIndex: 'fechaCierre'
    },
    {
      title: 'Valoración Primaria',
      key: 'valoracionPrimaria',
      dataIndex: 'valoracionPrimaria'
    },
    {
      title: 'Valoración Secundaria',
      key: 'valoracionSecundaria',
      dataIndex: 'valoracionSecundaria'
    },
    {
      title: 'AT',
      key: 'vigenciaAt',
      dataIndex: 'vigenciaAt'
    },
    {
      title: 'AC',
      key: 'vigenciaAC',
      dataIndex: 'vigenciaAC'
    },
    {
      title: 'Destino Final',
      key: 'destinoFinal',
      dataIndex: 'destinoFinal'
    },
    {
      title: 'stringClasificacionInformacion',
      key: 'stringClasificacionInformacion',
      dataIndex: 'stringClasificacionInformacion'
    },
    {
      title: 'Archivos',
      key: 'archivo',
      dataIndex: 'archivo'
    },
    {
      title: 'Ubicación del Expediente',
      key: 'ubicacionValor',
      dataIndex: 'ubicacionValor'
    },
    {
      title: 'soporte',
      key: 'stringTipoExpediente',
      dataIndex: 'stringTipoExpediente'
    },
  ];

  const infoToExport = async () => {
    let obj = {
      expand: 'serie,subfondoarea,seccion,ubicacion,archivos,legajos',
      estatus: 'ATR',
      inicio: paramsBuscar.inicio ? paramsBuscar.inicio : '',
      fin: paramsBuscar.fin ? paramsBuscar.fin : '',
      anio: paramsBuscar.anio ? paramsBuscar.anio : '',
      idSerie: form.getFieldValue("serie"),
      idSeccion: form.getFieldValue("seccion"),
    };
    let flag = true;
    let pagina = 1, total = 0, limite = 1000000;
    let registros = [];
    setImportLoading(true);
    while (flag) {
      obj["pagina"] = pagina;
      obj["limite"] = limite;
      const params = new URLSearchParams(obj).toString();
      const res = await StatusResponse.get(`expediente?${params}`);
      const resultado = res?.resultado;

      registros = [
        ...registros,
        ...resultado
      ];
      let _paginacion = res?.paginacion;
      total = _paginacion.total;
      setTotalArchivos(_paginacion.total);

      const actual = pagina * limite;
      setActuales(actual);
      pagina = parseInt(_paginacion?.pagina) + 1;

      if (total < actual) {
        flag = false;
        setImportLoading(false);
      }
    }

    registros.sort((a, b) => a.folio > b.folio ? 1 : -1)

    let array = [];
    for (let i = 0, l = registros.length; i < l; i++) {
      let _folio = registros[i]?.folio?.split("/")
      registros[i].numeroCaja = i + 1
      registros[i].folio = _folio[3];
      registros[i].serieValor = `${registros[i].serie?.codigo}`;
      registros[i].fechaApertura = moment(registros[i].fechaApertura).format('DD/MM/YYYY');
      registros[i].fechaCierre = registros[i].fechaCierre ? moment(registros[i].fechaCierre).format('DD/MM/YYYY') : "sin fecha definida";

      registros[i].stringClasificacionInformacion = registros[i]?.clasificacionInformacion?.split(',').map((clasificacionInformacion) => {
        if (clasificacionInformacion === 'R') {
          return 'Reservada';
        } else if (clasificacionInformacion === 'C') {
          return 'Confidencial';
        } else if (clasificacionInformacion === 'P') {
          return 'Pública';
        }
      }).toString().replace(",", ", ");

      registros[i].stringTipoExpediente = registros[i]?.tipoExpediente?.split(',').map((item) => {
        if (item === 'PO') {
          return 'Papel/Original';
        } else if (item === 'DE') {
          return 'Digital/Electrónico';
        } else if (item === 'AU') {
          return 'Audiovisual';
        }
      }).toString().replace(",", ", ");

      registros[i].valoracionPrimaria = registros[i]?.valoracionPrimaria?.split(',').map((item) => {
        if (item === 'A') {
          return 'Administrativo, ';
        } else if (item === 'L') {
          return 'Legal';
        } else if (item === 'CF') {
          return 'Fiscal o Contable';
        }
      }).toString().replace(",", ", ");

      registros[i].valoracionSecundaria = registros[i]?.valoracionSecundaria?.split(',').map((item) => {
        if (item === 'INFO') {
          return 'Informativo';
        } else if (item === 'ET') {
          return 'Evidencial / Testimonial';
        }
      }).toString().replace(",", ", ");

      if (registros[i]?.serie?.destinoFinal === "D")
        registros[i].destinoFinal = "B";
      else if (registros[i]?.serie?.destinoFinal === "CC")
        registros[i].destinoFinal = "H";
      else if (registros[i]?.serie?.destinoFinal === "CF")
        registros[i].destinoFinal = "M";
      else
        registros[i].destinoFinal = "-";

      registros[i].archivo = registros[i]?.archivos?.length > 0 ? `${registros[i]?.archivos?.length}` : "0"
      registros[i].ubicacionValor = registros[i]?.ubicacion?.titulo;

      for (let j = 0, l = registros[i].legajosExpediente.length; j < l; j++) {
        array.push({
          ...registros[i],
          legajos: registros[i].legajosExpediente[j]?.legajos,
          numeroHojas: registros[i].legajosExpediente[j]?.numeroHojas,
        })
      }

    }

    return [
      ...array
    ]

  }

  const infoToExportRat = async () => {

    let obj = {
      expand: 'serie,subfondoarea,seccion,ubicacion,archivos',
      estatus: 'ATR',
      inicio: paramsBuscar.inicio ? paramsBuscar.inicio : '',
      fin: paramsBuscar.fin ? paramsBuscar.fin : '',
      anio: paramsBuscar.anio ? paramsBuscar.anio : '',
      idSubFondoArea: form.getFieldValue("subFondoArea"),
      idSerie: form.getFieldValue("serie"),
      idSeccion: form.getFieldValue("seccion"),
    };

    let flag = true;
    let pagina = 1, total = 0, limite = 100;
    let registros = [];
    setImportLoading(true);

    while (flag) {
      obj["pagina"] = pagina;
      obj["limite"] = limite;

      const params = new URLSearchParams(obj).toString();
      const res = await StatusResponse.get(`expediente?${params}`);
      const resultado = res?.resultado;
      registros = [
        ...registros,
        ...resultado
      ];
      let _paginacion = res?.paginacion;
      total = _paginacion.total;
      setTotalArchivos(_paginacion.total);

      const actual = pagina * limite;
      setActuales(actual);
      pagina = parseInt(_paginacion?.pagina) + 1;

      if (total < actual) {
        flag = false;
        setImportLoading(false);
      }
    }
    let array = [];
    registros.sort((a, b) => a.folio > b.folio ? 1 : -1)
    for (let i = 0, l = registros.length; i < l; i++) {
      let _folio = registros[i]?.folio?.split("/")
      registros[i].numeroCaja = i + 1
      registros[i].folio = _folio[3];
      registros[i].serieValor = `${registros[i].serie?.codigo}`;
      registros[i].fechaApertura = moment(registros[i].fechaApertura).format('DD/MM/YYYY');
      registros[i].fechaCierre = registros[i].fechaCierre ? moment(registros[i].fechaCierre).format('DD/MM/YYYY') : "sin fecha definida";

      registros[i].stringClasificacionInformacion = registros[i]?.clasificacionInformacion?.split(',').map((clasificacionInformacion) => {
        if (clasificacionInformacion === 'R') {
          return 'Reservada';
        } else if (clasificacionInformacion === 'C') {
          return 'Confidencial';
        } else if (clasificacionInformacion === 'P') {
          return 'Pública';
        }
      }).toString().replace(",", ", ");

      registros[i].stringTipoExpediente = registros[i]?.tipoExpediente?.split(',').map((item) => {
        if (item === 'PO') {
          return 'Papel/Original';
        } else if (item === 'DE') {
          return 'Digital/Electrónico';
        } else if (item === 'AU') {
          return 'Audiovisual';
        }
      }).toString().replace(",", ", ");

      registros[i].valoracionPrimaria = registros[i]?.valoracionPrimaria?.split(',').map((item) => {
        if (item === 'A') {
          return 'Administrativo, ';
        } else if (item === 'L') {
          return 'Legal';
        } else if (item === 'CF') {
          return 'Fiscal o Contable';
        }
      }).toString().replace(",", ", ");

      registros[i].valoracionSecundaria = registros[i]?.valoracionSecundaria?.split(',').map((item) => {
        if (item === 'INFO') {
          return 'Informativo';
        } else if (item === 'ET') {
          return 'Evidencial / Testimonial';
        }
      }).toString().replace(",", ", ");

      if (registros[i]?.serie?.destinoFinal === "D")
        registros[i].destinoFinal = "B";
      else if (registros[i]?.serie?.destinoFinal === "CC")
        registros[i].destinoFinal = "H";
      else if (registros[i]?.serie?.destinoFinal === "CF")
        registros[i].destinoFinal = "M";
      else
        registros[i].destinoFinal = "-";

      registros[i].archivo = registros[i]?.archivos?.length > 0 ? `${registros[i]?.archivos?.length}` : "0"
      registros[i].ubicacionValor = registros[i]?.ubicacion?.titulo;

      for (let j = 0, l = registros[i].legajosExpediente.length; j < l; j++) {
        array.push({
          ...registros[i],
          legajos: registros[i].legajosExpediente[j]?.legajos,
          numeroHojas: registros[i].legajosExpediente[j]?.numeroHojas,
        })
      }

    }

    return [
      ...array
    ]

  }

  const descargarExcel = async () => {
    await inventarioConcentracion(
      columnasExcel,  // columnas
      await infoToExport(),
      "Inventario_General_Archivo_Trámite_" + moment().format('DD-MM-YYYY'),  // nombre del archivo
      "Área Coordinadora de Archivos", // titulo
      "Inventario General de Archivo de Trámite", // subtitulo
      "/logo.png", // img
    )
  }

  const descargarExcelRat = async () => {
    if (form.getFieldValue("subFondoArea") === undefined) {
      notification.error({
        message: 'Atención',
        description: 'Debes de seleccionar un área.',
        placement: 'bottomRight'
      });
      return;
    }

    await inventarioConcentracion(
      columnasExcel,  // columnas
      await infoToExportRat(),
      "Inventario_Archivo_Trámite_" + moment().format('DD-MM-YYYY'),  // nombre del archivo
      "Área Coordinadora de Archivos", // titulo
      "Inventario General de Archivo de Trámite", // subtitulo
      "/logo.png", // img
    )
  }

  const Opciones = (item) => {
    let _opciones = [
      {
        name: 'Editar',
        icon: <EditOutlined/>,
        onClick: () => history.push(`${url}/editar?id=${item.idExpediente}`)
      },
      user?.permisoExtra?.includes(55) && {
        name: 'Pasar a Concentración',
        icon: <BorderlessTableOutlined/>,
        onClick: () => cambiarEstatusExpediente(item, 'ACO', refreshModels)
      },
      {
        name: <Tooltip title={<p>Descargar e imprimir la caratula del Expediente</p>}>Imprimir</Tooltip>,
        icon: <FilePdfOutlined/>,
        onClick: () => window.open(`${_baseUrl}/pdf/reportes/caratula?id=${item.idExpediente}&access-token=${token}`, '_blank')
      },
    ];

    if (user?.permisoExtra?.includes(82)) {
      _opciones.push({
        name: 'Eliminar',
        icon: <DeleteOutlined/>,
        onClick: () => modalEliminar(item),
        styleProps: {color: '#ff4d4f'}
      })
    }

    return _opciones
  }

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.idExpediente}
          options={Opciones(item)}
        />
      )
    },
    {
      title: 'Estatus',
      dataIndex: 'estatus',
      key: 'estatus',
      align: 'center',
      width: 90,
      render: (_, item) => <RenderEstatus key={item.idExpediente} item={item} url={url} token={token}/>
    },
    {
      title: 'Fecha Apertura',
      dataIndex: 'fechaApertura',
      key: 'fechaApertura',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.fechaApertura && moment(item?.fechaApertura).format('DD/MM/YYYY')}
        </Link>
      )
    },
    {
      title: 'Fecha Cierre',
      dataIndex: 'fechaCierre',
      key: 'fechaCierre',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.fechaCierre && moment(item?.fechaCierre).format('DD/MM/YYYY')}
        </Link>
      )
    },
    {
      title: 'Serie',
      dataIndex: 'idSerie',
      key: 'idSerie',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.serie?.codigo}
        </Link>
      )
    },
    {
      title: 'Número de expediente',
      dataIndex: 'folio',
      key: 'folio',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.folio}
        </Link>
      )
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.titulo}
        </Link>
      )
    },
    {
      title: 'Área',
      dataIndex: 'SubFondoArea',
      key: 'SubFondoArea',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          <>{item?.subfondoarea?.codigo} / {item?.subfondoarea?.titulo}</>
        </Link>
      )
    },
    {
      title: 'Asunto',
      dataIndex: 'asunto',
      key: 'asunto',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idExpediente}`}>
          {item?.asunto}
        </Link>
      )
    },
  ];

  const onSetPageCallback = useCallback(async (page, size) => {
    setLimite(size);
    await refreshModels(true, {
      ordenar: 'titulo',
      pagina: page,
      limite: size,
    })
  }, [refreshModels]);

  const descargarPdf = (tipo, formato = 'normal') => {
    let _area = "";

    let _subFondo = form.getFieldValue("subFondoArea");
    let _idSerie = form.getFieldValue("serie");
    let _idSeccion = form.getFieldValue("seccion");

    if (tipo === "AREA") {
      if (form.getFieldValue("subFondoArea") === undefined) {
        notification.error({
          message: 'Atención',
          description: 'Debes de seleccionar un área.',
          placement: 'bottomRight'
        });
        return;
      }
      _area = "&idSubFondoArea=" + parseInt(_subFondo);
    } else {
      _area = _subFondo ? "&idSubFondoArea=" + parseInt(_subFondo) : '';
    }
    let _serie = _idSerie ? "&idSerie=" + parseInt(_idSerie) : "";
    let _seccion = _idSeccion ? "&idSeccion=" + parseInt(_idSeccion) : "";

    if (formato === 'guia')
      window.open(`${_baseUrl}pdf/expediente/guia-tramite?access-token=${token}${_area}`, '_blank')
    else
      window.open(`${_baseUrl}pdf/reportes/pdf-expedientes-tramite?hoja=legal&access-token=${token}${_area}${_serie}${_seccion}`, '_blank')
  }

  //configuracion de la paginación
  const configPagination = useMemo(() => {
    if (modelsPage) {
      let size = limite;

      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} Expedientes.`,
        locale: {items_per_page: '/ página'},
        pageSizeOptions: [10, 15, 20, 30, 50, 75, 100,  modelsPage.total < 100 && modelsPage.total ].filter(val => val <= modelsPage.total),
        showSizeChanger: true
      }
    }
    return null;
  }, [onSetPageCallback, limite, modelsPage]);

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el Expediente "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("expediente/eliminar", {idExpediente: valor.idExpediente});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idExpediente > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'idExpediente.asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el Expediente",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const onFinishBuscar = async (values) => {
    let params = {...paramsBuscar};

    const {buscar, seccion, serie, subFondoArea, rango, anio, estatus} = form.getFieldsValue();

    if (buscar) {
      params.buscar = buscar;
    }

    if (rango?.length > 0) {
      params.inicio = rango[0].format('YYYY-MM-DD') + ' 00:00:00';
      params.fin = rango[1].format('YYYY-MM-DD') + ' 23:59:59';
    }

    if (serie) {
      params.idSerie = serie;
    }

    if (seccion) {
      params.idSeccion = seccion;
    }

    if (subFondoArea) {
      params.idSubFondoArea = subFondoArea;
    }

    if (anio) {
      params.anio = anio.format('YYYY');
    }

    if (estatus) {
      params.estatus = estatus;
    }
    setParamsBuscar(params)
  };

  const SearchForm = () => {
    return (
      <Form form={form} layout="vertical">
        <Collapse
          expandIconPosition={'end'}
          defaultActiveKey={['1']}
          bordered={false}
          ghost
        >
          <Panel key="1">
            {/* Buscar | Área | Sección  */}
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="" name="buscar">
                  <Input placeholder="Buscar"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}
              >
                <Form.Item label="" name="subFondoArea">
                  <Select
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    disabled={subFondoAreasLoading}
                    loading={subFondoAreasLoading}
                    style={{width: "100%"}}
                    placeholder="Área"
                  >
                    {subFondoAreas?.length > 0 &&
                    subFondoAreas?.map((i, index) => (
                      <Option
                        key={`sub-fondo-area-${index}`}
                        value={i?.idSubFondoArea}
                      >
                        {i?.codigo} - {i?.titulo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="" name="seccion">
                  <Select
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    disabled={seccionesLoading}
                    loading={seccionesLoading}
                    style={{width: "100%"}}
                    placeholder="Sección"
                    onChange={(val) => {
                      let _secciones = secciones?.find(
                        (seccion) => seccion?.idSeccion === val
                      );
                      setSelectSeries(_secciones?.series);
                      form.setFieldsValue({
                        serie: null,
                      });
                    }}
                  >
                    {secciones?.length > 0 &&
                    secciones?.map((item, index) => (
                      <Option key={`seccion-${index}`} value={item?.idSeccion}>
                        {item?.codigo} - {item?.titulo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* Serie | Fecha apertura | Año | Trámite */}
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="" name="serie">
                  <Select
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    style={{width: "100%"}}
                    placeholder="Serie"
                  >
                    {selectSeries?.map((item, index) => (
                      <Option key={`serie-${index}`} value={item?.idSerie}>
                        {item?.idSeriePadre && <SubnodeOutlined/>} {item?.codigo} -{" "}
                        {item?.titulo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="" name="rango">
                  <RangePicker
                    disabled={modelsLoading}
                    format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                    placeholder={["FECHA APERTURA INICIO", "FECHA APERTURA LÍMITE"]}
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Form.Item label="" name="anio">
                  <DatePicker
                    placeholder={"Año"}
                    picker="year"
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Form.Item label="" name="estatus">
                  <Select
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    style={{width: "100%"}}
                    placeholder="Estatus expediente"
                    options={estatusExpediente}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        {/* Botones: Agregar | Buscar | Excel | Limpiar */}
        <Row gutter={10}>
          <Col xs={6} sm={6} md={3} lg={2} xl={1}>
            <Form.Item>
              <Tooltip title="Buscar">
                <Button
                  block
                  icon={<SearchOutlined/>}
                  type="default"
                  onClick={onFinishBuscar}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col xs={6} sm={6} md={3} lg={2} xl={1}>
            <Form.Item>
              <Tooltip title="Limpiar campos">
                <Button
                  block
                  icon={<ClearOutlined/>}
                  type="dashed"
                  onClick={() => {
                    form.resetFields();
                    form.setFieldsValue({
                      estatus: 'ATR'
                    })
                    setParamsBuscar({estatus: 'ATR'});
                  }}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <Form.Item>
              <Tooltip title="Generar un nuevo expediente">
                <Button
                  block
                  icon={<PlusOutlined/>}
                  type="primary"
                  onClick={() => history.push(`${url}/nuevo`)}
                >
                  Nuevo Expediente
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}
               hidden={!(user?.rol === "RAC" || user?.rol === "RGA" || user?.rol === "ADMINISTRADOR")}
          >
            <Form.Item>
              <Tooltip title="Descargar el inventario general de archivo de trámite en excel">
                <Button
                  block
                  icon={<FileExcelOutlined/>}
                  type="default"
                  style={{background: "#1f6e43", color: "#ffffff", border: 'none'}}
                  disabled={modelsLoading}
                  onClick={() => descargarExcel()}
                >
                  Excel General
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item>
              <Tooltip title="Descargar el inventario general de archivo de trámite en excel">
                <Button
                  block
                  icon={<FileExcelOutlined/>}
                  type="default"
                  style={{background: "#1f6e43", color: "#ffffff", border: 'none'}}
                  disabled={modelsLoading}
                  onClick={() => descargarExcelRat()}
                >
                  Excel de Expedientes
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>
          {/*<Col xs={4} sm={4} md={4} lg={4} xl={4}
                hidden={!(user?.rol === "RAC" || user?.rol === "RGA" || user?.rol === "ADMINISTRADOR")}
          >
            <Form.Item>
              <Tooltip title="Descargar el inventario general de archivo de trámite en PDF">
                <Button
                  block
                  icon={<FilePdfOutlined/>}
                  type="default"
                  style={{background: "#ce0000", color: "#ffffff", border: 'none'}}
                  disabled={modelsLoading}
                  onClick={() => descargarPdf("GENERAL")}
                >
                  PDF General
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>*/}
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item>
              <Tooltip title="Descargar el inventario general de archivo de trámite en PDF">
                <Button
                  block
                  icon={<FilePdfOutlined/>}
                  type="default"
                  style={{background: "#ce0000", color: "#ffffff", border: 'none'}}
                  disabled={modelsLoading}
                  onClick={() => descargarPdf("AREA")}
                >
                  Inventario General
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      estatus: 'ATR'
    })
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({...models});
  }, [form, models])


  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      emptyText="No se encuentra ningún expediente."
      children={
        <>
          <SearchForm/>
          <Modal
            open={importLoading}
            footer={null}
            closable={false}
          >
            <Row justify="center">
              <Col>
                <Spin spinning={importLoading} tip={<>Obteniendo
                  expedientes... {actuales > totalArchivos ? totalArchivos : actuales} de {totalArchivos}</>}
                      size="large"/>
              </Col>
            </Row>
          </Modal>
          <Row gutter={10}>
            <Col span={24}>
              <Table
                dataSource={models}
                rowKey={'idExpediente'}
                loading={modelsLoading}
                pagination={configPagination}
                columns={columns}
                size="small"
                scroll={{x: 1200}}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
}

export default ExpedienteTramite;