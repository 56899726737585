import React, {useState, useMemo, useRef} from 'react'
import {Button, Col, Form, Input, Modal, Row, Tooltip,} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  BookOutlined,
  PlusOutlined,
  ClearOutlined,
  SearchOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import StatusResponse from "../../../services/statusResponse";
import {Link, useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {Tabla} from "../../../components";
import {respuestas} from "../../../utilities";

const SeccionListado = () => {

  const titulo = "Secciones";
  const url = '/gestion-general/secciones';
  const history = useHistory();
  const {form} = Form.useForm();

  let tablaRef = useRef(null);

  const [buscar, setBuscar] = useState('');
  const extraParamsMemo = useMemo(() => {
    let params = {}

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    return params

  }, [buscar]);

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/secciones/',
      icon: <BookOutlined/>,
    },
  ];

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar la Sección "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("seccion/eliminar", {idSeccion: valor.idSeccion});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idSeccion > 0) {
              if (respuestas(res)) {
                tablaRef?.current?.refresh();
                resolve();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idSeccion}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              styleProps: {color: '#ff4d4f'},
              onClick: () => modalEliminar(item)
            }
          ]}
        />
      )
    },
    {
      title: 'Fondo',
      dataIndex: 'idFondo',
      key: 'idFondo',
      render: (_, item) => {
        return <Link to={`${url}/editar?id=${item.idFondo}`}>
          {`${item?.fondo?.titulo}`}
        </Link>
      },
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.codigo}`}
        </Link>
      ),
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.titulo}`}
        </Link>
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idSeccion}`}>
          {`${item?.descripcion}`}
        </Link>
      ),
    },
  ];

  const SearchForm = () => {
    const onFinishButton = async values => {
      let params = {}
      const {buscar} = values;

      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
    }

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishButton}
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item
              label=""
              name="buscar"
            >
              <Input
                placeholder="BUSCAR..."
                defaultValue={buscar}
                value={buscar}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Tooltip
              tittle="Buscar"
            >
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                htmlType="submit"
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip
              tittle="Limpiar"
            >
              <Button
                icon={<ClearOutlined/>}
                type="default"
                style={{minWidth: "50px", minHeight: "40px"}}
                onClick={() => {
                  setBuscar('');
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      withSearchButton={false}
      emptyText='Aún no hay áreas registrados.'
      children={<>
        <Row>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 20, offset: 0}}
            lg={{span: 20, offset: 0}}
            xxl={{span: 20, offset: 0}}
          >
            <SearchForm/>
          </Col>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            xxl={{span: 4, offset: 0}}
          >
            <Button
              onClick={() => history.push(url + '/nuevo')}
              icon={<PlusOutlined/>}
              style={{width: "100%", minWidth: "150px", minHeight: "40px"}}
              type="primary"
            >
              Agregar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabla
              columns={columns}
              innerRef={tablaRef}
              nameURL={'seccion'}
              rowKey={'idSeccion'}
              expand="fondo"
              extraParams={extraParamsMemo}
              order="orden"
              scroll={{x: '30vw'}}
            />
          </Col>
        </Row>
      </>
      }
    />
  )
}

export default SeccionListado