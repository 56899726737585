import React from 'react'

import {
  ArrowLeftOutlined,
  ProfileOutlined,
  FileAddOutlined, SettingOutlined, DatabaseOutlined,
} from '@ant-design/icons';

import {useHistory} from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import {useQuery} from "../../../hooks";
import UbicacionForm from "./UbicacionForm";

const UbicacionDetalle = () => {

  const titulo = 'Ubicación';
  const history = useHistory();
  const url = "/gestion-general/archivo/ubicacion";

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;

  const breadcrumbItems = [
    {
      name: 'Administración',
      icon: <SettingOutlined/>
    },
    {
      name: 'Archivo',
      icon: <DatabaseOutlined/>
    },
    {
      name: "Ubicacion",
      to: url,
      icon: <ProfileOutlined/>,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? url + '/editar?id=' + id
        : url + '/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push(url),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };


  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <UbicacionForm id={id}/>
    </DefaultLayout>
  )
}

export default UbicacionDetalle