import React, {useState, useMemo, useCallback, useRef} from 'react'

import {
  Modal,
  Table,
} from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  ProfileOutlined,
  PlusOutlined, CloseCircleOutlined
} from '@ant-design/icons';

import {Link, useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {useModels} from "../../../hooks";
import StatusResponse from '../../../services/statusResponse';
import { modalEliminar } from '../../../utilities';
import Tabla from '../../../components/Tabla';

const DocumentoTipoListado = () => {
  const endPoint = 'documento-tipo';
  let tablaRef = useRef(null);

  const [buscarParams, setBuscarParams] = useState({});

  const titulo = "Documento Tipos";
  const url = '/gestion-general/documento-tipo';
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'documento-tipo',
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/documento-tipo/',
      icon: <ProfileOutlined/>,
    },
  ];

  const buttonData = {
    text: "Agregar Tipo",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const defaultText = prop => prop || <span style={{color: '#c7c3c3'}}>---</span>;

  const columns = [

    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idDocumentoTipo}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar("idDocumentoTipo", item.idDocumentoTipo, item.nombre, endPoint, tablaRef),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: '#',
      dataIndex: 'idDocumentoTipo',
      key: 'idDocumentoTipo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idDocumentoTipo}`}>
          {`${item?.idDocumentoTipo}`}
        </Link>
      ),
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idDocumentoTipo}`}>
          {`${item?.codigo}`}
        </Link>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idDocumentoTipo}`}>
          {`${item?.nombre}`}
        </Link>
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idDocumentoTipo}`}>
          {`${item?.descripcion}`}
        </Link>
      ),
    },
  ];


  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = {...params, buscar: search };
    params = {...params, pagina: 0};
    await refreshModels(true, params);
    if(!modelsLoading) setSearching(false);
    setBuscarParams(params);
  };

  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setLimite(size);
      await refreshModels ( true,  {
        ordenar: 'idDocumentoTipo-desc',
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );

  const configPagination = useMemo( () => {
    if( modelsPage ) {
    let size = limite;
    
    return {        
      total: modelsPage.total,
      pageSize: modelsPage.limite,
      onShowSizeChange: ( _, newSize)  => ( size = newSize ),
      onChange: async (v) => await onSetPageCallback(v, size),
      showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} Documentos Tipos.`,
      locale: { items_per_page: '/ página' },
      pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
      showSizeChanger: true
    }}
    return null;
  }, [ onSetPageCallback, limite, modelsPage ] );


  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay áreas registrados.'
      children={
        <>
          <Tabla
            columns={columns}
            innerRef={tablaRef}
            nameURL={endPoint}
            expand=""
            order="idDocumentoTipo-desc"
            scroll={{ x: '30vw' }}
            extraParams={buscarParams}
          />
        </>
      }
    />
  )
}

export default DocumentoTipoListado