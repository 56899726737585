import React, {useState, useMemo, useCallback} from "react";
import {Table, Modal, Row, Col, Button, Popover, Form, Input, Tooltip} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  FileTextOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  ClearOutlined, 
} from "@ant-design/icons";
import {useModels} from '../../../hooks';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {Link, useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import {SimpleTableLayout} from '../../../components/layouts'

const Permisos = () => {

  const titulo = 'Permisos';
  const url = '/gestion-general/permisos';
  const history = useHistory();
  //# const [searching, setSearching] = useState(false);
  const [limite, setLimite] = useState(10);
  const {form} = Form.useForm();
  const [buscar, setBuscar] = useState('');

  const extraParamsMemo = useMemo(() => {
    let params = {}

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    return params

  }, [buscar]);


  const defaultText = prop => prop || <span style={{color: '#c7c3c3'}}>---</span>;

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'permiso',
    ordenar: 'idPermisoModulo',
    expand: 'permisoModulo',
    extraParams: extraParamsMemo,
    limite: limite
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: url,
      icon: <FileTextOutlined/>
    }
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={"Acción"}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idPermiso}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: '# permiso',
      dataIndex: 'idPermiso',
      key: 'idPermiso',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idPermiso}`}>
          {`${item?.idPermiso}`}
        </Link>
      ),
    },
    {
      title: 'Modulo',
      dataIndex: 'idModulo',
      key: 'idModulo',
      render: (_, item) => (<Popover
        content={<> {item?.permisoModulo?.descripcion}
          <Button
            onClick={() => history.push(url + '/modulo-editar?id=' + item.idPermisoModulo)}
            icon={<PlusOutlined/>}
            style={{width: "100%", minWidth:"80px", minHeight: "40px"}}
          >
            Editar Modulo
          </Button></>}> {item?.permisoModulo?.nombre} </Popover>),
    },
    {
      title: 'Nombre',
      dataIndex: 'permiso',
      key: 'permiso',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item.idPermiso}`}>
          {`${item?.permiso}`}
        </Link>
      ),
    },
  ];

  const onSetPageCallback = useCallback(
    async (page, size) => {
      setLimite(size);
      await refreshModels(true, {
        ordenar: 'idPermiso-desc',
        pagina: page,
        limite: size,
      })
    }, [refreshModels]
  );

  const configPagination = useMemo(() => {
    if (modelsPage) {
      let size = limite;

      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} permisos.`,
        locale: {items_per_page: '/ página'},
        pageSizeOptions: [10, 20, 30].filter(val => val <= modelsPage.total),
        showSizeChanger: true
      }
    }
    return null;
  }, [onSetPageCallback, limite, modelsPage]);

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el permiso "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("permiso/eliminar", {idPermiso: valor.idPermiso});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idPermiso > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'idPermiso-asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el permiso",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const SearchForm = () => {

    const onFinishButton = async values => {
      let params = {}
      const {buscar} = values;

      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
    }

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishButton}
      >
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item
              label=""
              name="buscar"
            >
              <Input
                size="large"
                disabled={modelsLoading}
                placeholder="BUSCAR..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Tooltip
              tittle="Buscar"
            >
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                htmlType="submit"
                loading={modelsLoading}
                style={{ minWidth: "50px", minHeight: "40px"}}
                disabled={modelsLoading}
              />
            </Tooltip>
            <Tooltip
              tittle="Limpiar"
            >
              <Button
                icon={<ClearOutlined/>}
                type="default"
                disabled={modelsLoading}
                style={{ minWidth: "50px", minHeight: "40px"}}
                onClick={() => {
                  setBuscar('');
                  refreshModels()
                }}
              />
            </Tooltip>
          </Col>

        </Row>
      </Form>
    );
  }


  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      emptyText='Aún no hay permisos registrados.'
      withSearchButton={false}
      children={
        <Row>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 16, offset: 0}}
            lg={{span: 16, offset: 0}}
            xxl={{span: 16, offset: 0}}
          >
            <SearchForm/>
          </Col>

          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            xxl={{span: 4, offset: 0}}
          >
              <Button
                onClick={() => history.push(url + '/modulo-nuevo')}
                icon={<PlusOutlined/>}
                style={{width: "100%", minWidth:"150px", minHeight: "40px"}}
                type="primary"
              >
                Agregar Módulo
              </Button>
          </Col>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 4, offset: 0}}
            lg={{span: 4, offset: 0}}
            xxl={{span: 4, offset: 0}}
          >
              <Button
                onClick={() => history.push(url + '/nuevo')}
                icon={<PlusOutlined/>}
                style={{width: "100%", minWidth:"150px", minHeight: "40px"}}
                type="primary"
              >
                Agregar Permiso
              </Button>
          </Col>
          <Col span={24}>
            <Table
              dataSource={models}
              rowKey="idPermiso"
              loading={modelsLoading}
              pagination={configPagination}
              columns={columns}
              size='small'
            />
          </Col>
        </Row>
      }
    />
  )
}

export default Permisos