import React from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const ActionsButton = ({ options = [] }) => {

  const [ menuItems, setMenuItems ] = React.useState([]);

  React.useEffect(() => {
    if( options?.length > 0 ) {
      const items = [];
      for( let i = 0; i < options.length; i ++ ) {
        items.push({
          ...[i]?.props,
          key: i +  options[i]?.name,
          icon: options[i].icon,
          label: options[i]?.name,
          onClick: options[i]?.onClick,
          style: options[i]?.styleProps,
          disabled: options[i]?.disabled,
        });
      }
      setMenuItems(items);
    }
  }, [options]);

  return (
    <Dropdown
      overlay={<Menu items={menuItems} />}
      placement="right"
      arrow
    >
      <Button>
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

export default ActionsButton;