import React, {useEffect, useState, useCallback} from 'react';
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Typography,
  Divider,
  Radio,
  Upload,
  Modal,
  Popover,
  Avatar,
  List,
  Comment,
  Tooltip,
  Timeline,
  Switch, InputNumber, DatePicker, Collapse,
} from 'antd';
import {
  SaveOutlined,
  FileTextOutlined,
  ArrowLeftOutlined,
  FilePdfTwoTone,
  FolderOpenOutlined,
  MailOutlined,
  FileDoneOutlined,
  SolutionOutlined,
  RightOutlined,
  InboxOutlined,
  UserOutlined,
  DeleteOutlined,
  CopyOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import StatusResponse from '../../services/statusResponse';
import {useQuery, useModels, useModel, useAuth, useApp} from "../../hooks";
import {ViewLoading} from "../../components";
import moment from "moment";
import EditorTexto from "../../components/editortexto/EditorTexto";

const {Panel} = Collapse;

const {REACT_APP_API_URL: baseUrl} = process.env;

const DocumentoForm = () => {
  const localStorageKey = "usr_jwt";
  const {Option} = Select;
  const {TextArea} = Input;
  const {Title} = Typography;
  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const _baseUrl = baseUrl.replace("/v1/", "");
  const titulo = id ? 'Editar Documento' : "Nuevo documento";
  const [form] = Form.useForm();
  const history = useHistory();
  const {user} = useAuth();
  const {confirm} = Modal;

  const [loading, setLoading] = useState(false)

  const [confidencial, setConfidencial] = useState(false);
  //# const [archivo, setArchivo] = useState(null);
  //# const [archivoCargando, setArchivoCargando] = useState(false);
  const {token} = useApp();

  const [usrEnvios, setUsrEnvios] = useState([]);
  const [usrCopias, setUsrCopias] = useState([]);
  const [usrEmisor, setUsrEmisor] = useState([]);

  const [usuarioRecepcion, setUsuarioRecepcion] = useState([]);
  const [usuarioRecepcionCopia, setUsuarioRecepcionCopia] = useState([]);
  const [usuariosSelect, setUsuariosSelect] = useState([]);
  //# const [usuariosSelectCopia, setUsuariosSelectCopia] = useState([]);
  const [usuarioEmisor, setUsuarioEmisor] = useState([]);
  const [usuariosEmisores, setUsuariosEmisores] = useState([]);
  //# const [estatusVal, setEstatusVal] = useState('');
  const [descripcionTexto, setDescripcionTexto] = useState('');

  const [areasEmisoras, setAreasEmisoras] = useState([]);
  const [esEmisor, setEsEmisor] = useState(false);
  const [esReceptor, setEsReceptor] = useState(false);
  const [esCopia, setEsCopia] = useState(false);
  const [esBorrador, setEsBorrador] = useState(true);
  const [codigo, setCodigo] = useState("");
  const [pin, setPin] = useState('');
  const [motivoCancelado, setMotivoCancelado] = useState('');
  const [listaArchivos, setListaArchivos] = useState([]);
  //# const [eslogan, setEslogan] = useState("56");
  const [comentario, setComentario] = useState("");
  const [comentarios, setComentarios] = useState([]);
  const [comentarioList, setComentarioList] = useState([]);
  const [bitacora, setBitacora] = useState([]);
  const [externo, setExterno] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState(true);
  //# const [areasReceptoras, setAreasReceptoras] = useState([]);
  const [copiaArchivo, setCopiaArchivo] = useState(true);

  //# const idUsuario = useMemo(() => ({idUsuario: user?.idUsuario}), [user]);
  const botonesOpciones = [
    {
      name:
        <Popover placement="top" content={<div>
          <TextArea
            placeholder=""
            value={motivoCancelado}
            onChange={e => setMotivoCancelado(e.target.value)}
            disabled={editing && !esEmisor}
            autoSize={{minRows: 2, maxRows: 20}}
          />

          <Button
            type="primary"
            block size='large'
            name="estatus"
            value="CANCELADO"
            onClick={() => onFinish(form.getFieldsValue(true), 'CANCELADO')}
            icon={<SaveOutlined/>}
          >
            Cancelar
          </Button>
        </div>} title="¿Porque quiere cancelar el documento?">
          Cancelar Documento
        </Popover>,
      icon: <DeleteOutlined/>,
      hidden: (!editing || esReceptor),
    },

    {
      name: 'Trámite',
      icon: <SolutionOutlined/>,
      onClick: () => guardarEstatus('TRAMITE'),
      hidden: !editing || !esReceptor || esCopia
    }, {
      name: 'Atendido',
      icon: <FileDoneOutlined/>,
      onClick: () => guardarEstatus('ATENDIDO'),
      hidden: !editing || !esReceptor || esCopia
    }, {
      name: 'Guardar Borrador',
      icon: <SaveOutlined/>,
      onClick: () => onFinish(form.getFieldsValue(true), 'NUEVO'),
      hidden: (esReceptor || esCopia || !esBorrador)
    }, {
      name: <Popover placement="top" content={<div>
        {user?.pin !== null ? <Input
            type="password"
            value={pin}
            onChange={e => setPin(e.target.value)}
          /> :
          <span onClick={() => history.push("/")}
                style={{margin: "0 8px"}}>Introducir un PIN para firma.</span>}
        <Button
          type="primary"
          block size='large'
          name="estatus"
          value="NUEVO"
          onClick={() => onFinish(form.getFieldsValue(true), 'ENVIADO', 1)}
          icon={<FilePdfTwoTone/>}
          style={{margin: '3px 0'}}
        >
          Firmar
        </Button>
      </div>} title="Digite PIN">
        Guardar, Enviar y Firmar
      </Popover>,
      icon: <SaveOutlined/>,
      hidden: (esReceptor || esCopia || !esBorrador),
    }, {
      name: 'Guardar y Enviar',
      icon: <CopyOutlined/>,
      onClick: () => onFinish(form.getFieldsValue(true), 'ENVIADO'),
      hidden: (esReceptor || esCopia || !esBorrador),
    }, {
      name: 'Clonar',
      icon: <CopyOutlined/>,
      onClick: () => onFinish(form.getFieldsValue(true), 'CLONAR'),
      hidden: (!editing),
    },
  ]

  const [
    misAreas
  ] = useModels({
    name: 'area/mis-areas',
    expand: 'usuarios, usuarioTitular'
  });

  const {
    model,
    modelLoading
  } = useModel({
    name: 'documento',
    id: id,
    expand: 'documentoResponsable, emisores, receptores, documentoMedia, bitacora',
  });

  const [
    usuarios,
    usuariosLoading
  ] = useModels({
    name: 'usuario',
    expand: 'areas'
  });

  const [
    areas,
    areasLoading
  ] = useModels({
    name: 'area',
    ordenar: 'nombre',
    expand: 'usuarios, usuarioTitular'
  });

  const [
    documentoTipos,
    documentoTiposLoading
  ] = useModels({
    name: 'documento-tipo',
    ordenar: 'nombre-asc'
  });


  const breadcrumbItems = [
    {
      name: "Documentos",
      to: '/documento',
      icon: <FolderOpenOutlined/>,
    },
    {
      name: id ? "Editar " + model?.folio : "Nuevo",
      to: id ? '/editar-documento?id=' + id : '/nuevo-documento',
      icon: <FileTextOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/documento'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary', size: 'large'}
  };

  const getCurrentToken = () => {
    return new Promise((resolve, reject) => {
      const jwt = localStorage.getItem(localStorageKey);
      if (!jwt) reject("No hay sesión.");
      resolve(jwt);
    });
  };


  const onRemoveFile = async (file) => {
    let body = {...file};

    confirm({
      title: `¿Estás seguro de eliminar el Archivo ${file.name}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: 'Si, Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          setLoading(true)
          const res = await StatusResponse.post('documento/eliminar-archivo', body);
          if (res) {
            //Si estatus 400 y "errores" es diferente a nulo
            if (res?.status === 400 && res?.errores !== null) {
              const arrayError = Object.values(res?.errores);
              console.log(arrayError);
              Modal.error({
                title: 'Error al guardar: datos incompletos.',
              });
              //cuando el dato ya existe no se puede guardar en BD
            } else if (res?.status === 400 && res?.errores === null) {
              message.error({
                content: res?.mensaje,
                style: {marginTop: '20vh'},
              });
              //todo salió bien
            } else if (res?.status === 200) {
              message.success({
                content: res?.mensaje,
                style: {marginTop: '20vh'},
              });
            }
          }
        } catch (e) {
          console.log('Error al guardar: ', e);
        } finally {
          setLoading(false)
        }
      },
      onCancel() {
      },
    });
  }

  /**
   * Revisa los comentarios de el documento y el usuario que esta logueado
   */
   const checkComment = useCallback(async () => {

    let body = {
      idUsuario: user?.idUsuario,
      idDocumento: id,
    }

    try {
      setLoading(true)
      const res = await StatusResponse.post('documento/comentarios', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: 'Error al guardar: datos incompletos.',
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          if (res?.response?.resultado[0]?.idComentario !== null || res?.response?.resultado[0]?.idComentario !== undefined) {
            setComentarios(res?.response?.resultado);
          }
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }, [id, user?.idUsuario])

  /**
   * al abrir el documento guarda un estatus de RECIBIDO
   */
   const onOpen = useCallback(async () => {
    let body = {};
    body.estatus = "RECIBIDO";

    body.idDocumento = model?.idDocumento;
    body.idUsuario = user?.idUsuario;

    try {
      setLoading(true)
      const res = await StatusResponse.post('documento/guardar-estatus', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: 'Error al guardar: datos incompletos.',
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          checkComment();
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }, [checkComment, model?.idDocumento, user?.idUsuario]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      onOpen();
    }
  }, [model, modelLoading, onOpen])

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      let _documentoCopia = model?.documentoResponsable.filter(item => item?.tipo === 2);
      let _documentoReceptor = model?.documentoResponsable.filter(item => item?.tipo === 1);
      let _documentoExterno = model?.documentoResponsable.filter(item => item?.estatus === 'EXTERNO');
      let _documentoEmisor = model?.emisores;
      let idAreasReceptoras = JSON.parse(model.idAreaReceptora);

      let areasSeleccionadas = idAreasReceptoras?.map((item) => {
        return areas.filter(area => item === area?.idArea)[0]?.idArea + "/" + areas.filter(area => item === area?.idArea)[0]?.nombre
      });

      form.setFieldsValue({
        ...model,
        confidencial: model?.confidencial !== 0,
        envios: _documentoReceptor?.map((item) => item?.tipo === 1 ? item?.idUsuarioReceptor + "/" + item.nombreResponsable : undefined).filter(function (item) {
          return item !== undefined
        }),
        copias: _documentoCopia?.map((item) => item?.tipo === 2 ? item?.idUsuarioReceptor + "/" + item.nombreResponsable : undefined).filter(function (item) {
          return item !== undefined
        }),
        idAreaReceptora: areasSeleccionadas,
      })

      let _listaArchivos = model?.documentoMedia?.map(item => {
        return {
          name: item?.descripcion,
          uid: item?.uuid,
          url: `${_baseUrl}/v1/descarga/documento?uuid=${item?.uuid}&access-token=${token}`,
          status: 'done',
          thumbUrl: `${item?.ruta}`,
        }
      });

      let bolReceptor = false;
      let bolCopia = false;
      let _usrEnvios = [];
      let _usrCopias = [];

      _documentoReceptor.forEach(itemEnv => {
        if (itemEnv.idUsuarioReceptor === user?.idUsuario) {
          bolReceptor = true;
        }
        _usrEnvios.push(usuarios.filter(item => item.idUsuario === itemEnv.idUsuarioReceptor)[0])
      })
      setExterno(_documentoExterno[0]);

      _documentoCopia.forEach(itemCop => {
        if (itemCop.idUsuarioReceptor === user?.idUsuario) {
          bolCopia = true;
        }
        _usrCopias.push(usuarios.filter(item => item.idUsuario === itemCop.idUsuarioReceptor)[0])
      })

      setCodigo(model?.uuid + "/" + model?.idUsuarioFirma + "/" + areas.filter(item => item.idArea === model?.idAreaEmisora)[0]?.codigo + "/" + (model?.fecha ? model?.fecha : "0000-00-00 00:00:00").split(" ")[0].replaceAll("-", "/") + "/" + model?.folio);
      setListaArchivos(_listaArchivos);
      setUsrEmisor(_documentoEmisor);
      setUsuariosEmisores(_documentoEmisor)
      setEsEmisor(_documentoEmisor[0]?.idUsuario === user?.idUsuario || model?.idUsuarioFirma === user?.idUsuario);
      setEsReceptor(bolReceptor);
      setEsCopia(bolCopia);
      setUsrEnvios(_usrEnvios);
      setUsrCopias(_usrCopias);
      setCopiaArchivo(model?.copiaArchivo);
      //# setEslogan(model?.eslogan);
      setBitacora(model?.bitacora);
      setEsBorrador(model?.estatus === 'NUEVO');

      setAreasEmisoras(usuarios.filter(item => item.idUsuario === user.idUsuario)[0]?.areas);
      //setUsuariosEmisores(usuarios.filter(item => item.idUsuario === model.idUsuarioFirma))
    }
    return () => mounted = false;
  }, [form, modelLoading, model, usuarios, areas, user, _baseUrl, token]);

  console.log(usuariosEmisores)

  useEffect(() => {
    if (editing && model) {
      setTipoDocumento(model?.estatus !== "EXTERNO");
      setDescripcionTexto(model?.descripcion);
    }
  }, [editing, model]);

  /**
   * guarda los usuarios en arreglos separados ya sea receptores o copias
   */
  useEffect(() => {
    setAreasEmisoras(usuarios.filter(item => item.idUsuario === user.idUsuario)[0]?.areas);
    setUsuariosSelect(usuarios);
    //# setUsuariosSelectCopia(usuarios);
  }, [user, usuarios]);

  /**
   * Guarda los comentarios
   */
  useEffect(() => {
    if (comentarios?.length > 0 && comentarios[0]?.idComentario !== undefined && comentarios[0]?.idComentario !== null) {
      let _comentarios = comentarios?.map(item => {
        let usuario = usuarios?.find(usr => usr?.idUsuario === item?.idUsuario);
        return {
          actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
          author: usuario?.nombre + " " + usuario?.apellidoPaterno + " " + usuario?.apellidoMaterno,
          avatar: usuario?.urlFoto,
          content: (
            <p>
              {item.comentario} {item.tipo === 0 ? <span style={{color: '#1890ff', fontSize: "8px"}}>Público</span> :
              <span style={{color: '#ff0000', fontSize: "8px"}}>Privado</span>}
            </p>
          ),
          datetime: (
            <Tooltip title={moment(item?.creado).parseZone().local().format('DD/MM/YYYY HH:mm:ss')}>
              <span>{moment(item?.creado).parseZone().local().format('DD/MM/YYYY HH:mm:ss')} </span>
            </Tooltip>
          ),
        }
      });
      setComentarioList(_comentarios);
    }
  }, [comentarios, usuarios]);

  /**
   * guarda en la lista de archivos para subir al servidor
   */
  const onChange = ({fileList: newFileList}) => {
    setListaArchivos(newFileList);
  };

  /**
   * Funcion para subri un archivo
   */
  const subirArchivo = async (file, idDocumento) => {
    try {
      //# setArchivoCargando(true);

      let _archivo = file.originFileObj;

      if (!_archivo) {
        return
      }

      const form = new FormData();
      form.append('archivo', _archivo);
      form.append('idDocumento', idDocumento);

      const token = await getCurrentToken();

      const response = await fetch(_baseUrl + '/v1/documento-media', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });
      console.log("Respuesta al subir archivo", response);
    } catch (error) {
      console.log('error al cargar archivo: ', error);
    } finally {
      //# setArchivoCargando(false);
    }
  };

  useEffect(() => {
    setUsuariosEmisores(misAreas[0]?.usuarios);

    if (!model?.idAreaEmisora) {
      form.setFieldsValue({
        idAreaEmisora: misAreas?.length > 0 ? misAreas[0]?.idArea : null,
        idUsuarioFirma: user?.idUsuario,
      });
    }
  }, [user, misAreas, model, form]);

  /**
   * guarda el documento con sus campos
   */
  const onFinish = async (values, estats, firma = 0) => {

    const {
      idTipo,
    } = values;

    let body = {...values}

    const firmante = usuarios.filter(item => item.idUsuario === values.idUsuarioFirma)[0]
    let _areaReceptoras = [];
    let _envios = [];
    let _copias = [];


    if (tipoDocumento === true) {
      values.idAreaReceptora?.forEach(idArea => idArea !== "todas" ? _areaReceptoras.push(parseInt(idArea?.split("/")[0])) : "");
      values.envios?.forEach(idUsuario => _envios.push(idUsuario?.split("/")[0]));
      values.copias?.forEach(idUsuario => _copias.push(idUsuario?.split("/")[0]));

      if (_areaReceptoras.length === 0) {
        areas.forEach(area => _areaReceptoras.push(area?.idArea))
      }
    }


    if (idTipo === undefined) {
      message.error({
        content: "introduzca un tipo",
        style: {marginTop: '20vh'},
      });
      console.log(idTipo);
      return;
    }

    console.log("sinPin")

    if (pin === "" && firma === 1) {
      message.error({
        content: "PIN vacio",
        style: {marginTop: '20vh'},
      });
      return;
    }

    if (firma === 1) {
      if (parseInt(pin) !== parseInt(user?.pin)) {
        message.error({
          content: "Pin incorrecto, el documento no se firmara ",
          style: {marginTop: '20vh'},
        });
        body.firmado = null
        return;
      } else {
        body.firmado = 1;
      }
    }


    body.nombreFirmante = firmante?.nombre + " " + firmante?.apellidoPaterno + " " + firmante?.apellidoMaterno;
    body.idCreador = user?.idUsuario;
    body.confidencial = values.confidencial === true ? 1 : 0;
    body.estatus = estats;
    body.motivoCancelado = motivoCancelado;
    body.idUsuarioResponsable = user?.idUsuario;
    body.copiaArchivo = copiaArchivo ? 1 : 0;
    body.areasReceptoras = _areaReceptoras;
    body.envios = _envios;
    body.copias = _copias;
    body.nombreResponsable = values.nombreResponsable;
    body.descripcion = descripcionTexto;

    if (editing) {
      body.idDocumento = id;
    }

    if (values?.idAreaReceptora.includes("todas")) {
      Modal.confirm({
        title: "Se seleccionaron todas las areas receptoras",
        icon: <CloseCircleOutlined style={{color: "red"}}/>,
        content: `¿Va a enviar el documento a todas las Áreas?`,
        okText: "Sí",
        cancelText: "No",
        closable: true,
        onOk: async () => {

          try {
            setLoading(true)
            const res = await StatusResponse.post('documento/guardar', body);
            if (res) {
              //Si estatus 400 y "errores" es diferente a nulo
              if (res?.status === 400 && res?.errores !== null) {
                const arrayError = Object.values(res?.errores);
                console.log(arrayError);
                Modal.error({
                  title: res?.mensaje,
                  content: (
                    <div>{arrayError.map((m, i) =>
                      <span key={(i + 1)}> -{m} <br/>
                      </span>)
                    }</div>
                  )
                });
                //cuando el dato ya existe no se puede guardar en BD
              } else if (res?.status === 400 && res?.errores === null) {
                message.error({
                  content: res?.mensaje,
                  style: {marginTop: '20vh'},
                });
                //todo salió bien
              } else if (res?.status === 200) {


                message.success({
                  content: res?.mensaje,
                  style: {marginTop: '20vh'}
                });


                let data = await subirArchivos(res?.response?.detalle?.idDocumento);

                if (data) {
                  //# setArchivoCargando(false);
                  message.success({
                    content: data?.mensaje,
                    style: {marginTop: '20vh'},
                  });
                }

                history.push('documento');
              }
            }
          } catch (e) {
            console.log('Error al guardar: ', e);
          } finally {
            setLoading(false)
          }
        },
      });
    } else {

      try {
        setLoading(true)
        const res = await StatusResponse.post('documento/guardar', body);
        if (res) {
          //Si estatus 400 y "errores" es diferente a nulo
          if (res?.status === 400 && res?.errores !== null) {
            const arrayError = Object.values(res?.errores);
            console.log(arrayError);
            Modal.error({
              title: res?.mensaje,
              content: (
                <div>{arrayError.map((m, i) =>
                  <span key={(i + 1)}> -{m} <br/>
                  </span>)
                }</div>
              )
            });
            //cuando el dato ya existe no se puede guardar en BD
          } else if (res?.status === 400 && res?.errores === null) {
            message.error({
              content: res?.mensaje,
              style: {marginTop: '20vh'},
            });
            //todo salió bien
          } else if (res?.status === 200) {

            let tamano = listaArchivos.length;

            message.success({
              content: res?.mensaje,
              style: {marginTop: '20vh'}
            });

            if (tamano > 0) {
              for (let i = 0; i < tamano; i++) {
                subirArchivo(listaArchivos[i], res?.detalle?.idDocumento);
                if (tamano === (i + 1)) {
                  history.push('/documento');
                }
              }
            } else {
              history.push('/documento');
            }
          }
        }
      } catch (e) {
        console.log('Error al guardar: ', e);
      } finally {
        setLoading(false)
      }
    }
  }

  const subirArchivos = async (res) => {
    let tamano = listaArchivos.length;

    console.log(res)

    let file;
    for (let i = 0; i < tamano; i++) {
      file = await subirArchivo(listaArchivos[i], res);
    }
    return file;
  }

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  }

  const guardarEstatus = async (estatus) => {
    let body = {};
    body.estatus = estatus;
    body.idUsuario = user?.idUsuario;
    body.idDocumento = model?.idDocumento;

    try {
      setLoading(true)
      const res = await StatusResponse.post('documento/guardar-estatus', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{arrayError.map((m, i) =>
                <span key={(i + 1)}> -{m} <br/>
                </span>)
              }</div>
            )
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {

          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'}
          });

          history.push('/documento');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  const deleteTagsHTML = (str) => {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str?.toString();

    return str?.replace(/(<([^>]+)>)/ig, ' ');
  }

  /**
   * guarda en arreglo de usuarios de area en emisores
   */
  const definirAreaEmisor = (idArea) => {
    let _usuariosArea = areas.filter(item => item.idArea === idArea)[0].usuarios;
    setUsuariosEmisores(_usuariosArea)
  }

  /**
   * guarda en arreglo de usuarios ya utilizados para que no aparescan en otros campos
   */
  const quitarUsuarioRecepcion = (idUsuario) => {
    const arr = [];

    idUsuario.forEach(itemArr => {
      let arrAux = itemArr.split("/");
      if (!usuarioRecepcionCopia.includes(arrAux[0])) {
        arr.push(arrAux[0]);
      }
    })
    arr.push(usuarioEmisor);
    setUsuarioRecepcionCopia(arr);
  }


  const quitarUsuarioCopia = (idUsuario) => {
    const arr = [];
    idUsuario.forEach(itemArr => {
      let arrAux = itemArr.split("/");
      if (!usuarioRecepcion.includes(arrAux[0])) {
        arr.push(arrAux[0]);
      }
    })
    arr.push(usuarioEmisor);
    setUsuarioRecepcion(arr);
  }

  const areaReceptora = (value) => {
    value = value.filter((item) => item.id !== "todas");

    const arr = [];
    const _usuarios = [];
    const _usuariosCopia = [];
    value.forEach(itemArr => {
      let arrAux = itemArr.split("/");
      if (!usuarioRecepcion.includes(arrAux[0])) {
        arr.push(arrAux[0]);
      }
    })

    arr.forEach(itemArr => {
      let area = areas.filter(item => item.idArea === parseInt(itemArr))[0]
      area?.usuarios.forEach(usr => _usuarios.push(usr));
      area?.usuarios.forEach(usr => _usuariosCopia.push(usr));
    })

    //# setAreasReceptoras(arr);
    setUsuariosSelect(_usuarios);
    //# setUsuariosSelectCopia(_usuariosCopia);

  }

  const definirEmisor = (idUsuario) => {
    setUsuarioEmisor(idUsuario);
    setUsuarioRecepcion((usuario) => [idUsuario]);
    setUsuarioRecepcionCopia((usuario) => [idUsuario]);
  }

  /**
   * regresa el estatus de el documento a segun el usuario que lo consulta
   */
  const regresaEstatus = () => {
    let estatus = model?.documentoResponsable.filter(item => item.idUsuarioReceptor === user.idUsuario)[0]?.estatus;

    let _estatus = "";
    if (estatus === "NUEVO") {
      _estatus = <>
        <MailOutlined style={{fontSize: "26px"}}/>
        <span style={{fontSize: "26px"}}>Nuevo</span>
      </>
    }
    if (estatus === "ATENDIDO") {
      _estatus = <>
        <FileDoneOutlined style={{fontSize: "26px"}}/>
        <span style={{fontSize: "26px"}}>Atendido</span>
      </>
    }
    if (estatus === "TRAMITE") {
      _estatus = <>
        <SolutionOutlined style={{fontSize: "26px"}}/>
        <span style={{fontSize: "26px"}}> Trámite</span>
      </>
    }
    if (estatus === "RECIBIDO") {
      _estatus = <>
        <InboxOutlined style={{fontSize: "26px"}}/>
        <span style={{fontSize: "26px"}}>Recibido</span>
      </>
    }


    return _estatus;
  }

  /**
   * guarda comentario
   */
  const comentar = async (tipo) => {

    let body = {};
    body.idDocumento = model?.idDocumento;
    body.comentario = comentario;
    body.idUsuario = user?.idUsuario;
    body.tipo = tipo;

    if (body.comentario === '') {
      message.error({
        content: "Comentario no puede estar vacio",
        style: {marginTop: '20vh'},
      });
      return;
    }

    try {
      setLoading(true)
      const res = await StatusResponse.post('documento-comentario/guardar', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: 'Error al guardar: datos incompletos.',
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });

          const arrComentarios = [...comentarioList];

          arrComentarios.push({
            actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
            author: user?.nombreCompleto,
            avatar: user?.urlFoto,
            content: (
              <p>
                {comentario} {body.tipo === 0 ? <span style={{color: '#1890ff', fontSize: "8px"}}>Público</span> :
                <span style={{color: '#ff0000', fontSize: "8px"}}>Privado</span>}
              </p>
            ),
            datetime: (
              <Tooltip title={moment(res?.response?.detalle?.creado).parseZone().local().format('YYYY-MM-DD HH:mm:ss')}>
                <span>{moment(res?.response?.detalle?.creado).parseZone().local().format('YYYY-MM-DD HH:mm:ss')}</span>
              </Tooltip>
            ),
          });
          setComentarioList(arrComentarios);
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  /**
   * Firma el documento y lo guarda en la base de datos
   */
  const imprimirDocumento = (firma) => {
    if (firma === 1) {
      if (usrEmisor?.pin === null) {
        message.warning('El usuario no tiene un PIN asignado.');
        return;
      }

      if (usrEmisor?.pin + '' !== pin) {
        message.warning('El PIN ingresado no es correcto.');
        return;
      }
      firmarDocumento(model?.idDocumento, 1);

      window.open(`${_baseUrl}/pdf/reportes/documento?id=${model.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')
    } else {
      window.open(`${_baseUrl}/pdf/reportes/documento?id=${model.idDocumento}Us-${user.idUsuario}&f=0&access-token=${token}`, '_blank')
    }
  }

  /**
   * Firma el documento y lo guarda en la base de datos
   */
  const firmarDocumento = async () => {

    let body = {};
    body.idDocumento = model?.idDocumento;
    try {
      setLoading(true)
      const res = await StatusResponse.post('documento/guardar-firma', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: 'Error al guardar: datos incompletos.',
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  const Destinatarios = () => {
    let value = <></>;
    if (!editing && tipoDocumento === true) {
      value = <>
        <Col className="gutter-row" span={24}>
          <Title level={5}>Destinatarios</Title>
        </Col>
        <Col
          className="gutter-row"
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 24}}
          lg={{span: 24}}
          xxl={{span: 24}}
        >
          <Form.Item
            label="Área receptora "
            name="idAreaReceptora"
            //rules={[{required: true, message: 'Requerido'}]}
          >
            <Select
              mode="multiple"
              size='large'
              loading={areasLoading}
              placeholder="Selecciona un área receptora"
              onChange={(value) => areaReceptora(value)}
            >
              <Option key="todas" value="todas">Todas</Option>
              {areas?.map(item =>
                <Option key={item?.idArea} value={item?.idArea + "/" + item?.nombre}>
                  {item?.codigo} - {item?.nombre}
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Destinatarios"
            name="envios"
          >
            <Select
              mode="multiple"
              allowClear
              size='large'
              loading={usuariosLoading}
              style={{width: '100%'}}
              placeholder="Selecciona"
              onChange={(e) => quitarUsuarioRecepcion(e)}
            >
              {usuariosSelect?.filter((usr) => !usuarioRecepcion.includes(usr.idUsuario)).map(item => (
                <Option
                  key={item?.idUsuario}
                  value={item?.idUsuario + "/" + item?.nombre + " " + item?.apellidoPaterno + " " + item?.apellidoMaterno}
                >
                  {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Copias"
            name="copias"
          >
            <Select
              mode="multiple"
              allowClear
              size='large'
              style={{width: '100%'}}
              placeholder="Selecciona"
              onChange={(e) => quitarUsuarioCopia(e)}
            >
              {usuarios?.filter((usr) => !usuarioRecepcionCopia.includes(usr?.idUsuario)).map(item => (
                <Option
                  key={item?.idUsuario}
                  value={item?.idUsuario + "/" + item?.nombre + " " + item?.apellidoPaterno + " " + item?.apellidoMaterno}
                >
                  {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </>
    } else if (!editing && tipoDocumento === false) {
      value = <>

        <Col className="gutter-row" span={24}>
          <Title level={5}>Destinatarios</Title>
        </Col>
        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Destinatario"
            name="nombreResponsable"
          >
            <Input
              size='large'
            />
          </Form.Item>
        </Col>
      </>
    } else if (editing === true && model?.estatus === 'NUEVO') {
      value = <>
        <Col className="gutter-row" span={24}>
          <Title level={5}>Destinatarios</Title>
        </Col>
        <Col
          className="gutter-row"
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 24}}
          lg={{span: 24}}
          xxl={{span: 24}}
        >
          <Form.Item
            label="Área receptora "
            name="idAreaReceptora"
            //rules={[{required: true, message: 'Requerido'}]}
          >
            <Select
              mode="multiple"
              size='large'
              loading={areasLoading}
              placeholder="Selecciona un área receptora"
              onChange={(value) => areaReceptora(value)}
            >
              <Option key="todas" value="todas">Todas</Option>
              {areas?.map(item =>
                <Option key={item?.idArea} value={item?.idArea + "/" + item?.nombre}>
                  {item?.codigo} - {item?.nombre}
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Destinatarios"
            name="envios"
          >
            <Select
              mode="multiple"
              allowClear
              size='large'
              loading={usuariosLoading}
              style={{width: '100%'}}
              placeholder="Selecciona"
              onChange={(e) => quitarUsuarioRecepcion(e)}
            >
              {usuariosSelect?.filter((usr) => !usuarioRecepcion.includes(usr.idUsuario)).map(item => (
                <Option
                  key={item?.idUsuario}
                  value={item?.idUsuario + "/" + item?.nombre + " " + item?.apellidoPaterno + " " + item?.apellidoMaterno}
                >
                  {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Copias"
            name="copias"
          >
            <Select
              mode="multiple"
              allowClear
              size='large'
              style={{width: '100%'}}
              placeholder="Selecciona"
              onChange={(e) => quitarUsuarioCopia(e)}
            >
              {usuarios?.filter((usr) => !usuarioRecepcionCopia.includes(usr?.idUsuario)).map(item => (
                <Option
                  key={item?.idUsuario}
                  value={item?.idUsuario + "/" + item?.nombre + " " + item?.apellidoPaterno + " " + item?.apellidoMaterno}
                >
                  {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </>
    } else if (editing === true && model?.estatus === 'EXTERNO') {
      value = <>
        <Col className="gutter-row" span={24}>
          <Title level={5}>Destinatarios</Title>
        </Col>
        <Col className="gutter-row" span={24}>
          {externo?.nombreResponsable}
        </Col>
      </>
    } else if (editing === true && model?.estatus === 'ENVIADO') {
      value = <>
        <Col
          hidden={!usrEnvios.length > 0}
          className="gutter-row"
          span={24}
        >
          <Title level={5}>Destinatarios</Title>
        </Col>
        <Col
          hidden={!usrEnvios.length > 0}
          className="gutter-row"
          span={24}
        >
          {usrEnvios?.map(item => (
            <Popover key={"popover-" + item?.idUsuario} placement="right" content={<div>
              <Avatar
                style={{cursor: "pointer"}}
                onClick={() => history.push("/perfil")}
                size={50}
                src={item?.urlFoto}
                icon={<UserOutlined/>}
              />
              <p>{item?.correo}</p>
              <p>{item?.telefono}</p>
            </div>} title={item?.nombre + " " + item?.apellidoPaterno + " " + item?.apellidoMaterno}
            >
                    <span key={"spam-" + item?.idUsuario}>
                      {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}<br/>
                    </span>
            </Popover>
          ))}
        </Col>
        <Col
          hidden={!usrCopias.length > 0}
          className="gutter-row"
          span={24}
        >
          <Title level={5}>Con Copia Para</Title>
        </Col>
        <Col
          hidden={!usrCopias.length > 0}
          className="gutter-row" span={24}
        >

          {usrCopias?.map(item => (
            <Popover key={"popover-" + item?.idUsuario} placement="right" content={<div>
              <Avatar
                style={{cursor: "pointer"}}
                onClick={() => history.push("/perfil")}
                size={50}
                src={item?.urlFoto}
                icon={<UserOutlined/>}
              />
              <p>{item?.correo}</p>
              <p>{item?.telefono}</p>
            </div>} title={item?.nombre + " " + item?.apellidoPaterno + " " + item?.apellidoMaterno}
            >
                    <span key={"spam-" + item?.idUsuario}>{item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                      <br/></span>
            </Popover>
          ))}
        </Col>
      </>
    }
    return value;
  }

  if (loading) return (<ViewLoading/>);


  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {editing ? <Row gutter={12}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 4}}
            lg={{span: 4}}
            xxl={{span: 4}}
          >
            <img src={"https://chart.googleapis.com/chart?cht=qr&chs=250x250&choe=UTF-8&chl=" + codigo}
                 style={{width: "100%", marginTop: "-28%"}} alt=""/>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 16}}
            lg={{span: 16}}
            xxl={{span: 16}}
          >
            {regresaEstatus()}<br/>
            <span>Folio:</span> {model?.folio}<br/>
            <span>Fecha:</span> {moment(model?.fecha).parseZone().local().format('DD-MM-YYYY')}
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 4}}
            lg={{span: 4}}
            xxl={{span: 4}}
            style={{textAlign: 'right'}}
          >
            <Button
              type="primary"
              block size='large'
              name="estatus"
              value="NUEVO"
              onClick={() => imprimirDocumento(0)}
              icon={<FilePdfTwoTone/>}
              style={{margin: '3px 0'}}
            >
              Imprimir
            </Button>

            <Popover
              placement="right"
              content={<div>
                {user?.pin !== null ? <Input
                  type="password"
                  value={pin}
                  onChange={e => setPin(e.target.value)}
                /> : <span onClick={() => history.push("/perfil")} style={{margin: "0 8px"}}>Introducir un PIN para firma.</span>}
                <Button
                  type="primary"
                  block size='large'
                  name="estatus"
                  value="NUEVO"
                  onClick={() => imprimirDocumento(1)}
                  icon={<FilePdfTwoTone/>}
                  style={{margin: '3px 0'}}
                >
                  Imprimir
                </Button>
              </div>} title="Digite PIN de impresión">
              <Button
                type="primary"
                hidden={(!esEmisor || model?.firmado === 1)}
                block size='large'
                name="estatus"
                value="NUEVO"
                icon={<FilePdfTwoTone/>}
                style={{margin: '3px 0'}}
              >
                Imprimir Firmado
              </Button>
            </Popover>

            <Button
              type="primary"
              hidden={(!editing || model?.firmado !== 1)}
              block size='large'
              name="estatus"
              icon={<FilePdfTwoTone/>}
              style={{margin: '3px 0'}}
              onClick={() => window.open(`${_baseUrl}/pdf/reportes/documento?id=${model.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')}
            >
              Imprimir Firmado
            </Button>

          </Col>
        </Row> : null}

        <Row
          gutter={8}
          hidden={!user?.permisoExtra.includes(48)}
        >
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Consecutivo"
              name="folioNumero"
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Fecha"
              name="fechaCreado"
            >
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Asunto | Área Emisora | Emitido por | Área receptora  */}
        <Row gutter={12}>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item
              label="Asunto"
              name="asunto"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <TextArea
                placeholder=""
                disabled={editing && !esEmisor}
                autoSize={{minRows: 2, maxRows: 20}}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
            hidden={editing && !esEmisor}
          >
            <Form.Item
              label="Área Emisora "
              name="idAreaEmisora"
              //rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                size='large'
                onChange={(e) => definirAreaEmisor(e)}
              >
                {areasEmisoras?.map(item =>
                  <Option key={item?.idArea} value={item?.idArea}>{item?.codigo}-{item?.nombre}</Option>)}
              </Select>

            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
            hidden={!editing || esEmisor || (!esReceptor && !esCopia)}
          >
            <label
              style={{marginBottom: "50px"}}
              htmlFor="form_idUsuarioFirmaCopia"
            >Área Emisora</label>

            <Input
              name="idAreaEmisoraCopia"
              size='large'
              disabled
              value={areas.filter(item => item?.idArea === model?.idAreaEmisora)[0]?.nombre}
            />
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Emitido por"
              name="idUsuarioFirma"
              //rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                size='large'
                loading={usuariosLoading}
                placeholder="Selecciona un usuario"
                disabled={editing && !esEmisor}
                onChange={(e) => definirEmisor(e)}
              >
                {user?.permisoExtra.includes(1) ? usuariosEmisores.push(user)?.map(item => (
                  <Option key={item?.idUsuario} value={item?.idUsuario}>
                    {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                  </Option>)) : usuariosEmisores?.filter(usr => usr.idUsuario === user.idUsuario).map(item => (
                  <Option key={item?.idUsuario} value={item?.idUsuario}>
                    {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                  </Option>))}

                {!esEmisor ?  <Option key={model?.emisores[0]?.idUsuario} value={model?.emisores[0]?.idUsuario}>
                  {model?.emisores[0]?.nombre} {model?.emisores[0]?.apellidoPaterno} {model?.emisores[0]?.apellidoMaterno}
                </Option> : ""}
              </Select>
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 6}}
            lg={{span: 6}}
            xxl={{span: 6}}
          >
            <Form.Item
              label="Tipo"
              name="idTipo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                size='large'
                loading={documentoTiposLoading}
                disabled={editing && !esEmisor}
                placeholder="Selecciona un tipo"
              >
                {documentoTipos?.map(item => <Option key={"tipo-" + item?.idDocumentoTipo}
                                                     value={item?.idDocumentoTipo}>{item?.nombre}</Option>)}
              </Select>
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 2}}
            lg={{span: 2}}
            xxl={{span: 2}}
          >
            <Form.Item
              label="Confidencial"
              name="confidencial"
            >
              <Radio.Group
                onChange={(e) => setConfidencial(e.target.value)}
                defaultValue={confidencial}
                disabled={editing && !esEmisor}
              >
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {/* Descripción | Tipo | Confidencial */}
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item
              label="Descripción"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <EditorTexto
                value={descripcionTexto}
                onValue={setDescripcionTexto}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider/>

        {/* Envios | Copias */}
        <Row gutter={10}>

          <Col className="gutter-row" span={24}>
            <Title level={5}>Tipo de Documento</Title>
            <Switch
              checkedChildren="Interno"
              unCheckedChildren="Externo"
              defaultChecked={tipoDocumento}
              disabled={editing ? 'disabled' : ''}
              size='large'
              onChange={(e) => setTipoDocumento(e)}
            />
          </Col>

          {Destinatarios()}
        </Row>

        <Divider/>

        {/* Anexos  */}
        <Row gutter={10}>

          <Col className="gutter-row" span={24}>
            <Title level={5}>Anexos</Title>
          </Col>

          <Col
            className="gutter-row"
            span={24}
          >

            <Upload
              //previewFile={{showPreviewIcon:false}}
              beforeUpload={() => false}
              multiple={true}
              fileList={listaArchivos}
              showUploadList={{showRemoveIcon: esEmisor}}
              listType="picture-card"
              onChange={onChange}
              onRemove={user?.permisoExtra.includes(51) ? onRemoveFile : false}
            >
              {listaArchivos.length < (listaArchivos.length + 2) && '+ Subrir archivos'}
            </Upload>

          </Col>
        </Row>
        <Collapse>
          <Panel header="Comentarios">
            {/* Comentarios */}
            <Row
              hidden={comentarioList?.length === 0 && !editing}
              gutter={10}
            >
              <Col className="gutter-row" span={24}>

                <List
                  className="comment-list"
                  header={`${comentarioList ? comentarioList?.length : 0} Comentarios`}
                  itemLayout="horizontal"
                  dataSource={comentarioList}
                  locale={{emptyText: "Sin comentarios"}}
                  renderItem={(item) => (
                    <li>
                      <Comment
                        author={item.author}
                        avatar={item.avatar}
                        content={item.content}
                        datetime={item.datetime}
                      />
                    </li>
                  )}
                />
              </Col>
              <Divider/>
              <Col className="gutter-row" span={20}>
                <TextArea
                  placeholder="Introduce aqui tu comentario"
                  size='large'
                  onChange={(e) => {
                    setComentario(e.target.value);
                  }}
                  autoSize={{minRows: 3, maxRows: 20}}
                />
              </Col>
              <Col className="gutter-row" span={4}>
                <Button
                  type="primary"
                  block size='large'
                  onClick={() => comentar(0)}

                >
                  Público
                </Button>
                <Button
                  type="primary"
                  block size='large'
                  onClick={() => comentar(1)}
                >
                  Privado
                </Button>
              </Col>
              <Divider/>
            </Row>
          </Panel>
          <Panel header="Revisiones">
            {/* Revisiones */}
            <Row
              hidden={!editing}
            >
              <Col className="gutter-row" span={24}>
                <Title level={5}>Revisiones</Title>
              </Col>

              <Col className="gutter-row" span={8}>
                {bitacora.map((item, index) => {
                  return <Timeline>
                    <Popover
                      content={
                        <Row className="gutter-row" span={12}>
                          <Col className="gutter-row" span={11}>
                            <Row className="gutter-row" span={12}>
                              <Col span={12}>Asunto</Col>
                              <Col span={12}>Descripción</Col>
                              <Col span={12}>{JSON.parse(item?.antes)?.asunto}</Col>
                              <Col
                                span={12}>
                                {deleteTagsHTML(JSON.parse(item?.antes)?.descripcion?.substring(0, 300))}
                                {(JSON.parse(item?.antes)?.descripcion?.length > 300 ? "..." : "")}</Col>
                            </Row>
                          </Col>
                          <Col className="gutter-row" span={2}>
                            <RightOutlined style={{fontSize: "30px", margin: "27%"}}/>
                          </Col>
                          <Col className="gutter-row" span={11}>
                            <Row className="gutter-row" span={12}>
                              <Col span={12}>Asunto</Col>
                              <Col span={12}>Descripción</Col>
                              <Col span={12}>{JSON.parse(item?.despues).asunto}</Col>
                              <Col
                                span={12}>
                                {deleteTagsHTML(JSON.parse(item?.despues).descripcion?.substring(0, 300))}
                                {(JSON.parse(item?.despues)?.descripcion?.length > 300 ? "..." : "")}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      }
                      title={"Cambios en realizados " + moment(item?.fecha).parseZone().local().format('DD/MM/YYYY HH:mm:ss')}
                      overlayStyle={{
                        width: "80%"
                      }}
                    >
                      <Timeline.Item>
                        {moment(item?.fecha).parseZone().local().format('DD/MM/YYYY HH:mm:ss')}
                      </Timeline.Item>
                    </Popover>
                  </Timeline>
                })}
              </Col>

            </Row>
          </Panel>
        </Collapse>

        <Divider/>


        {/* Botones */}
        <Row
          gutter={[10, 10]}>
          {botonesOpciones.map(item => <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 6}}
            lg={{span: 6}}
            xxl={{span: 6}}
            hidden={item?.hidden}
            className="gutter-row"
          >
            <Button
              type="primary"
              block size='large'
              onClick={item?.onClick}
              icon={item?.icon}
            >
              {item?.name}
            </Button>
          </Col>)}

          <Col span={6}>
            <Button
              type="dashed"
              block size='large'
              onClick={() => history.goBack()}
            >
              Salir
            </Button>
          </Col>
        </Row>

        <Divider/>

      </Form>
    </DefaultLayout>
  );
};

export default DocumentoForm;