import React, {useEffect, useState, useCallback} from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Typography,
  Divider,
  Upload,
  Modal,
  Popover,
  List,
  Comment,
  Tooltip,
  Timeline,
  DatePicker,
} from "antd";
import {
  SaveOutlined,
  FileTextOutlined,
  ArrowLeftOutlined,
  FilePdfTwoTone,
  FolderOpenOutlined,
  MailOutlined,
  FileDoneOutlined,
  SolutionOutlined,
  RightOutlined,
  InboxOutlined,
  DeleteOutlined,
  CopyOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import DefaultLayout from "../../components/layouts/DefaultLayout";
import StatusResponse from "../../services/statusResponse";
import {useQuery, useModels, useModel, useAuth, useApp} from "../../hooks";
import {ViewLoading, Select as SelectComponent} from "../../components";
import moment from "moment";
import locale from "antd/lib/date-picker/locale/es_ES";
import "moment/locale/es-mx";
import ActionsButton from "../../components/actionsbutton/ActionsButton";

const {REACT_APP_API_URL: baseUrl} = process.env;

const CorrespondenciaForm = () => {
  const {Option} = Select;
  const {TextArea} = Input;
  const {Title} = Typography;
  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const _baseUrl = baseUrl.replace("v1/", "");
  const titulo = id ? "Editar Correspondencia" : "Nueva Correspondencia";
  const [form] = Form.useForm();
  const history = useHistory();
  const {user} = useAuth();
  const localStorageKey = "usr_jwt";

  const [loading, setLoading] = useState(false);

  const {token} = useApp();

  const [usrEnvios, setUsrEnvios] = useState([]);
  const [usrEmisor, setUsrEmisor] = useState([]);

  const [usuarioRecepcion, setUsuarioRecepcion] = useState([]);
  const [usuarioRecepcionCopia, setUsuarioRecepcionCopia] = useState([]);
  const [usuariosSelect, setUsuariosSelect] = useState([]);

  const [esEmisor, setEsEmisor] = useState(true);
  const [esReceptor, setEsReceptor] = useState(false);
  const [esCopia, setEsCopia] = useState(false);

  const [codigo, setCodigo] = useState("");

  const [motivoCancelado, setMotivoCancelado] = useState("");
  const [listaArchivos, setListaArchivos] = useState([]);
  const [eslogan, setEslogan] = useState("56");
  const [comentario, setComentario] = useState("");
  const [comentarios, setComentarios] = useState([]);
  const [comentarioList, setComentarioList] = useState([]);
  const [bitacora, setBitacora] = useState([]);
  const [tipoCorrespondencia, setTipoCorrespondencia] = useState(true);
  const [copiaArchivo, setCopiaArchivo] = useState(true);
  const botonesOpciones = [
    {
      name: (
        <Popover
          placement="top"
          content={
            <div>
              <TextArea
                placeholder=""
                value={motivoCancelado}
                onChange={(e) => setMotivoCancelado(e.target.value)}
                disabled={editing && !esEmisor}
                autoSize={{minRows: 2, maxRows: 20}}
              />
              <Button
                type="primary"
                block
                size="large"
                name="estatus"
                value="CANCELADO"
                onClick={() => onFinish(form.getFieldsValue(true), "CANCELADO")}
                icon={<SaveOutlined/>}
              >
                Cancelar
              </Button>
            </div>
          }
          title="¿Porque quiere cancelar la correspondencia?"
        >
          Cancelar Correspondencia
        </Popover>
      ),
      icon: <DeleteOutlined/>,
      hidden: !editing,
    },
    {
      name: "Trámite",
      icon: <SolutionOutlined/>,
      onClick: () => guardarEstatus("TRAMITE"),
      hidden: !editing || !esReceptor || esCopia,
    },
    {
      name: "Atendido",
      icon: <FileDoneOutlined/>,
      onClick: () => guardarEstatus("ATENDIDO"),
      hidden: !editing || !esReceptor || esCopia,
    },
    /*    {
         name: "Guardar y Enviar",
         icon: <SaveOutlined />,
         onClick: () => onFinish(form.getFieldsValue(true), "ENVIADO"),
         hidden: esReceptor || esCopia,
       }, */
    {
      name: "Clonar",
      icon: <CopyOutlined/>,
      onClick: () => onFinish(form.getFieldsValue(true), "CLONAR"),
      hidden: !editing,
    },
  ];

  const comentariosParams = React.useMemo(() => {
    let body = {};
    if (id)
      body.idCorrespondencia = id
    if (user?.idUsuario)
      body.idUsuario = user?.idUsuario
    return body
  }, [id, user]);


  const requestComentarios = React.useMemo(
    () => ({
      name: "correspondencia-comentario",
      extraParams: comentariosParams,
    }),
    [comentariosParams]
  );

  const {model, modelLoading} = useModel({
    name: "correspondencia",
    id: id,
    expand:
      "correspondenciaResponsable,emisores,receptores,correspondenciaMedia,bitacora,comentario,areasReceptoras.usuarios.cargo",
  });

  const [usuarios, usuariosLoading] = useModels({
    name: "usuario",
    expand: "areas,cargo",
  });


  const [correspondenciaTipos, correspondenciaTiposLoading] = useModels({
    name: "documento-tipo",
    ordenar: "nombre-asc",
  });

  const [
    correspondenciaComentarios,
    // eslint-disable-next-line no-unused-vars
    correspondenciaComentariosLoading,
  ] = useModels(requestComentarios);

  const breadcrumbItems = [
    {
      name: "Correspondencia",
      to: "/correspondencia",
      icon: <FolderOpenOutlined/>,
    },
    {
      name: id ? "Editar " + model?.folio : "Nueva",
      to: id ? "/correspondencia/editar?id=" + id : "/correspondencia/nuevo",
      icon: <FileTextOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push("/correspondencia"),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: "primary", size: "large"},
  };

  const getCurrentToken = () => {
    return new Promise((resolve, reject) => {
      const jwt = localStorage.getItem(localStorageKey);
      if (!jwt) reject("No hay sesión.");
      resolve(jwt);
    });
  };

  /**
   * Revisa los comentarios de la correspondencia y el usuario que esta logueado
   */
  const checkComment = useCallback(async () => {
    let body = {
      idUsuario: user?.idUsuario,
      idCorrespondencia: id,
    };

    try {
      setLoading(true);
      const res = await StatusResponse.post(
        "correspondencia/comentarios",
        body
      );
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });
        } else if (res?.status === 200) {
          if (
            res?.response?.resultado[0]?.idComentario !== null ||
            res?.response?.resultado[0]?.idComentario !== undefined
          ) {
            setComentarios(res?.response?.resultado);
          }
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  }, [id, user?.idUsuario]);

  const onOpen = useCallback(async () => {
    let body = {};
    body.estatus = "RECIBIDO";
    body.idCorrespondencia = model?.idCorrespondencia;
    body.idUsuario = user?.idUsuario;
    try {
      setLoading(true);
      const res = await StatusResponse.post(
        "correspondencia/guardar-estatus",
        body
      );
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });
        } else if (res?.status === 200) {
          checkComment();
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  }, [checkComment, model?.idCorrespondencia, user?.idUsuario]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      onOpen();
    }
  }, [model, modelLoading, onOpen]);

  useEffect(() => {
    setUsuariosSelect(usuarios);
  }, [usuarios]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {

      let _correspondenciaReceptor = model?.correspondenciaResponsable?.filter(
        (item) => item?.tipo === 1
      );

      let _correspondenciaEmisor = model?.emisores;
      let idAreasReceptoras = JSON.parse(model?.idAreaReceptora);

      form.setFieldsValue({
        ...model,
        fecha: model?.fecha ? moment(model?.fecha) : null,
        confidencial: model?.confidencial === 0 ? false : true,
        envios: _correspondenciaReceptor
          ?.map((item) =>
            item?.tipo === 1 ? `${item?.nombreResponsable} ` : undefined   /* ${usuarios?.cargo?.nombre} */
          )
          ?.filter(function (item) {
            return item !== undefined;
          }),

        idAreaReceptora: idAreasReceptoras,
      });

      let _usuarios = [];
      model?.areasReceptoras?.map(area => _usuarios.push(...area?.usuarios));

      let _listaArchivos = model?.correspondenciaMedia?.map((item) => {
        return {
          name: item?.descripcion,
          uid: item?.uuid,
          url: `${item?.ruta}`,
          status: "done",
          thumbUrl: `${item?.ruta}`,
        };
      });

      let bolReceptor = false;
      let bolCopia = false;
      let _usrEnvios = [];

      _correspondenciaReceptor?.forEach((itemEnv) => {
        if (itemEnv?.idUsuarioReceptor === user?.idUsuario) {
          bolReceptor = true;
        }
        _usrEnvios.push(
          usuarios.filter(
            (item) => item?.idUsuario === itemEnv?.idUsuarioReceptor
          )[0]
        );
      });

      setCodigo(
        model?.uuid +
        "/" +
        (model?.fecha ? model?.fecha : "0000-00-00 00:00:00")
          .split(" ")[0]
          .replaceAll("-", "/") +
        "/" +
        model?.folio
      );
      setListaArchivos(_listaArchivos);
      setUsrEmisor(_correspondenciaEmisor[0]);
      setEsEmisor(
        _correspondenciaEmisor[0]?.idUsuario === user?.idUsuario ||
        model?.idCreador === user?.idUsuario
      );
      setEsReceptor(bolReceptor);
      setEsCopia(bolCopia);
      setUsrEnvios(_usrEnvios);
      setUsuariosSelect(_usuarios);
      setCopiaArchivo(model?.copiaArchivo);
      setEslogan(model?.eslogan);
      setBitacora(model?.bitacora);
    }

    return () => (mounted = false);
  }, [
    _baseUrl,
    form,
    model,
    modelLoading,
    token,
    user?.idUsuario,
    usuarios,
  ]);

  useEffect(() => {
    if (!editing) {
      form.setFieldsValue({
        fecha: moment(),
      });
    }
  }, [editing, form]);

  useEffect(() => {
    setTipoCorrespondencia(model?.estatus !== "EXTERNO");
  }, [model]);

  useEffect(() => {
    if (
      comentarios?.length > 0 &&
      comentarios[0]?.idComentario !== undefined &&
      comentarios[0]?.idComentario !== null
    ) {
      let _comentarios = comentarios?.map((item) => {
        let usuario = usuarios?.find(
          (usr) => usr?.idUsuario === item?.idUsuario
        );
        return {
          actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
          author: usuario?.nombre + " " + usuario?.apellidoPaterno + " " + usuario?.apellidoMaterno,
          avatar: usuario?.urlFoto,
          content: (
            <p>
              {item.comentario}{" "}
              {item.tipo === 0 ?
                (<span style={{color: "#1890ff", fontSize: "8px"}}>Publico</span>) :
                (<span style={{color: "#ff0000", fontSize: "8px"}}>Privado</span>)}
            </p>
          ),
          datetime: (
            <Tooltip
              title={moment(comentarios?.creado).format("DD/MM/YYYY HH:mm:ss")}
            >
              <span>{moment(comentarios?.creado).format("DD/MM/YYYY HH:mm:ss")}</span>
            </Tooltip>
          ),
        };
      });
      setComentarioList(_comentarios);
    }
  }, [comentarios, usuarios]);

  useEffect(() => {
    if (
      correspondenciaComentarios?.length > 0 &&
      correspondenciaComentarios[0]?.idComentario !== undefined &&
      correspondenciaComentarios[0]?.idComentario !== null
    ) {
      let _comentarios = correspondenciaComentarios?.map((item) => {
        let usuario = usuarios?.find(
          (usr) => usr?.idUsuario === item?.idUsuario
        );
        return {
          actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
          author: usuario?.nombre + " " + usuario?.apellidoPaterno + " " + usuario?.apellidoMaterno,
          avatar: usuario?.urlFoto,
          content: (
            <p>
              {item.comentario}{" "}
              {item.tipo === 0 ?
                (<span style={{color: "#1890ff", fontSize: "8px"}}>Publico</span>) :
                (<span style={{color: "#ff0000", fontSize: "8px"}}>Privado</span>)}
            </p>
          ),
          datetime: (
            <Tooltip
              title={moment(correspondenciaComentarios?.creado).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            >
              <span>{moment(correspondenciaComentarios?.creado).format("DD/MM/YYYY HH:mm:ss")}</span>
            </Tooltip>
          ),
        };
      });
      setComentarioList(_comentarios);
    }
  }, [correspondenciaComentarios, usuarios]);

  /**
   * guarda en la lista de archivos para subir al servidor
   */
  const onChange = ({fileList: newFileList}) => {
    setListaArchivos(newFileList);
  };

  const subirArchivo = async (file, idCorrespondencia) => {
    try {
      let _archivo = file.originFileObj;

      if (!_archivo) {
        message.info({
          content: "Debes de seleccionar un archivo",
          style: {marginTop: "20vh"},
        });
        return;
      }

      const form = new FormData();
      form.append("archivo", _archivo);
      form.append("idCorrespondencia", idCorrespondencia);

      const token = await getCurrentToken();

      const response = await fetch(
        _baseUrl + "v1/correspondencia/guardar-media",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: form,
        }
      );

      console.log("Respuesta", response);
    } catch (error) {
      console.log("error al cargar archivo: ", error);
    } finally {
    }
  };

  const onFinish = async (values, estats) => {
    const {
      asunto,
      descripcion,
      idInstitucion,
      idUsuarioEmitido,
      areaEmisora,
      idTipo,
      folio,
      emisor,
      fecha,
      idUsuarioFirma,
    } = values;

    let body = {
      idInstitucion,
      idUsuarioEmitido,
      areaEmisora,
      idTipo,
      folio,
      fecha: fecha ? moment(fecha).format() : "",
      emisor,
      asunto,
      descripcion,
      idUsuarioFirma,
    };

    let _areaReceptoras = [];
    let _envios = [];

    if (tipoCorrespondencia === true) {
      values.idAreaReceptora?.forEach((idArea) =>
        idArea !== "todas" ? _areaReceptoras.push(parseInt(idArea)) : ""
      );
      values.envios?.forEach((idUsuario) =>
        _envios.push(idUsuario)
      );
    }

    body.nombreFirmante = values?.emisor;
    body.idCreador = user?.idUsuario;
    body.confidencial = 0;
    body.estatus = estats;
    body.motivoCancelado = motivoCancelado;
    body.idUsuarioResponsable = user?.idUsuario;
    body.copiaArchivo = copiaArchivo ? 1 : 0;
    body.eslogan = eslogan !== "56" ? eslogan : user?.eslogan;
    body.areasReceptoras = _areaReceptoras;
    body.envios = _envios;
    body.nombreResponsable = values.nombreResponsable;


    if (editing) {
      body.idCorrespondencia = id;
    }

    if (values?.idAreaReceptora?.includes("todas")) {
      Modal.confirm({
        title: "Se seleccionaron todas las areas receptoras",
        icon: <CloseCircleOutlined style={{color: "red"}}/>,
        content: `¿Va a enviar la correspondencia a todas las Áreas?`,
        okText: "Sí",
        cancelText: "No",
        closable: true,
        onOk: async () => {
          try {
            setLoading(true);
            const res = await StatusResponse.post(
              "correspondencia/guardar",
              body
            );
            if (res) {
              if (res?.status === 400 && res?.errores !== null) {
                const arrayError = Object.values(res?.errores);
                console.log(arrayError);
                Modal.error({
                  title: "Error al guardar: datos incompletos.",
                });
              } else if (res?.status === 400 && res?.errores === null) {
                message.error({
                  content: res?.mensaje,
                  style: {marginTop: "20vh"},
                });
              } else if (res?.status === 200) {
                let tamano = listaArchivos.length;

                message.success({
                  content: res?.mensaje,
                  style: {marginTop: "20vh"},
                });

                if (tamano > 0) {
                  for (let i = 0; i < tamano; i++) {
                    subirArchivo(
                      listaArchivos[i],
                      res?.detalle?.idCorrespondencia
                    );
                    if (tamano === i + 1) {
                      history.push("/correspondencia");
                    }
                  }
                } else {
                  history.push("/correspondencia");
                }
              }
            }
          } catch (e) {
            console.log("Error al guardar: ", e);
          } finally {
            setLoading(false);
          }
        },
      });
    } else {
      try {
        setLoading(true);
        const res = await StatusResponse.post("correspondencia/guardar", body);
        if (res) {
          if (res?.status === 400 && res?.errores !== null) {
            const arrayError = Object.values(res?.errores);
            console.log(arrayError);
            Modal.error({
              title: "Error al guardar: datos incompletos.",
            });
          } else if (res?.status === 400 && res?.errores === null) {
            message.error({
              content: res?.mensaje,
              style: {marginTop: "20vh"},
            });
          } else if (res?.status === 200) {
            let tamano = listaArchivos.length;

            message.success({
              content: res?.mensaje,
              style: {marginTop: "20vh"},
            });

            if (tamano > 0) {
              for (let i = 0; i < tamano; i++) {
                subirArchivo(listaArchivos[i], res?.detalle?.idCorrespondencia);
                if (tamano === i + 1) {
                  history.push("/correspondencia");
                }
              }
            } else {
              history.push("/correspondencia");
            }
          }
        }
      } catch (e) {
        console.log("Error al guardar: ", e);
      } finally {
        setLoading(false);
      }
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning("Error al guardar: datos incompletos.");
    console.log(values, errorFields, outOfDate);
  };

  const guardarEstatus = async (estatus) => {
    let body = {};
    body.estatus = estatus;
    body.idUsuario = user?.idUsuario;
    body.idCorrespondencia = model?.idCorrespondencia;

    try {
      setLoading(true);
      const res = await StatusResponse.post(
        "correspondencia/guardar-estatus",
        body
      );
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });

          history.push("/correspondencia");
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const quitarUsuarioRecepcion = (idUsuario) => {
    const arr = [];

    idUsuario.forEach((itemArr) => {
      let arrAux = itemArr.split("/");
      if (!usuarioRecepcionCopia.includes(arrAux[0])) {
        arr.push(arrAux[0]);
      }
    });
    setUsuarioRecepcionCopia(arr);
  };

  /*const areaReceptora = (value) => {
    value = value.filter((item) => item.id !== "todas");

    const arr = [];
    const _usuarios = [];
    const _arrAreas = [];
    const _usuariosCopia = [];

    value.forEach((itemArr) => {
      let arrAux = itemArr.split("/");
      if (!usuarioRecepcion.includes(arrAux[0])) {
        arr.push(arrAux[0]);
      }
    });

    arr.forEach((itemArr) => {
      let area = areas.filter((item) => item.idArea === parseInt(itemArr))[0];
      area?.usuarios.forEach((usr) => _usuarios.push(usr));
      area?.usuarios.forEach((usr) => _usuariosCopia.push(usr));
      _arrAreas.push(area?.idArea);
    });

    setUsuariosSelect(_usuarios);
  };*/

  const regresaEstatus = () => {
    let estatus = model?.correspondenciaResponsable?.filter(
      (item) => item.idUsuarioReceptor === user.idUsuario
    )[0]?.estatus;

    if (estatus === "NUEVO") {
      return (
        <>
          <MailOutlined style={{fontSize: "26px"}}/>{" "}
          <span style={{fontSize: "26px"}}>Nuevo</span>
        </>
      );
    }
    if (estatus === "ATENDIDO") {
      return (
        <>
          <FileDoneOutlined style={{fontSize: "26px"}}/>{" "}
          <span style={{fontSize: "26px"}}>Atendido</span>
        </>
      );
    }
    if (estatus === "TRAMITE") {
      return (
        <>
          <SolutionOutlined style={{fontSize: "26px"}}/>{" "}
          <span style={{fontSize: "26px"}}> Trámite</span>
        </>
      );
    }
    if (estatus === "RECIBIDO") {
      return (
        <>
          <InboxOutlined style={{fontSize: "26px"}}/>{" "}
          <span style={{fontSize: "26px"}}>Recibido</span>
        </>
      );
    }
  };

  const comentar = async (tipo) => {
    let body = {};
    body.idCorrespondencia = model?.idCorrespondencia;
    body.comentario = comentario;
    body.idUsuario = user?.idUsuario;
    body.tipo = tipo;

    if (body.comentario === "") {
      message.error({
        content: "Comentario no puede estar vacio",
        style: {marginTop: "20vh"},
      });
      return;
    }

    try {
      setLoading(true);
      const res = await StatusResponse.post(
        "correspondencia-comentario/guardar",
        body
      );
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });

          const arrComentarios = [...comentarioList];

          arrComentarios.push({
            actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
            author: user?.nombreCompleto,
            avatar: user?.urlFoto,
            content: (
              <p>
                {comentario}{" "}
                {body.tipo === 0 ?
                  (<span style={{color: "#1890ff", fontSize: "8px"}}>Publico</span>) :
                  (<span style={{color: "#ff0000", fontSize: "8px"}}>Privado</span>)}
              </p>
            ),
            datetime: (
              <Tooltip
                title={moment(res?.response?.detalle?.creado)
                  .parseZone()
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss")}
              >
                <span>
                  {moment(res?.response?.detalle?.creado)
                    .parseZone()
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </Tooltip>
            ),
          });
          setComentarioList(arrComentarios);
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const imprimirCorrespondencia = (firma) => {
    if (firma === 1) {
      if (usrEmisor?.pin === null) {
        message.warning("El usuario no tiene un PIN asignado.");
        return;
      }

      firmarCorrespondencia(model?.idCorrespondencia, 1);

      window.open(
        `${_baseUrl}pdf/reportes/correspondencia?id=${model.idCorrespondencia}Us-${user.idUsuario}&f=1&access-token=${token}`,
        "_blank"
      );
    } else {
      window.open(
        `${_baseUrl}pdf/reportes/correspondencia?id=${model.idCorrespondencia}Us-${user.idUsuario}&f=0&access-token=${token}`,
        "_blank"
      );
    }
  };

  const firmarCorrespondencia = async () => {
    let body = {};
    body.idCorrespondencia = model?.idCorrespondencia;
    try {
      setLoading(true);
      const res = await StatusResponse.post(
        "correspondencia/guardar-firma",
        body
      );
      if (res) {
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });
        } else if (res?.status === 200) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: "20vh"},
          });
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const Destinatarios = () => {
    let value = <></>;
    if (!editing && tipoCorrespondencia === true) {
      value = (
        <>
          <Col className="gutter-row" span={24}>
            <Title level={5}>Destinatarios</Title>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item
              label="Área Receptora"
              name="idAreaReceptora"
            >
              {/*<Select
                showSearch
                onSearch={onSearchArea}
                mode="multiple"
                size="large"
                loading={areasLoading}
                placeholder="Selecciona un área receptora"
                onChange={(value) => {
                  areaReceptora(value);
                  refreshAreas();
                }}
                onSelect={(input, option) => {
                  console.log(option);
                  areaReceptora(value);
                  refreshAreas();
                }}
                filterOption={(input, option) =>
                  option.children[2]
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children[2]
                    ?.toLowerCase()
                    .localeCompare(optionB.children[2]?.toLowerCase())
                }
              >
                <Option key="todas" value="todas">
                  Todas{""}
                  {""}
                </Option>
                {areas?.map((item) => (
                  <Option
                    key={item?.idArea}
                    value={item?.idArea + "/" + item?.nombre}
                  >
                    {item?.codigo} - {item?.nombre}
                  </Option>
                ))}
              </Select>/**/}
              <SelectComponent
                size="large"
                modelsParams={{
                  name: "area",
                  expand: "usuarios.usuario.cargo",
                }}
                mode="multiple"
                labelProp="nombre"
                onChange={(value, row) => {
                  let _usuarios = [];
                  form.setFieldValue("envios", []);
                  if (value.includes("todas"))
                    setUsuariosSelect(usuarios);
                  else {
                    row.map(area => _usuarios.push(...area?.usuarios));
                    setUsuariosSelect(_usuarios);
                  }
                }}
                onDeselected={() =>
                  setUsuariosSelect(usuarios)
                }
                valueProp="idArea"
                idModel="idArea"
                unShift={[{idArea: "todas", codigo: "", nombre: "Todas"}]}
                placeholder="Selecciona una opción"
                render={(item, row) => {
                  return row?.codigo + ' - ' + row?.nombre
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item label="Destinatarios" name="envios">
              <Select
                mode="multiple"
                allowClear
                size="large"
                loading={usuariosLoading}
                style={{width: "100%"}}
                placeholder="Selecciona"
                onChange={(e) => quitarUsuarioRecepcion(e)}
              >
                {usuariosSelect
                  ?.filter((usr) => !usuarioRecepcion?.includes(usr?.idUsuario))
                  .map((item) => (
                    <Option
                      key={item?.idCorrespondencia}
                      value={`${item?.idUsuario}`}
                    >
                      {item?.nombre} {item?.apellidoPaterno}{" "}
                      {item?.apellidoMaterno} - {item?.cargo?.nombre}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </>
      );
    } else if (!editing && tipoCorrespondencia === false) {
      value = (
        <>
          <Col className="gutter-row" span={24}>
            <Title level={5}>Destinatarios</Title>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item label="Destinatario" name="nombreResponsable">
              <Input size="large"/>
            </Form.Item>
          </Col>
        </>
      );
    } else if (editing === true && model?.estatus === "NUEVO") {
      value = (
        <>
          <Col className="gutter-row" span={24}>
            <Title level={5}>Destinatarios</Title>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item label="Área receptora " name="idAreaReceptora">
              <SelectComponent
                size="large"
                modelsParams={{
                  name: "area",
                  expand: "usuarios.cargo",
                }}
                mode="multiple"
                labelProp="nombre"
                onChange={(value, row) => {
                  form.setFieldValue("envios", []);
                  let _usuarios = [];
                  if (value.includes("todas"))
                    setUsuariosSelect(usuarios);
                  else {
                    row.map(area => _usuarios.push(...area?.usuarios));
                    setUsuariosSelect(_usuarios);
                  }
                }}
                valueProp="idArea"
                idModel="idArea"
                unShift={[{idArea: "todas", codigo: "", nombre: "Todas"}]}
                placeholder="Selecciona una opción"
                render={(item, row) => {
                  return row?.codigo ? row?.codigo + ' - ' + row?.nombre : row?.nombre;
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item label="Destinatarios" name="envios">
              <Select
                mode="multiple"
                allowClear
                size="large"
                loading={usuariosLoading}
                style={{width: "100%"}}
                placeholder="Selecciona"
                onChange={(e) => quitarUsuarioRecepcion(e)}
              >
                {usuariosSelect
                  ?.filter((usr) => !usuarioRecepcion?.includes(usr?.idUsuario))
                  .map((item) => (
                    <Option
                      key={item?.idCorrespondencia}
                      value={`${item?.idUsuario}`}
                    >
                      {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno} - {item?.cargo?.nombre}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </>
      );
    } else if (editing === true && model?.estatus === "EXTERNO") {
      value = (
        <>
          <Col className="gutter-row" span={24}>
            <Title level={5}>Destinatarios</Title>
          </Col>
          <Col className="gutter-row" span={24}/>
        </>
      );
    } else if (editing === true && model?.estatus === "ENVIADO") {
      value = (
        <>
          <Col hidden={!usrEnvios?.length > 0} className="gutter-row" span={24}>
            <Title level={5}>Destinatarios</Title>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item label="Área receptora " name="idAreaReceptora">
              {/*<Select
                /* disabled={editing && !esEmisor} * /
                showSearch
                onSearch={onSearchArea}
                mode="multiple"
                size="large"
                loading={areasLoading}
                placeholder="Selecciona un área receptora"
                onChange={(value) => {
                  areaReceptora(value);
                  refreshAreas();
                }}
                onSelect={(input, option) => {
                  console.log(option);
                  areaReceptora(value);
                  refreshAreas();
                }}
                filterOption={(input, option) =>
                  option?.children[2]
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children[2]
                    ?.toLowerCase()
                    ?.localeCompare(optionB?.children[2]?.toLowerCase())
                }
              >
                <Option key="todas" value="todas">
                  Todas{""}
                  {""}
                </Option>
                {areas?.map((item) => (
                  <Option
                    key={item?.idArea}
                    value={item?.idArea + "/" + item?.nombre}
                  >
                    {item?.codigo} - {item?.nombre}
                  </Option>
                ))}
              </Select>/**/}
              <SelectComponent
                size="large"
                modelsParams={{
                  name: "area",
                  expand: "usuarios.cargo",
                }}
                mode="multiple"
                labelProp="nombre"
                onChange={(value, row) => {
                  form.setFieldValue("envios", []);
                  let _usuarios = [];
                  if (value.includes("todas"))
                    setUsuariosSelect(usuarios);
                  else {
                    row.map(area => _usuarios.push(...area?.usuarios));
                    setUsuariosSelect(_usuarios);
                  }
                }}
                valueProp="idArea"
                idModel="idArea"
                unShift={[{idArea: "todas", codigo: "", nombre: "Todas"}]}
                placeholder="Selecciona una opción"
                render={(item, row) => {
                  return row?.codigo ? row?.codigo + ' - ' + row?.nombre : row?.nombre;
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item label="Destinatarios" name="envios">
              <Select
                mode="multiple"
                allowClear
                size="large"
                loading={usuariosLoading}
                style={{width: "100%"}}
                placeholder="Selecciona"
                onChange={(e) => quitarUsuarioRecepcion(e)}
                /*  disabled={editing && !esEmisor}  */
              >
                {usuariosSelect
                  ?.filter((usr) => !usuarioRecepcion?.includes(usr?.idUsuario))
                  .map((item) => (
                    <Option
                      key={item?.idCorrespondencia}
                      value={`${item?.idUsuario}`}
                    >
                      {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno} - {item?.cargo?.nombre}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </>
      );
    }
    return value;
  };

  if (loading) return <ViewLoading/>;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {editing ? (
          <Row gutter={12}>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 4}}
              lg={{span: 4}}
              xxl={{span: 4}}
            >
              <img
                src={
                  "https://chart.googleapis.com/chart?cht=qr&chs=250x250&choe=UTF-8&chl=" +
                  codigo
                }
                style={{width: "100%", marginTop: "-20%"}}
                alt=""
              />
            </Col>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 16}}
              lg={{span: 16}}
              xxl={{span: 16}}
            >
              {regresaEstatus()}
              <br/>
              {codigo}
            </Col>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 4}}
              lg={{span: 4}}
              xxl={{span: 4}}
              style={{textAlign: "right"}}
            >
              <Button
                type="primary"
                block
                size="large"
                name="estatus"
                value="NUEVO"
                onClick={() => imprimirCorrespondencia(0)}
                icon={<FilePdfTwoTone/>}
                style={{margin: "3px 0"}}
              >
                Imprimir
              </Button>
              <Button
                type="primary"
                hidden={!editing || model?.firmado !== 1}
                block
                size="large"
                name="estatus"
                icon={<FilePdfTwoTone/>}
                style={{margin: "3px 0"}}
                onClick={() =>
                  window.open(
                    `${_baseUrl}pdf/reportes/correspondencia?id=${model.idCorrespondencia}Us-${user.idUsuario}&f=1&access-token=${token}`,
                    "_blank"
                  )
                }
              >
                Imprimir Firmado
              </Button>
            </Col>
          </Row>
        ) : null}
        {/* Asunto | Área Emisora | Emitido por | Área receptora  */}
        <Row gutter={12}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Folio"
              name="folio"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Input
                type="text"
                size="large"
                placeholder=""
                disabled={editing && !esEmisor}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Fecha"
              name="fecha"
              rules={[{required: true, message: "Requerido"}]}
            >
              <DatePicker
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                showTime
                locale={locale}
                format="DD/MM/YYYY HH:mm"
                size="large"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item
              label="Asunto"
              name="asunto"
              rules={[{required: true, message: "Requerido"}]}
            >
              <TextArea
                placeholder=""
                disabled={editing && !esEmisor}
                autoSize={{minRows: 2, maxRows: 20}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 9}}
            lg={{span: 9}}
            xxl={{span: 9}}
          >
            <Form.Item
              label="Entidad Emisora "
              name="areaEmisora"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Input size="large"/>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 9}}
            lg={{span: 9}}
            xxl={{span: 9}}
          >
            <Form.Item label="Emitido por" name="emisor">
              <Input size="large"/>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12}}
            sm={{span: 12}}
            md={{span: 6}}
            lg={{span: 6}}
            xxl={{span: 6}}
          >
            <Form.Item
              label="Tipo"
              name="idTipo"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                size="large"
                loading={correspondenciaTiposLoading}
                disabled={editing && !esEmisor}
                placeholder="Selecciona un tipo"
              >
                {correspondenciaTipos?.map((item) => (
                  <Option
                    key={"tipo-" + item?.idDocumentoTipo}
                    value={item?.idDocumentoTipo}
                  >
                    {item?.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Descripción | Tipo | Confidencial */}
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[{required: true, message: "Requerido"}]}
            >
              <TextArea
                placeholder=""
                disabled={editing && !esEmisor}
                autoSize={{minRows: 4, maxRows: 20}}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        {/* Envios */}
        <Row gutter={10}>{Destinatarios()}</Row>
        <Divider/>
        {/* Anexos  */}
        <Row gutter={10}>
          <Col className="gutter-row" span={24}>
            <Title level={5}>Anexos</Title>
          </Col>
          <Col className="gutter-row" span={24}>
            <Upload
              beforeUpload={() => false}
              multiple={true}
              fileList={listaArchivos}
              listType="picture-card"
              onChange={onChange}
            >
              {listaArchivos.length < listaArchivos.length + 2 &&
              "+ Subir archivos"}
            </Upload>
          </Col>
        </Row>
        <Divider/>
        {/* Comentarios */}
        <Row hidden={comentarioList?.length === 0 && !editing} gutter={10}>
          <Col className="gutter-row" span={24}>
            <List
              className="comment-list"
              header={`${
                comentarioList ? comentarioList?.length : 0
              } Comentarios`}
              itemLayout="horizontal"
              dataSource={comentarioList}
              renderItem={(item) => (
                <li>
                  <Comment
                    author={item.author}
                    avatar={item.avatar}
                    content={item.content}
                    datetime={item.datetime}
                  />
                </li>
              )}
            />
          </Col>
          <Divider/>
          <Col className="gutter-row" span={20}>
            <TextArea
              placeholder="Introduce aqui tu comentario"
              size="large"
              onChange={(e) => {
                setComentario(e.target.value);
              }}
              autoSize={{minRows: 3, maxRows: 20}}
            />
          </Col>
          <Col className="gutter-row" span={4}>
            <Button
              type="primary"
              block
              size="large"
              onClick={() => comentar(0)}
            >
              Público
            </Button>
            <Button
              type="primary"
              block
              size="large"
              onClick={() => comentar(1)}
            >
              Privado
            </Button>
          </Col>
          <Divider/>
        </Row>
        <Divider/>
        {/* Botones */}
        <Row>
          <Col
            hidden={!editing}
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item name="copiaArchivo">
              {copiaArchivo ? "Copia para archivo" : ""}
            </Form.Item>
          </Col>
          <Col span={4}>
            {editing ? (
              <ActionsButton
                key="actions-button"
                text="Acciones"
                size="large"
                type="primary"
                style={{width: "100%"}}
                placement="top"
                options={botonesOpciones}
              />
            ) : (
              ""
            )}
          </Col>
          <Col span={4}>
            <Button
              icon={<SaveOutlined/>}
              onClick={() => onFinish(form.getFieldsValue(true), "ENVIADO")}
              type="primary"
              size="large"
            >
              Guardar y Enviar
            </Button>
          </Col>
          <Col span={3}>
            <Button
              type="dashed"
              block
              size="large"
              onClick={() => history.goBack()}
            >
              Salir
            </Button>
          </Col>
        </Row>
        <Divider/>
        {/* Revisiones */}
        <Row hidden={!editing}>
          <Col className="gutter-row" span={24}>
            <Title level={5}>Revisiones</Title>
          </Col>
          <Col className="gutter-row" span={8}>
            {bitacora.map((item, index) => {
              return (
                <Timeline>
                  <Popover
                    content={
                      <Row className="gutter-row" span={12}>
                        <Col className="gutter-row" span={11}>
                          <Row className="gutter-row" span={12}>
                            <Col span={12}>Asunto</Col>
                            <Col span={12}>Descripción</Col>
                            <Col span={12}>
                              {JSON.parse(item?.antes)?.asunto}
                            </Col>
                            <Col span={12}>
                              {JSON.parse(item?.antes)?.descripcion}
                            </Col>
                          </Row>
                        </Col>
                        <Col className="gutter-row" span={2}>
                          <RightOutlined
                            style={{fontSize: "30px", margin: "27%"}}
                          />
                        </Col>
                        <Col className="gutter-row" span={11}>
                          <Row className="gutter-row" span={12}>
                            <Col span={12}>Asunto</Col>
                            <Col span={12}>Descripción</Col>
                            <Col span={12}>
                              {JSON.parse(item?.despues).asunto}
                            </Col>
                            <Col span={12}>
                              {JSON.parse(item?.despues).descripcion}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    }
                    title={
                      "Cambios en realizados " +
                      moment(item?.fecha).format("DD/MM/YYYY HH:mm:ss")
                    }
                    overlayStyle={{
                      width: "80%",
                    }}
                  >
                    <Timeline.Item>
                      {moment(item?.fecha).format("DD/MM/YYYY HH:mm:ss")}
                    </Timeline.Item>
                  </Popover>
                </Timeline>
              );
            })}
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};
export default CorrespondenciaForm;
