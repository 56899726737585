import React, {useEffect, useState} from 'react';
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Typography,
  Divider,
  Radio,
  Upload,
  Popover,
} from 'antd';
import {
  FileTextOutlined,
  FilePdfTwoTone,
  FolderOpenOutlined,
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import {useQuery, useModels, useModel, useAuth, useApp} from "../../hooks";

const {REACT_APP_API_URL: baseUrl} = process.env;

const DocumentoView = () => {

    const {Option} = Select;
    const {TextArea} = Input;
    const {Title} = Typography;
    const query = useQuery();
    const id = query.get("id");
    const editing = !!id;
    const _baseUrl = baseUrl.replace("v1/", "")
    const titulo = id ? 'Editar Documento' : "Nuevo documento";
    const [form] = Form.useForm();
    const history = useHistory();
    const {user} = useAuth();

    const [confidencial, setConfidencial] = useState(false);
    const {token} = useApp();

    //# const [usrEnvios, setUsrEnvios] = useState([]);
    //# const [usrCopias, setUsrCopias] = useState([]);
    const [usrEmisor, setUsrEmisor] = useState([]);

    //# const [usuarioRecepcion, setUsuarioRecepcion] = useState([]);
    //# const [usuarioRecepcionCopia, setUsuarioRecepcionCopia] = useState([]);
    //# const [usuariosSelect, setUsuariosSelect] = useState([]);
    //# const [usuariosSelectCopia, setUsuariosSelectCopia] = useState([]);
    //# const [usuarioEmisor, setUsuarioEmisor] = useState([]);
    //# const [usuariosEmisores, setUsuariosEmisores] = useState([]);
    //# const [estatusVal, setEstatusVal] = useState('');

    //# const [areasEmisoras, setAreasEmisoras] = useState([]);
    const [esEmisor, setEsEmisor] = useState(true);
    const [esReceptor, setEsReceptor] = useState(false);
    const [esCopia, setEsCopia] = useState(false);
    //# const [esBorrador, setEsBorrador] = useState(true);
    const [codigo, setCodigo] = useState("");
    const [pin, setPin] = useState('');
    //# const [motivoCancelado, setMotivoCancelado] = useState('');
    const [listaArchivos, setListaArchivos] = useState([]);
    //# const [eslogan, setEslogan] = useState("56");
    //# const [comentario, setComentario] = useState("");
    //# const [comentarios, setComentarios] = useState([]);
    //# const [comentarioList, setComentarioList] = useState([]);
    //# const [bitacora, setBitacora] = useState([]);
    //# const [externo, setExterno] = useState([]);
    //# const [copiaArchivo, setCopiaArchivo] = useState(true);

    const {
      model,
      modelLoading
    } = useModel({
      name: 'documento',
      id: id,
      expand: 'documentoResponsable, emisores, receptores, documentoMedia, bitacora',
    });

    const [
      usuarios
    ] = useModels({
      name: 'usuario',
      expand: 'areas'
    });

    const [
      areas
    ] = useModels({
      name: 'area',
      expand: 'usuarios'
    });

    const [
      documentoTipos,
      documentoTiposLoading
    ] = useModels({
      name: 'documento-tipo',
      ordenar: 'nombre-asc'
    });


    const breadcrumbItems = [
      {
        name: "Documentos",
        to: '/documento/',
        icon: <FolderOpenOutlined/>,
      },
      {
        name: id ? "Editar " + model?.folio : "Ver documento",
        to: id ? '/documento/ver-documento?id=' + id : '/nuevo',
        icon: <FileTextOutlined/>,
      },
    ];

    useEffect(() => {
      let mounted = true;
      if (mounted && model && !modelLoading) {

        let _documentoCopia = model?.documentoResponsable.filter(item => item?.tipo === 2);
        let _documentoReceptor = model?.documentoResponsable.filter(item => item?.tipo === 1);
        //# let _documentoExterno = model?.documentoResponsable.filter(item => item?.estatus === 'EXTERNO');
        let _documentoEmisor = model?.emisores;
        let idAreasReceptoras = JSON.parse(model.idAreaReceptora);

        let areasSeleccionadas = idAreasReceptoras?.map((item) => {
          return areas.filter(area => item === area?.idArea)[0]?.idArea + "/" + areas.filter(area => item === area?.idArea)[0]?.nombre
        });

        form.setFieldsValue({
          ...model,
          confidencial: model?.confidencial === 0 ? false : true,
          envios: _documentoReceptor?.map((item) => item?.tipo === 1 ? item?.idUsuarioReceptor + "/" + item.nombreResponsable : undefined).filter(function (item) {
            return item !== undefined
          }),
          copias: _documentoCopia?.map((item) => item?.tipo === 2 ? item?.idUsuarioReceptor + "/" + item.nombreResponsable : undefined).filter(function (item) {
            return item !== undefined
          }),
          idAreaReceptora: areasSeleccionadas,
        })

        let _listaArchivos = model?.documentoMedia?.map(item => {
          return {
            name: item?.descripcion,
            uid: item?.uuid,
            url: `${_baseUrl}v1/descarga/documento?uuid=${item?.uuid}&access-token=${token}`,
            status: 'done',
            thumbUrl: `${item?.ruta}`,
          }
        });

        let bolReceptor = false;
        let bolCopia = false;
        let _usrEnvios = [];
        let _usrCopias = [];

        _documentoReceptor.forEach(itemEnv => {
          if (itemEnv.idUsuarioReceptor === user?.idUsuario) {
            bolReceptor = true;
          }
          _usrEnvios.push(usuarios.filter(item => item.idUsuario === itemEnv.idUsuarioReceptor)[0])
        })
        //# setExterno(_documentoExterno[0]);

        _documentoCopia.forEach(itemCop => {
          if (itemCop.idUsuarioReceptor === user?.idUsuario) {
            bolCopia = true;
          }
          _usrCopias.push(usuarios.filter(item => item.idUsuario === itemCop.idUsuarioReceptor)[0])
        })

        setCodigo(model?.uuid + "/" + model?.idUsuarioFirma + "/" + areas.filter(item => item.idArea === model?.idAreaEmisora)[0]?.codigo + "/" + (model?.fecha ? model?.fecha : "0000-00-00 00:00:00").split(" ")[0].replaceAll("-", "/") + "/" + model?.folio);
        setListaArchivos(_listaArchivos);
        setUsrEmisor(_documentoEmisor[0]);
        setEsEmisor(_documentoEmisor[0]?.idUsuario === user?.idUsuario || model?.idUsuarioFirma === user?.idUsuario);
        setEsReceptor(bolReceptor);
        setEsCopia(bolCopia);
        //# setUsrEnvios(_usrEnvios);
        //# setUsrCopias(_usrCopias);
        //# setCopiaArchivo(model?.copiaArchivo);
        //# setEslogan(model?.eslogan);
        //# setBitacora(model?.bitacora);
        //# setEsBorrador(model?.estatus === 'NUEVO');

        //# setAreasEmisoras(usuarios.filter(item => item.idUsuario === user.idUsuario)[0]?.areas);
        //# setUsuariosEmisores(usuarios.filter(item => item.idUsuario === model.idUsuarioFirma))
      }
      return () => mounted = false;
    }, [form, modelLoading, model, usuarios, areas, user?.idUsuario, _baseUrl, token]);


    /**
     * guarda los usuarios en arreglos separados ya sea receptores o copias
     * /
    useEffect(() => {
      setAreasEmisoras(usuarios.filter(item => item.idUsuario === user.idUsuario)[0]?.areas);
      setUsuariosSelect(usuarios);
      setUsuariosSelectCopia(usuarios);
    }, [usuarios]);
    // */

    /**
     * Guarda los comentarios
     * /
    useEffect(() => {
      if (comentarios?.length > 0 && comentarios[0]?.idComentario !== undefined && comentarios[0]?.idComentario !== null) {
        let _comentarios = comentarios?.map(item => {
          let usuario = usuarios?.find(usr => usr?.idUsuario === item?.idUsuario);
          return {
            actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
            author: usuario?.nombre + " " + usuario?.apellidoPaterno + " " + usuario?.apellidoMaterno,
            avatar: usuario?.urlFoto,
            content: (
              <p>
                {item.comentario} {item.tipo === 0 ? <span style={{color: '#1890ff', fontSize: "8px"}}>Publico</span> :
                <span style={{color: '#ff0000', fontSize: "8px"}}>Privado</span>}
              </p>
            ),
            datetime: (
              <Tooltip title={moment(comentarios?.creado).format('DD/MM/YYYY HH:mm:ss')}>
                <span>{moment(comentarios?.creado).format('DD/MM/YYYY HH:mm:ss')}</span>
              </Tooltip>
            ),
          }
        });
        setComentarioList(_comentarios);
      }
    }, [comentarios]);
    // */

    /**
     * Firma el documento y lo guarda en la base de datos
     */
    const imprimirDocumento = (firma) => {
      if (firma === 1) {
        if (usrEmisor?.pin === null) {
          message.warning('El usuario no tiene un PIN asignado.');
          return;
        }

        if (usrEmisor?.pin + '' !== pin) {
          message.warning('El PIN ingresado no es correcto.');
          return;
        }

        window.open(`${_baseUrl}pdf/reportes/documento?id=${model.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')
      } else {
        window.open(`${_baseUrl}pdf/reportes/documento?id=${model.idDocumento}Us-${user.idUsuario}&f=0&access-token=${token}`, '_blank')
      }
    }

    return (
      <DefaultLayout
        title={titulo}
        breadcrumbItems={breadcrumbItems}
      >
        <Form
          form={form}
          name="form"
          layout="vertical"
        >
          {editing ? <Row gutter={12}>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 4}}
              lg={{span: 4}}
              xxl={{span: 4}}
            >
              <img src={"https://chart.googleapis.com/chart?cht=qr&chs=250x250&choe=UTF-8&chl=" + codigo}
                   style={{width: "100%", marginTop: "-28%"}} alt=""/>
            </Col>
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 16}}
              lg={{span: 16}}
              xxl={{span: 16}}
            >
              {codigo}
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 4}}
              lg={{span: 4}}
              xxl={{span: 4}}
              style={{textAlign: 'right'}}
            >
              <Button
                type="primary"
                block size='large'
                name="estatus"
                value="NUEVO"
                onClick={() => imprimirDocumento(0)}
                icon={<FilePdfTwoTone/>}
                style={{margin: '3px 0'}}
              >
                Imprimir
              </Button>

              <Popover
                placement="right"
                content={<div>
                  {user?.pin !== null ? <Input
                    type="password"
                    value={pin}
                    onChange={e => setPin(e.target.value)}
                  /> : <span onClick={() => history.push("/perfil")} style={{margin: "0 8px"}}>Introducir un PIN para firma.</span>}
                  <Button
                    type="primary"
                    block size='large'
                    name="estatus"
                    value="NUEVO"
                    icon={<FilePdfTwoTone/>}
                    style={{margin: '3px 0'}}
                  >
                    Imprimir
                  </Button>
                </div>} title="Digite PIN de impresión">
                <Button
                  type="primary"
                  hidden={(!esEmisor || model?.firmado === 1)}
                  block size='large'
                  name="estatus"
                  value="NUEVO"
                  icon={<FilePdfTwoTone/>}
                  style={{margin: '3px 0'}}
                >
                  Imprimir Firmado
                </Button>
              </Popover>

              <Button
                type="primary"
                hidden={(!editing || model?.firmado !== 1)}
                block size='large'
                name="estatus"
                icon={<FilePdfTwoTone/>}
                style={{margin: '3px 0'}}
                onClick={() => window.open(`${_baseUrl}pdf/reportes/documento?id=${model.idDocumento}Us-${user.idUsuario}&f=1&access-token=${token}`, '_blank')}
              >
                Imprimir Firmado
              </Button>

            </Col>
          </Row> : null}

          {/* Asunto | Área Emisora | Emitido por | Área receptora  */}
          <Row gutter={12}>

            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 24}}
              lg={{span: 24}}
              xxl={{span: 24}}
            >
              <Form.Item
                label="Asunto"
                name="asunto"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <TextArea
                  placeholder=""
                  disabled={editing && !esEmisor}
                  autoSize={{minRows: 2, maxRows: 20}}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 8}}
              lg={{span: 8}}
              xxl={{span: 8}}
              hidden={editing && !esEmisor}
            >
              <Form.Item
                label="Área Emisora "
                name="idAreaEmisora"
                //rules={[{required: true, message: 'Requerido'}]}
              >


              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 8}}
              lg={{span: 8}}
              xxl={{span: 8}}
              hidden={!editing || esEmisor || (!esReceptor && !esCopia)}
            >
              <label
                style={{marginBottom: "50px"}}
                htmlFor="form_idUsuarioFirmaCopia"
              >Área Emisora</label>

              <Input
                name="idAreaEmisoraCopia"
                size='large'
                disabled
                value={areas.filter(item => item?.idArea === model?.idAreaEmisora)[0]?.nombre}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 8}}
              lg={{span: 8}}
              xxl={{span: 8}}
            >
              <Form.Item
                label="Emitido por"
                name="idUsuarioFirma"
                //rules={[{required: true, message: 'Requerido'}]}
              >

              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 6}}
              lg={{span: 6}}
              xxl={{span: 6}}
            >
              <Form.Item
                label="Tipo"
                name="idTipo"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <Select
                  size='large'
                  loading={documentoTiposLoading}
                  disabled={editing && !esEmisor}
                  placeholder="Selecciona un tipo"
                >
                  {documentoTipos?.map(item => <Option key={"tipo-" + item?.idDocumentoTipo}
                                                       value={item?.idDocumentoTipo}>{item?.nombre}</Option>)}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{span: 12}}
              sm={{span: 12}}
              md={{span: 2}}
              lg={{span: 2}}
              xxl={{span: 2}}
            >
              <Form.Item
                label="Confidencial"
                name="confidencial"
              >
                <Radio.Group
                  onChange={(e) => setConfidencial(e.target.value)}
                  defaultValue={confidencial}
                  disabled={editing && !esEmisor}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {/* Descripción | Tipo | Confidencial */}
            <Col
              className="gutter-row"
              xs={{span: 24}}
              sm={{span: 24}}
              md={{span: 24}}
              lg={{span: 24}}
              xxl={{span: 24}}
            >
              <Form.Item
                label="Descripción"
                name="descripcion"
                rules={[{required: true, message: 'Requerido'}]}
              >
                <TextArea
                  placeholder=""
                  disabled={editing && !esEmisor}
                  autoSize={{minRows: 4, maxRows: 20}}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider/>

          {/* Anexos  */}
          <Row gutter={10}>

            <Col className="gutter-row" span={24}>
              <Title level={5}>Anexos</Title>
            </Col>

            <Col
              className="gutter-row"
              span={24}
            >
              <Upload
                //previewFile={{showPreviewIcon:false}}
                beforeUpload={() => false}
                multiple={true}
                fileList={listaArchivos}
                showUploadList={{showRemoveIcon: true}}
                listType="picture-card"
              >
                {!editing && esEmisor ? listaArchivos.length < (listaArchivos.length + 2) && '+ Subir archivos' : false}
              </Upload>

            </Col>
          </Row>

        </Form>
      </DefaultLayout>
    );
  }
;

export default DocumentoView;