import React, {useState} from "react";
import {Form, Row, Col, Input, Select, DatePicker, Space, Tooltip, Button, notification} from "antd";
import {ClearOutlined, SearchOutlined, SubnodeOutlined, FileExcelOutlined, FilePdfOutlined} from '@ant-design/icons';
import {useApp, useModels} from "../../../hooks";

const {REACT_APP_API_URL: baseUrl} = process.env;
const {RangePicker} = DatePicker;
const {Option} = Select;

export const Search = ({
                         disabledButtons,
                         form,
                         loading,
                         onClean,
                         onSearch,
                         onExcel,
                         user
                       }) => {

  const {token} = useApp();
  const _baseUrl = baseUrl.replace("v1/", "");
  const [subFondoAreasRequest, setSubFondoAreasRequest] = useState({});
  const [seccionesRequest, setSeccionesRequest] = useState({});
  const [seriesSelect, setSeriesSelect] = useState([]);

  const subFondoAreasParams = React.useMemo(() => ({
    name: 'sub-fondo-area',
    ordenar: 'codigo-asc',
    limite: 20,
  }), []);
  const seccionesParams = React.useMemo(() => ({
    name: 'seccion',
    expand: 'series',
    ordenar: 'orden-asc',
    limite: 20,
  }), []);

  // Áreas
  const [
    subFondoAreas,
    subFondoAreasLoading, , , refreshSubFondoAreas
  ] = useModels(subFondoAreasRequest);

  // Secciones
  const [
    secciones,
    seccionesLoading, ,
  ] = useModels(seccionesRequest);


  const descargarPdf = (tipo, formato = 'normal') => {
    let _subFondo = form.getFieldValue("subFondoArea");
    let _idSerie = form.getFieldValue("serie");
    let _idSeccion = form.getFieldValue("seccion");
    let _serie = _idSerie ? "&idSerie=" + parseInt(_idSerie) : "";
    let _seccion = _idSeccion ? "&idSeccion=" + parseInt(_idSeccion) : "";
    let _area = _subFondo ? "&idSubFondoArea=" + parseInt(_subFondo) : "";
    if (formato === 'guia')
      window.open(`${_baseUrl}pdf/expediente/guia-baja?&access-token=${token}${_area}`, '_blank')
    else
      window.open(`${_baseUrl}pdf/expediente/pdf-expedientes-baja?hoja=legal&access-token=${token}${_area}${_serie}${_seccion}`, '_blank')
  }

  const onSearchSubFondoAreas = async (value) => {
    if (value?.length > 0) {
      await refreshSubFondoAreas(true, {
        buscar: value
      })
    } else (
      await refreshSubFondoAreas()
    )
  }

  React.useEffect(() => {
    setSubFondoAreasRequest(subFondoAreasParams);
    setSeccionesRequest(seccionesParams);
    return () => {
      setSubFondoAreasRequest({});
      setSeccionesRequest({});
    }
  }, [seccionesParams, subFondoAreasParams]);

  return (
    <Form
      form={form}
      layout='vertical'
    >
      <Row gutter={[5, 0]}>
        <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} className={"header-expediente"}>
          <Form.Item
            name='buscar'
            label=''
          >
            <Input placeholder='Buscar...'/>
          </Form.Item>
        </Col>
        {user?.rol === "RGA" || user?.rol === "ADMINISTRADOR" ? (
          <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} className={"header-expediente"}>
            <Form.Item
              name='subFondoArea'
              label=''
            >
              <Select
                placeholder='Buscar por Área ...'
                allowClear
                showSearch
                listItemHeight={10}
                listHeight={250}
                loading={subFondoAreasLoading}
                onSearch={onSearchSubFondoAreas}
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                }
              >
                {subFondoAreas.map((item, index) => (
                  <Option key={"sub-fondo-area-" + index}
                          value={item?.idSubFondoArea}>{item?.codigo + " - " + item?.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} className={"header-expediente"}>
          <Form.Item
            name='seccion'
            label=''
          >
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              disabled={seccionesLoading}
              loading={seccionesLoading}
              style={{width: "100%"}}
              placeholder="Sección"
              onChange={(val) => {
                let _secciones = secciones?.find(
                  (seccion) => seccion?.idSeccion === val
                );
                setSeriesSelect(_secciones?.series);
                form.setFieldsValue({
                  serie: null,
                });
              }}
            >
              {secciones?.length > 0 &&
              secciones?.map((item, index) => (
                <Option key={`seccion-${index}`} value={item?.idSeccion}>
                  {item?.codigo} - {item?.titulo}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} className={"header-expediente"}>
          <Form.Item
            name='serie'
            label=''
          >
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              style={{width: "100%"}}
              placeholder="Serie"
            >
              {seriesSelect?.map((item, index) => (
                <Option key={`serie-${index}`} value={item?.idSerie}>
                  {item?.idSeriePadre && <SubnodeOutlined/>} {item?.codigo} -{" "}
                  {item?.titulo}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} className={"header-expediente"}>
          <Form.Item
            name='fecha'
            label=''
          >
            <RangePicker
              format='DD-MM-YYYY'
              placeholder={['Fecha Apertura Inicio', 'Fecha Apertura Límite']}
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} className={"header-expediente"}>
          <Form.Item
            name='ano'
            label=''
          >
            <DatePicker
              picker="year"
              format='YYYY'
              placeholder='Seleccione un Año...'
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12}>
          <Space
            justify='space-between'
          >
            <Tooltip title="Buscar">
              <Button
                disabled={disabledButtons}
                icon={<SearchOutlined/>}
                loading={loading}
                onClick={onSearch}
                // style={{ marginTop: '1.8rem' }}
                type="primary"
              >
                Buscar
              </Button>
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                disabled={disabledButtons}
                icon={<ClearOutlined/>}
                onClick={onClean}
                // style={{ marginTop: '1.8rem' }}
                type="default"
              >
                Limpiar
              </Button>
            </Tooltip>
            <Tooltip title="Importar a Excel">
              <Button
                disabled={disabledButtons}
                icon={<FileExcelOutlined/>}
                onClick={onExcel}
                style={{
                  background: "#1f6e43",
                  color: "#ffffff"
                }}
                type="default"
              >
                Inventario
              </Button>
            </Tooltip>
            <Tooltip title="Importar a PDF">
              <Button
                disabled={disabledButtons}
                icon={<FilePdfOutlined/>}
                onClick={descargarPdf}
                style={{
                  background: "#ce0000",
                  color: "#ffffff"
                }}
                type="default"
              >
                Inventario
              </Button>
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}