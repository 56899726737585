import React, { useEffect, useState } from "react";
import { Col, Row, Form, Modal, Input, Select, Button, message, InputNumber, DatePicker, Divider, Tree } from "antd";
import { EyeTwoTone, TeamOutlined, SaveOutlined, ArrowLeftOutlined, CloseCircleOutlined, EyeInvisibleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import { ViewLoading } from "../../components";
import { DefaultLayout } from "../../components/layouts";
import { useAuth, useModel, useModels, useQuery } from "../../hooks";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { respuestas, roles } from "../../utilities";

const UsuarioForm = () => {
  const titulo = "Usuarios";
  const [form] = Form.useForm();
  const history = useHistory();
  const { user } = useAuth();

  const query = useQuery();
  const id = query.get("id");
  const {Option} = Select;
  const editing = !!id;
  const {confirm} = Modal;

  const [checkedList, setCheckedList] = useState([]);
  const [subAreasUsuario, setSubAreasUsuario] = useState([]);

  const [listaPermisos, setListaPermisos] = useState([]);
  const [arbolPermisos, setArbolPermisos] = useState([]);
  const [ape, setApe] = useState([]); // Arbol de permisos expandido

  // Usuario
  const {model, modelLoading} = useModel({
    name: "usuario",
    expand: "permisos, areas, subFondoAreas",
    id: id,
  });

  // Cargo
  const [cargos, cargosLoading] = useModels({
    name: "cargo",
    expand: "area",
    ordenar: "nombre",
    limite: -1
  });

  const [permisoModulo] = useModels({
    name: "permiso-modulo",
    limite: -1,
    expand: 'permisos'
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/gestion-general/usuarios/",
      icon: <TeamOutlined/>,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? `/gestion-general/usuarios/editar?id=${id}` : "/gestion-general/usuarios/nuevo",
      icon: <TeamOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push("/gestion-general/usuarios/"),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: "primary"},
  };

  const reglasCurp = [
    {
      validator: (_, value) =>
        value && value.length >= 18 && value.length <= 18
          ? Promise.resolve()
          : Promise.reject(new Error("el CURP es de 18 caracteres")),
    },
  ];

  const reglasRfc = [
    {
      validator: (_, value) =>
        value && value.length >= 13 && value.length <= 13
          ? Promise.resolve()
          : Promise.reject(new Error("el RFC es de 13 caracteres")),
    },
  ];

  const reglasCorreo = [
    {
      validator: (_, value) =>
        value && value.includes("@")
          ? Promise.resolve()
          : Promise.reject(new Error("debe de ser un Correo Electrónico")),
    },
  ];

  const eliminarArea = (item) => {
    confirm({
      title: `¿Estás seguro de eliminar registro ${item?.titulo}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: 'Si, Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          const body = { 
            idSubFondoArea: item?.idSubFondoArea,
            idUsuario: model?.idUsuario
          }
          const res = await StatusResponse.delete('usuario/eliminar-sub-fondo-area', body);
          respuestas(res);

          if(res.status === 200) {
            const arr = [...subAreasUsuario];
            const objWithIdIndex = arr.findIndex((obj) => obj?.idSubFondoArea === item?.idSubFondoArea);
            if (objWithIdIndex > -1) {
              arr.splice(objWithIdIndex, 1);
            }
            setSubAreasUsuario(arr);
          }


        } catch (error) {
          console.log('Error al eliminar: ', error);
        }
      },
      onCancel() {
      },
    });
  };

  const onFinish = async (values) => {

    const { telefono, celular, extension } = values;

    try {
      const body = {
        ...values,
        idUsuario: id,
        telefono: telefono ? `${telefono}` : null,
        celular: celular ? `${celular}` : null,
        extension: extension ? `${extension}` : null,
        permisos: checkedList
      };
 
      const res = await StatusResponse.post("usuario/guardar", body);
      respuestas(res);
      if (res?.status === 200) {
        history.push("/gestion-general/usuarios");
      }
      
    } catch (e) {
      console.log("Error al guardar: ", e);
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning("Error al guardar: datos incompletos.");
    console.log(values, errorFields, outOfDate);
  };

  const onCheckedTree = (checkedList, info) => {
    checkedList = checkedList
      .map(item => { 
        const val = parseInt(item.replace("p-", ""));
        return !isNaN(val) ? val : null;
      })
      .filter(Number);

    let _checkedList = [];
    for(let i = 0, l = listaPermisos.length; i < l; i++) {
      const _permiso = listaPermisos[i];
      if(checkedList.includes(_permiso["id"])) {
        _checkedList.push(_permiso["id"]);
      }
    }

    setCheckedList(_checkedList);
  }

  // Setear teléfono
  useEffect(() => {
    let mounted = true;
    if(mounted) {
      form.setFieldsValue({
        telefono: model?.telefono ? model?.telefono : "6622131543",
      });
    }

    return () => mounted = false;
  }, [form, model?.telefono]);

  // Si esta editando
  useEffect(() => {
    let mounted = true;
    if (mounted && editing && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        fechaIngreso: model?.fechaIngreso ? moment(model?.fechaIngreso) : null,
      });
      setSubAreasUsuario(model.subFondoAreas);
      setCheckedList(model?.permisos.map(i => i.idPermiso))
    }
    return () => mounted = false;
  }, [editing, form, model, modelLoading]);

  useEffect(() => {
    let mounted = true;
    if(!mounted && !(permisoModulo && permisoModulo.length)) {
      return;
    }

    let _arbolPermisos = [];
    let _listaPermisos = [];
    let _ape = [];
    for(let i = 0, l = permisoModulo.length; i < l; i++) {
      let _permisos = permisoModulo[i]["permisos"];
      let _permisosModulo = [];
      for(let j = 0, k = _permisos.length; j < k; j++) {
        const nPermiso = {
          title: _permisos[j]["permiso"],
          id: _permisos[j]["idPermiso"],
          selectable: false,
          key: `p-${_permisos[j]["idPermiso"]}`,
        };
        _listaPermisos.push(nPermiso);
        _permisosModulo.push(nPermiso);
      }

      const llave = `m-${permisoModulo[i]["idPermisoModulo"]}`;
      _ape.push(llave);
      _arbolPermisos.push({
        title: permisoModulo[i]["nombre"],
        key: llave,
        id: permisoModulo[i]["idPermisoModulo"],
        children: _permisosModulo
      });
    }

    setApe(_ape);
    setListaPermisos(_listaPermisos);
    setArbolPermisos(_arbolPermisos);

    return () => mounted = false;
  }, [permisoModulo]);

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* Numero de empleado | Fecha Ingreso  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item label="Número de Empleado" name="numeroEmpleado">
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item label="Fecha de ingreso" name="fechaIngreso">
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Nombre | Apellidos | */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input size="large" autoComplete="off" />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Apellido Paterno"
              name="apellidoPaterno"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Apellido Materno"
              name="apellidoMaterno"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item label="RFC" name="rfc" rules={reglasRfc}>
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
                minLength={13}
                maxLength={13}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item label="CURP" name="curp" rules={reglasCurp}>
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
                caracteres="[A-Z0-9]"
                minLength={18}
                maxLength={18}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Correo  | Teléfono  Cargo  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Correo Electrónico"
              name="correo"
              autoComplete="off"
              autoCorrect="off"
              type="new-password"
              rules={reglasCorreo}
            >
              <Input
                size="large"
                style={{ width: "100%" }}
                placeholder="Introduce tu Correo electrónico"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Contraseña"
              name="clave"
              rules={[{ required: !editing, message: "Requerido" }]}
            >
              <Input.Password
                size="large"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Repetir contraseña"
              name="clave2"
              dependencies={["clave"]}
              rules={[
                {
                  required: !editing,
                  message: "Confirma tu contraseña.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("clave") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Las contraseñas no coinciden.")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Teléfono"
              name="telefono"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <InputNumber
                size="large"
                minLength={10}
                maxLength={10}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item label="Celular" name="celular">
              <InputNumber
                size="large"
                minLength={10}
                maxLength={10}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item label="Extensión" name="extension">
              <InputNumber
                size="large"
                minLength={1}
                maxLength={10}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Cargo"
              name="idCargo"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Select
                size="large"
                loading={cargosLoading}
                disabled={cargosLoading}
                placeholder="Selecciona una institución"
              >
                {cargos.map((item, _) => (
                  <Option key={item.idCargo} value={item.idCargo}>
                    {item.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Form.Item
              label="Rol"
              name="rol"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Select size="large" placeholder="Selecciona un rol">
                {roles.map((item) => (
                  <Option key={item.key} value={item?.key}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        {/* Permisos  */}
        <Row gutter={10} hidden={!user?.permisoExtra?.includes(18)}>
          <Col span={12}>
            <Title level={3}>Permisos</Title>
            <Divider />
            <Tree
              checkable
              defaultExpandedKeys={ape}
              checkedKeys={checkedList.map(i => `p-${i}`)}
              onCheck={onCheckedTree}
              treeData={arbolPermisos}
            />
          </Col>

          <Col span={12}>
            <Title level={3}>Áreas de Archivo</Title>
            <Divider />
            {subAreasUsuario.length > 0 &&
              subAreasUsuario.map((i) => (
                <Row key={i?.idSubFondoArea}>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <CloseCircleOutlined
                      onClick={() => eliminarArea(i)}
                      style={{ color: "darkRed", fontSize: "20px" }}
                    />
                  </Col>
                  <Col span={20}>{i?.titulo}</Col>
                </Row>
              ))}
          </Col>
        </Row>

        <Divider
          orientation="center"
          style={{ color: "#333", fontWeight: "bold" }}
        />

        <Row gutter={10}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 5, offset: 19 }}
            lg={{ span: 5, offset: 19 }}
            xxl={{ span: 5, offset: 19 }}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default UsuarioForm;