import React, {useEffect, useState, useMemo} from 'react';
import {
  Col, Row, Button, Form, Input, message, Select, Table, Space,
  Modal, Divider, DatePicker, InputNumber, Tooltip, Typography, notification
} from 'antd';
import {
  ArrowLeftOutlined, ContactsOutlined, SaveOutlined,
  FileAddOutlined, UploadOutlined,
  PlusOutlined, DeleteOutlined, MinusCircleOutlined
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {DefaultLayout} from '../../components/layouts';
import {useQuery, useModels, useModel, useApp, useAuth} from "../../hooks";
import {
  ViewLoading,
  Upload as UploadComponent,
  Select as SelectComponent
} from '../../components';
import StatusResponse from '../../services/statusResponse';
import {InformacionArchivos, Respuestas} from '../../utilities';
import moment from 'moment';
import {UbicacionForm} from "../gestion-general/ubicacion";

const {REACT_APP_API_URL: baseUrl} = process.env;

const {TextArea} = Input;
const {Option} = Select;
const {Title} = Typography;

const styles = {
  extra: {
    fontSize: 15,
    color: '#333',
    fontWeight: 'bold'
  }
}

const ArchivoTramite = () => {

  const titulo = 'Expedientes en trámite';
  const [form] = Form.useForm();
  const [formUbicacion] = Form.useForm();
  const history = useHistory();
  const _baseUrl = baseUrl.replace("v1/", "")
  const {token} = useApp();
  const {user} = useAuth();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false);
  const [subSeries, setSubSeries] = useState([]);
  const [vigenciaAt, setVigenciaAt] = useState(null);
  const [vigenciaAC, setVigenciaAC] = useState(null);
  const [vigenciaT, setVigenciaT] = useState(null);
  const [selectSeries, setSelectSeries] = useState([]);
  const [listaArchivos, setListaArchivos] = useState([]);
  const [listaArchivosReservada, setListaArchivosReservada] = useState([]);
  const [opcionesValoracion, setOpcionesValoracion] = useState([]);
  const [ubicacionesSelect, setUbicacionesSelect] = useState([]);
  const [modalUbicacion, setModalUbicacion] = useState(false);
  const [loadingUbicacion, setUbicacionLoading] = useState(false);
  const [subFondoAreasAselect, setSubFondoAreasAselect] = useState([]);
  const [appendUbicacion, setAppendUbicacion] = useState([]);
  const [selectedSubFondoArea, setSelectedSubFondoArea] = useState({});

  const [estatus, setEstatus] = useState(null);
  const [creador, setCreador] = useState(null);
  const [clasificacionInformacion, setClasificacionInformacion] = useState("");

  const [requestModel, setRequestModel] = useState({});
  const [requestFondos, setRequestFondos] = useState({});
  const [requestSecciones, setSubRequestSecciones] = useState({});
  const [requestUbicaciones, setSubRequestUbicaciones] = useState({});
  const [requestUbicacionesEliminar, setSubRequestUbicacionesEliminar] = useState({});
  const [requestUbicacionesNueva, setSubRequestUbicacionesNueva] = useState({});

  const {model, modelLoading} = useModel(requestModel);
  const [fondos, fondosLoading] = useModels(requestFondos);
  const [ubicaciones, , , , ubicacionesRecargar] = useModels(requestUbicaciones);
  const [ubicacionesEliminar, , , , ubicacionesRecargarEliminar] = useModels(requestUbicacionesEliminar);
  const [ubicacionesNueva, , , , ubicacionesRecargarNueva] = useModels(requestUbicacionesNueva);

  const [periodoReserva, setPeriodoReserva] = useState(0);
  const [fechaDesclasificacion, setFechaDesclasificacion] = useState("")
  const [articulos, setArticulos] = useState([])
  const [padres, setPadres] = useState([])
  const [modalBorrarUbicacion, setModalBorrarUbicacion] = useState(false)
  const [expedientesConUbicacion, setExpedientesConUbicacion] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [idUbicacionEliminar, setIdUbicacionEliminar] = useState(null)
  const [idUbicacionNueva, setIdUbicacionNueva] = useState(null)
  const [mostrarVS, setMostrarVS] = useState(true)
  const [selectValSec, setSelectValSec] = useState([]);

  const [infoArchivos, setInfoArchivos] = useState([]);

  const modelParams = useMemo(() => ({
    name: 'expediente',
    id: id,
    expand: 'archivos,archivosReservado,subfondoarea,serie,ubicacion'
  }), [id]);

  const fondosParams = useMemo(() => ({
    name: 'fondo',
    ordenar: 'idFondo.desc',
    limite: -1,
  }), []);


  const seccionesParams = useMemo(() => ({
    name: 'seccion',
    expand: 'series',
    ordenar: 'orden-asc',
  }), []);

  const ubicacionesExtraParams = useMemo(() => ({
    tipoExpediente: "TRAMITE",
    idUsuario: user?.idUsuario,
    idSubFondoArea: form.getFieldValue('idSubFondoArea'),
  }), [user?.idUsuario]);

  const ubicacionesParams = useMemo(() => ({
    name: 'ubicacion',
    ordenar: 'titulo',
    extraParams: ubicacionesExtraParams
  }), [user?.idUsuario]);

  const ubicacionesEliminarParams = useMemo(() => ({
    name: 'ubicacion',
    ordenar: 'titulo',
    extraParams: ubicacionesExtraParams
  }), [user?.idUsuario]);

  const ubicacionesNuevaParams = useMemo(() => ({
    name: 'ubicacion',
    ordenar: 'titulo',
    extraParams: ubicacionesExtraParams
  }), [user?.idUsuario]);

  const columnsBorrarUbicaciones = [
    {title: 'Número de Expediente', key: 'Folio', dataIndex: 'folio'}
  ]

  const clasificaciones = [
    {key: 'r', value: 'R', label: 'Reservada'},
    {key: 'c', value: 'C', label: 'Confidencial'},
    {key: 'p', value: 'P', label: 'Pública'},
  ];

  const breadcrumbItems = [
    {
      name: "Archivo trámite",
      to: '/archivo/tramite/',
      icon: <ContactsOutlined/>,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? `/archivo/tramite/editar?id=${id}` : '/archivo/tramite/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/archivo/tramite/'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const buscarUbicaciones = async (value) => {
    if (value?.length > 0) {
      await ubicacionesRecargar(true, {
        buscar: value,
        tipoExpediente: "TRAMITE",
        idSubFondoArea: form.getFieldValue('idSubFondoArea'),
      })
    }
  };

  const buscarUbicacionesEliminar = async (value) => {
    if (value?.length > 0) {
      await ubicacionesRecargarEliminar(true, {
        buscar: value
      })
    }
  };

  const buscarUbicacionesNueva = async (value) => {
    if (value?.length > 0) {
      await ubicacionesRecargarNueva(true, {
        buscar: value
      })
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let body = {
        ...values,
        idExpediente: id,
        tipo: 2,
        idArea: 1,
        fechaApertura: values?.fechaApertura,
        fechaCierre: values?.fechaCierre,
        fechaClasificacion: values?.fechaClasificacion,
        fechaDesclasificacion: fechaDesclasificacion ? fechaDesclasificacion?._d : null,
        vigenciaAt: vigenciaAt,
        vigenciaAC: vigenciaAC,
        vigenciaT: vigenciaT,
        legajos: values?.legajos,
        idCreador: creador,
        estatus: estatus,
        idSubFondoArea: values.idSubFondoArea || user?.subFondoAreas[0]?.idSubFondoArea
      }

      if (values?.legajosExpediente?.length === 0) {
        notification.error({
          message: 'Atención',
          description: "Debes seleccionar por lo menos un Legajo al expediente",
          placement: 'bottomRight'
        });
        return;
      }

      if (values.valoracionPrimaria)
        body.valoracionPrimaria = values.valoracionPrimaria.toString();
      if (values.valoracionSecundaria)
        body.valoracionSecundaria = values.valoracionSecundaria.toString();
      if (values.fundamentoLegal)
        body.fundamentoLegal = values.fundamentoLegal.toString();
      if (values.tipoExpediente)
        body.tipoExpediente = values.tipoExpediente.toString();
      listaArchivos.concat(listaArchivosReservada)

      body.archivos = infoArchivos;

      delete body.add; // no se usa este campo.

      if (clasificacionInformacion === "R" && listaArchivosReservada.length === 0) {

        notification.error({
          message: 'Atención',
          description: 'Al ser expediente Reservado falta subir el informe.',
          placement: 'bottomRight',
        });
        return;
      }

      const res = await StatusResponse.post('expediente/guardar', body);
      if (Respuestas(res)) {
        history.push('/archivo/tramite/');
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    Respuestas('error', 'Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  const onChangeUpload = (info) => {
    setListaArchivos(info.fileList);
    InformacionArchivos(info?.file?.response?.detalle, infoArchivos, setInfoArchivos, 'principal', token)
    Respuestas(info);
  };

  const onChangeUploadReservada = (info) => {
    setListaArchivosReservada(info.fileList);
    InformacionArchivos(info?.file?.response?.detalle, infoArchivos, setInfoArchivos, 'reservado', token)
    Respuestas(info);
  };

  const onChangeSeccion = (val, item) => {
    const series = item?.series;
    setSelectSeries(
      series?.map(s => ({
        ...s,
        codigoNumeral: Number(String(s?.codigo).split('.')[1]),
      }))
    );
    setSubSeries([]);

    form.setFieldsValue({
      idSerie: null,
      idSubSerie: null,
    });
  };

  const arrValoracionPrimaria = (arr) => {
    if (arr?.length < 1) {
      return "Error!"
    }
    const resp = [];
    for (let i = 0, l = arr?.length; i < l; i++) {
      if (arr[i] === "A") {
        resp.push({
          key: "a",
          value: "A",
          name: "Administrativo",
        });
      } else if (arr[i] === "L") {
        resp.push({
          key: "l",
          value: "L",
          name: "Legal",
        });
      } else if (arr[i] === "CF") {
        resp.push({
          key: "cf",
          value: "CF",
          name: "Fiscal o Contable",
        });
      }
    }

    return resp
  };

  const onChangeSerie = (val) => {

    form.setFieldsValue({
      idSubSerie: null,
      valoracionPrimaria: null,
    });
    setOpcionesValoracion([])
    setSubSeries([]);

    if (!val) {
      return
    }

    const serieSelected = selectSeries.find((s) => s.idSerie === val);

    setVigenciaAt(serieSelected?.vigenciaAt);
    setVigenciaAC(serieSelected?.vigenciaAC);
    setVigenciaT(serieSelected?.vigenciaT);

    if (serieSelected?.valoracionSecundaria !== null && serieSelected?.valoracionSecundaria !== "") {
      setMostrarVS(serieSelected.destinoFinal === 'D' ? true : false);
      console.log(serieSelected?.valoracionSecundaria)
      let valSec = serieSelected?.valoracionSecundaria?.split(",");
      setSelectValSec(valSec?.map((item) => {
        if (item === "INFO")
          return {key: "INFO", value: "INFO", label: "Informativo"}
        if (item === "ET")
          return {key: "ET", value: "ET", label: "Evidencial / Testimonial"}
      }))
    }

    const vp = serieSelected?.valoracionPrimaria?.split(",");
    const _vp = arrValoracionPrimaria(vp);
    setOpcionesValoracion(_vp);

    let _valoracionSecundaria = [];

    if (serieSelected?.valoracionSecundaria !== null || serieSelected?.valoracionSecundaria !== "")
      _valoracionSecundaria = serieSelected?.valoracionSecundaria?.split(",")

    form.setFieldsValue({
      valoracionPrimaria: serieSelected?.valoracionPrimaria?.split(","),
      valoracionSecundaria: _valoracionSecundaria,
    });

    const subSeries = serieSelected.series;
    if (subSeries.length < 1) {
      return;
    }

    setSubSeries(subSeries);

    let idSubfondoArea = 0;
    if (form.getFieldValue("idSubFondoArea")) {
      idSubfondoArea = form.getFieldValue("idSubFondoArea");
    }

    if (idSubfondoArea === 0) {
      message.error({
        content: "Debes de seleccionar un subfondo.",
      });
      return;
    }
  };

  const onChangeSubSerie = (val) => {

    if (!val) {
      return
    }

    let serie = subSeries.find(
      (item) => item.idSerie === val
    );

    setVigenciaAt(serie?.vigenciaAt);
    setVigenciaAC(serie?.vigenciaAC);
    setVigenciaT(serie?.vigenciaT);

    const vp = serie?.valoracionPrimaria?.split(",");
    const _vp = arrValoracionPrimaria(vp);

    let idSubfondoArea = 0;
    if (form.getFieldValue("idSubFondoArea")) {
      idSubfondoArea = form.getFieldValue("idSubFondoArea");
    }

    if (idSubfondoArea === 0) {
      message.error({
        content: "debes de seleccionar un subfondo",
      });
      return;
    }

    form.setFieldsValue({
      valoracionPrimaria: _vp[0]?.value
    });

    setOpcionesValoracion(_vp);
  };

  const guardarUbicacion = async (values) => {
    try {
      setUbicacionLoading(true);

      const _idSubFondoArea = form.getFieldValue('idSubFondoArea');

      if (!_idSubFondoArea) {
        message.info("Debes de seleccionar una Área")
        return
      }

      let body = {
        ...values,
        _idSubFondoArea,
      }

      body.tipoExpediente = "TRAMITE";

      const res = await StatusResponse.post('ubicacion/guardar', body);

      if (Respuestas(res)) {
        const arr = [...ubicaciones];
        const item = res.response.detalle;
        arr.push(item);
        form.setFieldsValue({
          idUbicacion: item.idUbicacion
        })
        setUbicacionesSelect(arr.sort((a, b) => a.titulo > b.titulo ? -1 : 1));
        setModalUbicacion(false);
      }

    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setUbicacionLoading(false);
    }
  };

  useEffect(() => {
    if (ubicaciones.length > 0) {
      setUbicacionesSelect(ubicaciones)
    }
  }, [ubicaciones]);


  // Setear archivos (cuando tiene archivos y si esta editando)
  useEffect(() => {
    if (editing && model && !modelLoading) {
      let _listaArchivos = model?.archivos
        ?.map((item) => {
          InformacionArchivos(item, infoArchivos, setInfoArchivos, 'principal', token)
          return {
            idMedia: item?.idMedia,
            name: item?.descripcion,
            uid: item?.uuid,
            url: `${_baseUrl}/v1/descarga/documento?uuid=${item?.uuid}&access-token=${token}&t=exp`,
            status: "done",
            thumbUrl: `${item?.ruta}`,

          };
        })
        .filter(function (x) {
          return x !== undefined;
        });

      let _listaArchivosReservada = model?.archivosReservado
        ?.map((item) => {
          InformacionArchivos(item, infoArchivos, setInfoArchivos, 'reservado', token)
          return {
            idMedia: item?.idMedia,
            name: item?.descripcion,
            uid: item?.uuid,
            url: `${_baseUrl}/v1/descarga/documento?uuid=${item?.uuid}&access-token=${token}&t=exp`,
            status: "done",
            thumbUrl: `${item?.ruta}`,
          };
        })
        .filter(function (x) {
          return x !== undefined;
        });

      setListaArchivos(_listaArchivos !== undefined ? _listaArchivos : []);
      setListaArchivosReservada(_listaArchivosReservada !== undefined ? _listaArchivosReservada : []);
    }
  }, [_baseUrl, editing, model, modelLoading, token]);

  // Guardar ubicaciones y setear en el form
  useEffect(() => {
    if (editing && model) {
      const idUbicacion = model?.idUbicacion;
      const arr = ubicaciones;
      const existe = arr.find(u => u.idUbicacion === idUbicacion)

      if (!existe && model?.ubicacion) {
        arr.push(model?.ubicacion)

        setUbicacionesSelect(arr)
      }
    }
  }, [editing, model, ubicaciones]);

  // Setar campos cuando es nuevo.
  useEffect(() => {
    if (!editing) {
      setSelectedSubFondoArea(subFondoAreasAselect[0])
      form.setFieldsValue({
        //numeroHojas: 1,
        idFondo: fondos[0]?.idFondo,
        clasificacionInformacion: 'P',
        idSubFondoArea: subFondoAreasAselect[0]?.idSubFondoArea
      });
      setClasificacionInformacion("P");
    }
  }, [editing, fondos, form, subFondoAreasAselect]);

  // Setear campos cuando es editar.
  useEffect(() => {
    if (model?.estatus === 'ACO' || model?.estatus === 'HIS' || model?.estatus === 'BAJ') {
      history.push(`/archivo/concentracion/detalle?id=${model.idExpediente}`)
    }

    if (editing && model && !modelLoading) {

      form.setFieldsValue({
        ...model,
        fechaApertura: moment(model?.fechaApertura),
        fechaCierre: model?.fechaCierre ? moment(model?.fechaCierre) : null,
        fechaArchivoTramite: moment(model?.fechaArchivoTramite),
        fechaClasificacion: moment(model?.fechaClasificacion),
      });

      if (model?.fechaDesclasificacion) {
        setFechaDesclasificacion(moment(model?.fechaDesclasificacion))
      }

      if (model?.periodoReserva) {
        setPeriodoReserva(Number(model.periodoReserva));
      }

      setMostrarVS(model?.serie?.destinoFinal === 'D' ? true : false);
      if (model?.serie?.valoracionSecundaria !== null && model?.serie?.valoracionSecundaria !== '')
        setSelectValSec(model?.serie?.valoracionSecundaria?.split(",").map((item) => {
          if (item === "INFO")
            return {key: "INFO", value: "INFO", label: "Informativo"}
          if (item === "ET")
            return {key: "ET", value: "ET", label: "Evidencial / Testimonial"}
        }))


      if (model?.valoracionPrimaria) {
        const valoracionPrimaria = model?.valoracionPrimaria?.split(',') || []
        form.setFieldsValue({valoracionPrimaria: valoracionPrimaria})
      } else {
        form.setFieldsValue({valoracionPrimaria: []})
      }

      if (model?.valoracionSecundaria) {
        const valoracionSecundaria = model?.valoracionSecundaria?.split(',') || []
        form.setFieldsValue({valoracionSecundaria: valoracionSecundaria})
      } else {
        form.setFieldsValue({valoracionSecundaria: []})
      }

      if (model?.tipoExpediente) {
        const _tipoExpediente = model?.tipoExpediente?.split(',') || []
        form.setFieldsValue({tipoExpediente: _tipoExpediente})
      } else {
        form.setFieldsValue({tipoExpediente: []})
      }


      if (model?.fundamentoLegal) {
        const fundamentoLegal = model?.fundamentoLegal?.split(',') ? model?.fundamentoLegal.split(',') : undefined
        form.setFieldsValue({fundamentoLegal: fundamentoLegal.map(m => Number(m))})
      } else {
        form.setFieldsValue({fundamentoLegal: []})
      }

      console.log(model)

      const serieSelected = model?.serie;
      const vp = serieSelected?.valoracionPrimaria?.split(",");
      const _vp = arrValoracionPrimaria(vp);

      setOpcionesValoracion(_vp);
      setClasificacionInformacion(model?.clasificacionInformacion);
      setVigenciaAt(model?.vigenciaAt);
      setVigenciaAC(model?.vigenciaAC);
      setVigenciaT(model?.vigenciaT);
      setCreador(model?.idCreador);
      setEstatus(model?.estatus);
    }
  }, [editing, form, model, modelLoading]);

  useEffect(() => {
    buscarUbicaciones()
  }, [buscarUbicaciones, subFondoAreasAselect])

  useEffect(() => {
    if (clasificacionInformacion === 'P')
      form.setFieldsValue({"fechaClasificacion": moment()})
    else
      form.setFieldsValue({"fechaClasificacion": null})
  }, [clasificacionInformacion, form])

  // Peticiones
  useEffect(() => {
    setRequestModel(modelParams);
    return () => setRequestModel({});
  }, [modelParams]);

  useEffect(() => {
    setRequestFondos(fondosParams);
    return () => setRequestFondos({});
  }, [fondosParams]);

  useEffect(() => {
    setSubRequestSecciones(seccionesParams);
    return () => setSubRequestSecciones({});
  }, [seccionesParams]);

  useEffect(() => {
    setSubRequestUbicaciones(ubicacionesParams);
    return () => setSubRequestUbicaciones({});
  }, [ubicacionesParams]);

  useEffect(() => {
    setSubRequestUbicacionesEliminar(ubicacionesEliminarParams);
    return () => setSubRequestUbicacionesEliminar({});
  }, [ubicacionesEliminarParams]);

  useEffect(() => {
    setSubRequestUbicacionesNueva(ubicacionesNuevaParams);
    return () => setSubRequestUbicacionesNueva({});
  }, [ubicacionesNuevaParams]);

  useEffect(() => {
    const getArticulos = async () => {
      const {
        status,
        resultado
      } = await StatusResponse.get(`articulo?ordenar=idArticuloPadre-asc,orden-asc&expand=padre`)
      if (status === 200) {
        setArticulos(resultado?.filter(f => f?.idArticuloPadre !== null));
        setPadres(resultado?.filter(f => f?.idArticuloPadre === null))
      }
    }
    getArticulos();
    return () => {
      setArticulos([]);
    }
  }, [])

  useEffect(() => {
    const getExpedientes = async () => {
      const {status, resultado} = await StatusResponse.get(`expediente?idUbicacion=${idUbicacionEliminar}`)
      if (status === 200) {
        setExpedientesConUbicacion(resultado)
      }
    }

    if (idUbicacionEliminar) {
      getExpedientes();
    }

    return () => {
      setExpedientesConUbicacion([])
    }
  }, [idUbicacionEliminar])

  useEffect(() => {
    setSelectedSubFondoArea(user?.subFondoAreas[0]);
    console.log(user?.subFondoAreas)
  }, [user])

  const borrarUbicacion = () => {
    let body = {
      idUbicacionEliminar,
      idUbicacionNueva,
      expedientes: selectedRowKeys?.length > 0 ? selectedRowKeys : [],
    }

    Modal.confirm({
      title: 'Eliminar Ubicación',
      content: '¿Estás seguro de realizar esta acción?',
      okText: 'Sí',
      cancelText: 'No',
      onOk: async () => {
        try {
          const res = await StatusResponse.post(`expediente/eliminar-ubicacion`, body);
          if (res?.status === 200) {
            window.location.reload();
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  const hasSelected = selectedRowKeys?.length > 0

  if (modelLoading) return (<ViewLoading/>);

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Modal
        title={modalBorrarUbicacion ? "Eliminar Ubicación" : "Agregar Ubicación"}
        open={modalUbicacion}
        onCancel={() => {
          setModalUbicacion(false)
          setExpedientesConUbicacion([])
        }}
        footer={null}
        width={800}
      >
        {
          modalBorrarUbicacion ? (
            <>
              <Form
                layout='vertical'
                onFinish={borrarUbicacion}
              >
                <Form.Item
                  label="Ubicación que desea eliminar"
                  style={{marginBottom: '0px'}}
                >
                  <Select
                    optionFilterProp='children'
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option?.children?.toLowerCase().includes(input?.toLowerCase())
                    }
                    listItemHeight={10}
                    listHeight={250}
                    placeholder="Seleccionar una Ubicación"
                    onSearch={buscarUbicacionesEliminar}
                    style={{width: '100%'}}
                    options={
                      ubicacionesEliminar &&
                      ubicacionesEliminar?.length > 0 ?
                        ubicacionesEliminar.map(u => ({
                          label: u?.titulo,
                          value: u?.idUbicacion,
                          key: u?.idUbicacion
                        }))
                        : []
                    }
                    onSelect={val => {
                      if (val) {
                        setIdUbicacionEliminar(val);
                      }
                    }}
                  />
                </Form.Item>
                <br/>
                <p>
                  {hasSelected ? `${selectedRowKeys.length} expedientes seleccionados` : ''}
                </p>
                <Table
                  columns={columnsBorrarUbicaciones}
                  dataSource={expedientesConUbicacion}
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowKeys ? selectedRowKeys : [],
                    onChange: (selectedRowKeys) => {
                      setSelectedRowKeys(selectedRowKeys);
                    }
                  }}
                  rowKey='idExpediente'
                />
                <br/>
                <Form.Item
                  label="Nueva Ubicación a la que desea asignar los expedientes seleccionados"
                  style={{marginBottom: '0px'}}
                >
                  <Select
                    optionFilterProp='children'
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option?.children?.toLowerCase().includes(input?.toLowerCase())
                    }
                    listItemHeight={10}
                    listHeight={250}
                    placeholder="Seleccionar una Ubicación"
                    onSearch={buscarUbicacionesNueva}
                    style={{width: '100%'}}
                    options={
                      ubicacionesNueva &&
                      ubicacionesNueva?.length > 0 ?
                        ubicacionesNueva.map(u => ({
                          label: u?.titulo,
                          value: u?.idUbicacion,
                          key: u?.idUbicacion
                        }))
                        : []
                    }
                    onSelect={val => {
                      if (val) {
                        setIdUbicacionNueva(val);
                      }
                    }}
                  />
                </Form.Item>
                <br/>
                <Row
                  gutter={10}
                  justify='end'
                >
                  <Space>
                    <Form.Item>
                      <Button
                        type="default"
                        size="large"
                        onClick={() => {
                          setModalUbicacion(false);
                          setExpedientesConUbicacion([])
                        }}
                      >
                        Cancelar
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={loadingUbicacion}
                        icon={<SaveOutlined/>}
                      >
                        Guardar
                      </Button>
                    </Form.Item>
                  </Space>
                </Row>
              </Form>
            </>
          ) : (
            <Form
              form={formUbicacion}
              name="form"
              layout="vertical"
              onFinish={guardarUbicacion}
            >
              <Row gutter={10}>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    label="Ubicación"
                    name="titulo"
                    rules={[{required: true, message: "Requerido"}]}
                  >
                    <Input placeholder="Ingresa una Ubicación" autoComplete="off"/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    label="Descripción"
                    name="descripcion"
                    rules={[{required: true, message: "Requerido"}]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Escribir la descripción"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      block
                      size="large"
                      htmlType="submit"
                      loading={loadingUbicacion}
                      icon={<SaveOutlined/>}
                    >
                      Guardar
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="default"
                      block
                      size="large"
                      onClick={() => {
                        setModalUbicacion(false);
                        setExpedientesConUbicacion([])
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )
        }
      </Modal>
      <Form
        autoComplete="off"
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {editing && (
          <Row gutter={10}>
            <Col span={24}>
              <Title level={4}>Folio: {model?.folio}</Title>
            </Col>
          </Row>
        )}
        {/* Fondo | Area */}
        <Row gutter={10}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Fondo" name="idFondo" hasFeedback>
              <Select
                size="large"
                loading={fondosLoading}
                disabled={fondosLoading}
                placeholder="Seleccionar un Fondo"
              >
                {fondos.map((item, index) => (
                  <Option key={index} value={item.idFondo}>
                    {item.titulo}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Área"
              name="idSubFondoArea"
            >
              <SelectComponent
                modelsParams={{
                  name: user?.permisoExtra?.includes(40) ? 'sub-fondo-area' : 'sub-fondo-area/mias',
                  ordenar: 'titulo',
                  limite: 10,
                }}
                disabled={editing}
                labelProp="titulo"
                size={"large"}
                valueProp="idSubFondoArea"
                allowClear={true}
                optionSelected={user?.subFondoAreas[0]?.idSubFondoArea}
                onChange={(_, item) => setSelectedSubFondoArea(item)}
                placeholder="Seleccionar un Área"
                autoComplete="off"
                append={[model?.subfondoarea, user?.subFondoAreas]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        {/*  Sección | Serie | Sub Serie */}
        <Row gutter={10}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<Tooltip title='Es cada una de las divisiones del fondo documental basada en las atribuciones de
              cada sujeto obligado de conformidad con las disposiciones legales aplicables (FUNCIONES)
                '>Sección</Tooltip>}
              name="idSeccion"
              hasFeedback
              rules={[{required: true, message: "Requerido"}]}
            >
              <SelectComponent
                modelsParams={seccionesParams}
                labelProp="titulo"
                disabled={editing}
                size={"large"}
                onChange={onChangeSeccion}
                placeholder="Seleccionar una Sección"
                valueProp="idSeccion"
                allowClear={true}
                autoComplete="off"
                render={(_, row) => `${row.codigo} - ${row.titulo}`}
                append={[model?.seccion]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<Tooltip title='División de una sección que corresponde al conjunto de documentos producidos en el
               desarrollo de una misma atribución general integrados en expedientes de acuerdo a un asunto, actividad o
               trámite específico (ACTIVIDAD)'>Serie</Tooltip>}
              name="idSerie"
              rules={[
                {
                  required: selectSeries.length > 0 ? true : false,
                  message: "Selecciona una serie.",
                },
              ]}
            >
              {editing ? (
                <h3 style={{marginTop: 5, marginLeft: 10}}>
                  {model?.serie?.codigo} - {model?.serie?.titulo}
                </h3>
              ) : (
                <Select
                  optionFilterProp="children"
                  showSearch={true}
                  allowClear={true}
                  disabled={editing}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  size="large"
                  style={{width: "100%"}}
                  placeholder="Seleccionar una Serie"
                  onChange={(val, opt) => {
                    onChangeSerie(val)
                  }}
                  options={selectSeries
                    ?.filter((seccion) => seccion?.idSeriePadre === null)
                    .sort((a, b) => a?.codigoNumeral < b?.codigoNumeral ? -1 : 1)
                    .map((item, index) => ({
                      label: item?.codigo + " - " + item?.titulo,
                      key: item?.idSerie,
                      value: item?.idSerie
                    }))}
                />
              )}
            </Form.Item>
          </Col>
          {subSeries.length > 0 && (
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Sub Serie"
                name="idSubSerie"
                rules={[
                  {
                    required: subSeries.length > 0 ? true : false,
                    message: "Selecciona una sub serie.",
                  },
                ]}
                hasFeedback
              >
                <Select
                  optionFilterProp="children"
                  showSearch={true}
                  allowClear={true}
                  disabled={editing}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  listItemHeight={10}
                  listHeight={250}
                  size="large"
                  style={{width: "100%"}}
                  placeholder="Seleccionar una Serie"
                  onChange={onChangeSubSerie}
                >
                  {subSeries?.map((item, index) => (
                    <Option key={"serie-" + index} value={item?.idSerie}>
                      {item?.codigo + " - " + item?.titulo}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        {/* Asunto | Descripción del expendiente  */}
        <Row gutter={10}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={<Tooltip
                title='Anotar el nombre del expediente que represente el asunto o tema que trate'>Título</Tooltip>}
              name="titulo">
              <Input size="large" placeholder="Título" autoComplete="off"/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={<Tooltip title='Hacer un breve resumen del contenido del expediente, máximo de tres líneas,
              describiendo el asunto o tema, considerando personas morales, o físicas, lugares, fechas etc.'>
                Descripción del Asunto</Tooltip>}
              name="asunto">
              <Input size="large" placeholder="Asunto" autoComplete="off"/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={<Tooltip title='Anotar cualquier característica o información adicional que se requiera saber de manera directa sobre el expediente.
Ejemplo:
 contiene informes digitales
'>Anotaciones Generales del Expediente</Tooltip>}
              name="descripcion">
              <TextArea
                size="large"
                placeholder="Anotaciones Generales"
                autoComplete="off"
                rows={4}
                autoSize={{minRows: 2, maxRows: 20}}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* Fecha de Apertura | Fecha Cierre */}
        <Row gutter={10}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<Tooltip title='Anotar fecha de apertura del expediente, es decirla fecha del documento que dio
              inicio al asunto o tema'>Fecha Apertura</Tooltip>}
              name="fechaApertura">
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<Tooltip title='Anotar la fecha de cierre del expediente (es decir la fecha en que el documento
              concluyó (de manera definitiva) el asunto o tema'>Fecha Cierre</Tooltip>}
              name="fechaCierre"
              dependencies={["fechaApertura"]}
              rules={[
                {
                  required: false,
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    const fecha1 = getFieldValue("fechaApertura");
                    const fecha2 = value;

                    if (!fecha2) {
                      return Promise.resolve();
                    }

                    if (fecha2.diff(fecha1, 'hours') >= 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("La fecha de Cierre no puede ser menor a le apertura.")
                    )
                  }
                })
              ]}
            >
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        {/* Número de Fojas | Legajos */}
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={[12]}>
              <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                <Title level={5}><Tooltip
                  title={<p>Es el conjunto de documentos que están unidos, deberá asignarle un numero consecutivo
                    dependiendo de la cantidad de legajos que requiera el expediente.<br/><br/>
                    La cantidad total de legajos que se integran en el expediente, por ejemplo: legajo 1 de 3
                  </p>}>Legajos</Tooltip></Title>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Title level={5}><Tooltip title='Cantidad de hojas que contiene el legajo'>Número de
                  Fojas</Tooltip></Title>
              </Col>
              <Col xs={24} sm={24} md={1} lg={1} xl={1}>
              </Col>
            </Row>
            <Form.List
              name="legajosExpediente"
            >
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({key, name, ...restField}) => (
                    <Row gutter={[12]} key={"legajosExpediente-" + key}>
                      <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "legajos"]}
                          rules={[
                            {
                              required: true,
                              message: "Escriba el legajo",
                            },
                          ]}
                        >
                          <Input
                            size='large'
                            placeholder="Legajos"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          {...restField}
                          name={[name, "numeroHojas"]}
                          rules={[
                            {
                              required: true,
                              message: "Número de Fojas",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            size='large'
                            placeholder="Número de Fojas"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                        <MinusCircleOutlined onClick={() => remove(name)}/>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined/>}
                    >
                      Agregar Legajo
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Divider/>
        {/* Valoración primaria | Ubicación del Expiendente | [+] */}
        <Row gutter={10}>
          <Col className="gutter-row" span={7}>
            <Form.Item
              label={<Tooltip title='Seleccionar de la lista  el tipo de valor que tiene la documentación que integra el
              expediente, para determinar su utilidad y asignarle el tiempo y espacio de conservación en los diferentes
              archivos'>Valoración Primaria</Tooltip>}
              name="valoracionPrimaria"
            >
              <Select
                size="large"
                loading={modelLoading}
                disabled={modelLoading}
                mode='multiple'
                placeholder="Seleccionar Valoración primaria"
              >
                {opcionesValoracion.map((item) => (
                  <Option key={item?.key} value={item?.value}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 8, offset: 0}}
            lg={{span: 8, offset: 0}}
            xxl={{span: 8, offset: 0}}
            hidden={mostrarVS}
          >
            <Form.Item
              label={<Tooltip title='Seleccionar de la lista  el tipo de valor que tiene la documentación que integra el
              expediente, para determinar su utilidad y asignarle el tiempo y espacio de conservación en los diferentes
              archivos'>Valoración Secundaria</Tooltip>}
              name="valoracionSecundaria"
            >
              <Select
                mode="multiple"
                size="large"
                options={selectValSec}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={7}>
            <Form.Item
              label="Soporte"
              name="tipoExpediente"
            >
              <Select
                size="large"
                optionFilterProp="children"
                mode="multiple"
                showSearch
                allowClear
                listItemHeight={10}
                listHeight={250}
                style={{width: "100%"}}
                options={[
                  {value: "PO", label: "Papel/Original"},
                  {value: "DE", label: "Digital/Electrónico"},
                  {value: "AU", label: "Audiovisual"}
                ].map(item => ({key: item.value, value: item.value, label: item.label}))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label={<Tooltip title={<>Especificar la ubicación con las siguientes denominaciones:<br/>
                A- archivero<br/>
                C. Cajón 1 (se cuenta de arriba hacia abajo)<br/>
                E- estante<br/>
                CR- credenza<br/>
                L – Librero</>}
              >Signatura Topográfica</Tooltip>}
              name="idUbicacion"
            >
              <SelectComponent
                modelsParams={ubicacionesParams}
                labelProp="titulo"
                size={"large"}
                valueProp="idUbicacion"
                extraParams={ubicacionesExtraParams}
                allowClear={true}
                placeholder="Seleccionar Signatura Topográfica"
                autoComplete="off"
                append={[model?.ubicacion, ...appendUbicacion]}
                formulario={<UbicacionForm
                  input={true}
                  setItem={setAppendUbicacion}
                  subFondoAreaForm={selectedSubFondoArea}
                  tipoExpediente={"TRAMITE"}
                />}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={2}>
            <Form.Item name="delete" label="&nbsp;">
              <Tooltip title="Borrar Ubicación">
                <Button
                  icon={<DeleteOutlined/>}
                  block
                  onClick={() => {
                    setModalUbicacion(true)
                    setModalBorrarUbicacion(true)
                  }}
                  size="large"
                  style={{
                    background: "#ff0000",
                    color: "#fff",
                    border: "none",
                  }}
                />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        {/* Clasificación de la información | Periodo de reserva */}
        <Row gutter={10}>
          <Col className="gutter-row" span={8}>
            <Form.Item label="Clasificación de la Información" name="clasificacionInformacion">
              <Select
                size="large"
                loading={modelLoading}
                disabled={modelLoading}
                placeholder="Selecciona una Clasificación"
                onChange={setClasificacionInformacion}
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {clasificaciones.map((i) => (
                  <Option key={i.key} value={i.value}>
                    {i.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {clasificacionInformacion !== "P" &&
          clasificacionInformacion !== "C" && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Periodo de reserva (meses)"
                name="periodoReserva"
                rules={[
                  {
                    required: true,
                    message: "Escriba el periodo de reserva.",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  min={0}
                  max={60}
                  autoComplete="off"
                  style={{width: "100%"}}
                  onChange={(v) => {
                    if (v) {
                      setPeriodoReserva(v)
                    }
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {/* Fecha de clasificación | Fundamento Legal | Partes o secciones clasificadas */}
        {clasificacionInformacion !== "P" && (
          <Row gutter={10}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Fecha de Clasificación"
                name="fechaClasificacion"
                extra={
                  <span
                    hidden={clasificacionInformacion === "C"}
                    style={styles.extra}>
                      Fecha Desclasificación: {fechaDesclasificacion && moment(fechaDesclasificacion).format("DD/MM/YYYY")}
                    </span>
                }
              >
                <DatePicker
                  size="large"
                  autoComplete="off"
                  placeholder="Seleccione una Fecha"
                  format="DD/MM/YYYY"
                  style={{width: "100%"}}
                  onChange={(_, string) => {
                    const arr = string.split("/")
                    if (string && periodoReserva) {
                      const fechaDesclasificacion = moment(`${arr[2]}-${arr[1]}-${arr[0]} 00:00:00`).add(periodoReserva, 'month')
                      if (fechaDesclasificacion) {
                        setFechaDesclasificacion(fechaDesclasificacion)
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Fundamento Legal" name="fundamentoLegal">
                <Select
                  size="large"
                  mode="multiple"
                  placeholder="Fundamento Legal"
                  autoComplete="off"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={
                    padres.filter(padre => clasificacionInformacion === "C" ? padre.titulo === "Artículo 108" : padre.titulo === "Artículo 96").map(padre => ({
                      label: padre?.titulo,
                      options: articulos
                        .filter(f => f?.idArticuloPadre === padre?.id)
                        .map(articulo => ({
                          label: `${articulo?.titulo} - ${articulo?.descripcion}`,
                          value: articulo?.id,
                          key: articulo?.id
                        }))
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Partes o secciones clasificadas"
                name="partesClasificadas"
              >
                <Input
                  size="large"
                  placeholder="Partes o secciones clasificadas"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Tooltip>
                <UploadComponent
                  onChange={onChangeUploadReservada}
                  fileList={listaArchivosReservada}
                  listType="picture-card"
                  text={<><UploadOutlined/> Subir archivos</>}
                />
              </Tooltip>
            </Col>
          </Row>
        )}
        <Divider/>
        {/* Vigencia archivo trámite | Vicengia archivo de concentración */}
        <Row gutter={10}>
          <Col className="gutter-row" span={8}>
            <Form.Item label="Vigencia archivo en Trámite">
              {vigenciaAt ? `${vigenciaAt} años` : 'Vigencia Documental'}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Vigencia archivo de Concentración"
            >
              {vigenciaAC ? `${vigenciaAC} años` : 'Vigencia Documental'}
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        {/* Upload */}
        <Row gutter={10}>
          <Col span={24}>
            <Tooltip title={<p>Anexar información digital que tenga el expediente. Especificarla en descripción y en
              el tipo de expediente</p>}>
              <UploadComponent
                onChange={onChangeUpload}
                fileList={listaArchivos}
                listType="picture-card"
                text={<><UploadOutlined/> Subir archivos</>}
              />
            </Tooltip>
          </Col>
        </Row>
        <Divider/>
        {/* Botón de guardar */}
        <Row gutter={10}>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 5, offset: 19}}
            lg={{span: 5, offset: 19}}
            xxl={{span: 5, offset: 19}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default ArchivoTramite;
