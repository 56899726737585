import React, {useEffect, useMemo, useState} from "react";
import {
  Col,
  Row,
  Tag,
  Tabs,
  Form,
  Table,
  Input,
  Select,
  Button,
  Tooltip,
  DatePicker,
} from 'antd';
import {useHistory, Link} from 'react-router-dom';
import {
  EditOutlined,
  PlusOutlined,
  FileDoneOutlined,
  ClearOutlined,
  InboxOutlined,
  DeleteOutlined,
  SearchOutlined,
  PrinterOutlined,
  FileTextOutlined,
  FileExcelOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import moment from 'moment';

import ActionsButton from '../../components/actionsbutton/ActionsButton';
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useApp, useAuth, useModels, useQuery} from "../../hooks";
import {modalDelete} from "../../utilities";
import {reporteCorrespondencia} from "../../utilities/excel";
import StatusResponse from "../../services/statusResponse";

const {REACT_APP_API_URL: baseUrl} = process.env;
const {TabPane} = Tabs;

const Correspondencia = () => {

  const {RangePicker} = DatePicker;
  const {Option} = Select;
  const titulo = "Correspondencia";
  const history = useHistory();
  const [form] = Form.useForm();
  const {token} = useApp();
  const query = useQuery();
  const opt = query.get("opt");
  const _baseUrl = baseUrl.replace("v1/", "")

  const {user} = useAuth();

  const [request, setRequest] = useState({});
  const [tab, setTab] = useState('1');
  const [limite, setLimite] = useState(10);
  const [pagina, setPagina] = useState(1);

  const [buscar, setBuscar] = useState('');
  const [inicio, setInicio] = useState('');
  const [fin, setFin] = useState('');
  const [docTipo, setDocTipo] = useState(undefined);
  const [selectionType] = useState('checkbox');
  const [permisoCrear, setPermisoCrear] = useState(false);
  const [buscarParams, setBuscarParams] = useState({});
  const [importLoading, setImportLoading] = useState(false);
  const [actuales, setActuales] = useState(0);

  const extraParamsMemo = useMemo(() => {
    let params = {
      tipo: tab === '2' ? 'recibido' : 'elaborado',
    }

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio;
      params.fin = fin;
    }

    if (opt) {
      params.opt = opt;
    }

    if (docTipo) {
      params.docTipo = docTipo;
    }

    const permiso = user?.permisoExtra?.filter(permiso => permiso === 42);
    if (permiso?.length > 0) {
      params.permisoVer = 1;
    }

    setBuscarParams(params);
    return params

  }, [buscar, docTipo, fin, inicio, opt, tab, user?.permisoExtra]);

  const requestParams = useMemo(() => ({
    name: 'correspondencia',
    ordenar: "fecha-desc",
    extraParams: extraParamsMemo,
    limite: limite,
    pagina: pagina,
    expand: 'emisores,receptores,correspondenciaResponsable'
  }), [extraParamsMemo, limite, pagina])


  // Correspondencia enviados
  const [
    tipos, ,
  ] = useModels({
    name: 'documento-tipo',
    ordenar: 'nombre-desc',
    limite: -1,
  });

  const [
    Correspondencia,
    CorrespondenciaLoading, ,
    CorrespondenciaPage,
    CorrespondenciaRefresh
  ] = useModels(request)

  const onSetPageCallback = React.useCallback(
    async (page, size) => {
      setPagina(page);
      setLimite(size);
      await CorrespondenciaRefresh(true, {
        ordenar: 'fecha-desc',
        pagina: page,
        limite: size,
      })
    }, [CorrespondenciaRefresh]
  );

  const configPagination = useMemo(() => {
    if (CorrespondenciaPage) {
      let size = limite;

      return {
        total: CorrespondenciaPage.total,
        pageSize: CorrespondenciaPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}.`,
        locale: {items_per_page: '/ página'},
        pageSizeOptions: [10, 20, 30].filter(val => val <= CorrespondenciaPage.total),
        showSizeChanger: true
      }
    }
    return null;
  }, [onSetPageCallback, limite, CorrespondenciaPage]);

  const breadcrumbItems = [
    {name: titulo, to: '/', icon: <FileTextOutlined/>}
  ];

  const defaultText = prop => prop || <span style={{color: '#c7c3c3'}}>---</span>;


  const columnsSend = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          size="large"
          type="default"
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`/correspondencia/editar?id=${item.idCorrespondencia}`)
            },
            {
              name: 'Imprimir',
              icon: <PrinterOutlined/>,
              onClick: () => window.open(`${_baseUrl}pdf/reportes/correspondencia?id=${item.idCorrespondencia}Us-${user.idUsuario}&access-token=${token}`, '_blank')

            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalDelete(item?.folio, {idCorrespondencia: item?.idCorrespondencia}, 'correspondencia/eliminar', CorrespondenciaRefresh),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: '',
      dataIndex: 'idCreador',
      key: 'idCreador',
      render: (_, item) => {
        if (item?.estatus === "ENVIADO") {
          return <><Link to={`/correspondencia/editar?id=${item.idCorrespondencia}`}><FileDoneOutlined
            style={{fontSize: "22px"}}/> Enviado</Link></>
        }
        if (item?.estatus === "CANCELADO") {
          return <><Link to={`/correspondencia/editar?id=${item.idCorrespondencia}`}><DeleteOutlined
            style={{fontSize: "22px"}}/> Cancelado</Link></>
        }
        if (item?.estatus === "NUEVO") {
          return <><Link to={`/correspondencia/editar?id=${item.idCorrespondencia}`}><SolutionOutlined
            style={{fontSize: "22px"}}/>Borrador</Link></>
        }
      }
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      render: (_, item) => <Link
        to={`/correspondencia/editar?id=${item.idCorrespondencia}`}>{moment(item.fecha).format('DD-MM-YYYY')}</Link>
    },
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      render: (_, item) => <Link
        to={`/correspondencia/editar?id=${item.idCorrespondencia}`}>{item.folio}</Link> || defaultText
    },
    {
      title: 'Asunto',
      dataIndex: 'asunto',
      key: 'nombre',
      render: (_, item) => <Link
        to={`/correspondencia/editar?id=${item.idCorrespondencia}`}>{item.asunto}</Link> || defaultText
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Dirigido a',
      dataIndex: 'idAreaReceptora',
      key: 'idAreaReceptora',
      render: (_, item) => <Link
        to={`/correspondencia/editar?id=${item.idCorrespondencia}`}>{renderArea(item)}</Link> || defaultText
    },
  ];

  const renderArea = (item) => {
    const receptores = item?.receptores;
    return (
      <Tag color="magenta">Detalles: {receptores?.length} </Tag>
    )
  };


  const columnasExcel = [
    {
      title: 'folio',
      key: 'Folio',
      dataIndex: 'folio'
    },
    {
      title: 'Fecha',
      key: 'fecha',
      dataIndex: 'fecha'
    },
    {
      title: 'Asunto',
      key: 'asunto',
      dataIndex: 'asunto'
    },
    {
      title: 'Entidad emisora',
      key: 'areaEmisora',
      dataIndex: 'areaEmisora'
    },
    {
      title: 'Quien Firma',
      key: 'nombreFirmante',
      dataIndex: 'nombreFirmante'
    },
    {
      title: '',
      key: '',
      dataIndex: ''
    },
    {
      title: '',
      key: '',
      dataIndex: ''
    },
  ];

  const onPrintExcel = (values, columnasExcel) => {
    const {rango} = form.getFieldsValue(true);
    let _inicio = '';
    let _fin = '';

    if (rango) {
      _inicio = rango[0].format('YYYY-MM-DD');
      _fin = rango[1].format('YYYY-MM-DD');
    } else {
      _inicio = moment().startOf("month").format('YYYY-MM-DD');
      _fin = moment().endOf("month").format('YYYY-MM-DD');
    }

    const parametros = new URLSearchParams({
      inicio: _inicio,
      fin: _fin,
      'access-token': token
    }).toString();

    // Construye la URL del enlace usando los parámetros y el arreglo columnasExcel
    window.open(`${_baseUrl}pdf/reportes/excel-correspondencia?${parametros}`, '_blank')
  }


  const infoToExport = async () => {
    let obj = {
      expand: 'emisores,receptores,correspondenciaResponsable',
      ordenar: 'fecha-desc',
      extraParams: buscarParams
    };
    let flag = true;
    let pagina = 1, total = 0, limite = 100;
    let registros = [];
    setImportLoading(true);
    while (flag) {
      obj["pagina"] = pagina;
      obj["limite"] = limite;
      const parametros = new URLSearchParams(obj).toString();
      const res = await StatusResponse.get(`correspondencia?${parametros}`);
      const resultado = res?.resultado;
      registros = [
        ...registros,
        ...resultado
      ];
      let _paginacion = res?.paginacion;
      total = _paginacion?.total;

      const actual = pagina * limite;
      setActuales(actual);
      pagina = _paginacion?.pagina + 1;
      if (total < actual) {
        flag = false;
        setImportLoading(false);
      }
    }

    return [
      ...registros
    ]
  }


  const descargarExcel = async () => {
    await reporteCorrespondencia(
      columnasExcel,
      await infoToExport(),
      `CORRESPONDENCIA ${moment().format('DD-MM-YYYY')}`, //Nombre del archivo
      "Correspondencia", //Título
      "/logo.png", //path
    )
  }


  const SearchForm = () => {

    const onFinishButton = async values => {
      let params = {}
      const {buscar, rango, docTipo} = form.getFieldsValue(true);
      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }
      if (rango && rango.length > 0) {
        setInicio(
          moment(rango[0].format('YYYY-MM-DD 00:00:00')).format()
        );
        setFin(
          moment(rango[1].format('YYYY-MM-DD 23:59:59')).format()
        );
        params.inicio = moment(rango[0].format('YYYY-MM-DD 00:00:00')).format();
        params.fin = moment(rango[1].format('YYYY-MM-DD 23:59:59')).format();
      }
      if (docTipo) {
        setDocTipo(docTipo);
        params.docTipo = docTipo;
      }

    }

    return (
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={8}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item
              label=""
              name="buscar"
            >
              <Input
                size="large"
                disabled={CorrespondenciaLoading}
                placeholder="NOMBRE, CORREO, RFC, ETC..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item
              label=""
              name="rango"
            >
              <RangePicker
                size="large"
                disabled={CorrespondenciaLoading}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                placeholder={['FECHA INICIO', 'FECHA LÍMITE']}
                style={{width: '100%'}}
                defaultValue={[inicio, fin]}
                value={[inicio, fin]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item
              label=""
              name="docTipo"
            >
              <Select
                size="large"
                allowClear
                placeholder="SELECCIONE UN TIPO"
                style={{width: '100%',}}
                disabled={CorrespondenciaLoading}
                defaultValue={docTipo}
                value={docTipo}
              >
                {tipos?.map(item => (
                  <Option key={item?.idDocumentoTipo} value={item?.idDocumentoTipo}>{item?.nombre}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Tooltip title="Buscar">
              <Button
                icon={<SearchOutlined/>}
                type="primary"
                // htmlType="submit"
                loading={CorrespondenciaLoading}
                disabled={CorrespondenciaLoading}
                onClick={onFinishButton}
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                icon={<ClearOutlined/>}
                type="default"
                disabled={CorrespondenciaLoading}
                onClick={() => {
                  form.resetFields();
                  setBuscar('');
                  setInicio('');
                  setFin('');
                  setDocTipo(undefined);
                  CorrespondenciaRefresh();
                }}
                style={{minWidth: "50px", minHeight: "40px"}}
              />
            </Tooltip>
            <Tooltip title="Imprimir Excel">
              <Button
                icon={<FileExcelOutlined/>}
                onClick={onPrintExcel}
                style={{
                  background: "#1f6e43",
                  color: "#ffffff",
                  minWidth: "50px",
                  minHeight: "40px"
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form>
    )
  };

  useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);

  useEffect(() => {
    const permiso = user?.permisoExtra?.filter(permiso => permiso === 52);
    if (permiso?.length > 0) {
      setPermisoCrear(false);
    } else {
      setPermisoCrear(true);
    }
  }, [user?.permisoExtra])

  return (
    <SimpleTableLayout
      title={titulo}
      withSearchButton={false}
      breadcrumbItems={breadcrumbItems}
      emptyText='Aún no tiene Correspondencia.'
      children={
        <>
          <Row>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 20, offset: 0}}
              lg={{span: 20, offset: 0}}
              xxl={{span: 20, offset: 0}}
            >
              <SearchForm/>
            </Col>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 4, offset: 0}}
              lg={{span: 4, offset: 0}}
              xxl={{span: 4, offset: 0}}
            >
              <Button
                block
                disabled={permisoCrear}
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => history.push('/correspondencia/nuevo')}
                style={{minHeight: "40px"}}
              >
                Agregar
              </Button>
            </Col>
          </Row>
          <Tabs defaultActiveKey={tab} onChange={setTab}>
            <TabPane
              tab={<span> <FileDoneOutlined/> Elaborados </span>}
              key="1"
            >
            </TabPane>
            <TabPane
              tab={<span><InboxOutlined/>Recibidos</span>}
              key="2"
            >
            </TabPane>
          </Tabs>
          <Table
            key="Enviados"
            rowKey="idCorrespondencia"
            pagination={configPagination}
            dataSource={Correspondencia}
            loading={CorrespondenciaLoading}
            rowSelection={{
              type: selectionType
            }}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  Receptores: &nbsp;
                  {record?.receptores?.map(item => (
                    <Tag
                      key={item?.idCorrespondencia}>{item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno} </Tag>
                  ))}
                  <br/> <br/>
                  Emisores: &nbsp;
                  {record ? <Tag key={record?.idCorrespondencia}>
                    {record?.emisor} / {record?.areaEmisora}
                  </Tag> : null}
                </>
              ),
            }}
            columns={columnsSend}
            size='small'
            scroll={{x: 1000}}
          />
        </>
      }
    />
  );
}

export default Correspondencia;