import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModel, useQuery } from "../../../hooks";
import {
  ArrowLeftOutlined,
  SaveOutlined,
  DollarOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import StatusResponse from "../../../services/statusResponse";
import { ViewLoading } from "../../../components";
import { DefaultLayout } from "../../../components/layouts";

const EstatusRecursoDetalle = () => {
  const titulo = "Estatus Recursos";
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false);
  const [estatus, setEstatus] = useState(false);

  const { model, modelLoading } = useModel({
    name: "estatus-recurso",
    id: id,
  });

  const breadcrumbItems = [
    {
      name: "Estatus Recursos",
      to: "/gestion-general/estatus-recurso/",
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: `/gestion-general/estatus-recurso/${editing ? `editar?id=${id}` : "nuevo"}`,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push("/gestion-general/estatus-recurso/"),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: "primary" },
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idArea,
      });
      setEstatus(model.estatus === 1 ? true : false);
      console.log(model?.nombre);
    }
    return () => (mounted = false);
  }, [form, modelLoading, model]);

  const onFinish = async (values) => {
    const { nombre, clave, idEstatusRecurso } = values;
    let body = {
      nombre,
      clave,
      idEstatusRecurso,
    };
    if (editing) {
      body.idEstatusRecurso = id;
    }
    body.estatus = estatus === true ? 1 : 0;
    try {
      setLoading(true);
      const res = await StatusResponse.post("estatus-recurso/guardar", body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            title: "Error al guardar: datos incompletos.",
          });
          console.log(arrayError);
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: "20vh" },
          });
          history.push("/gestion-general/estatus-recurso");
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning("Error al guardar: datos incompletos.");
    console.log(values, errorFields, outOfDate);
  };

  if (modelLoading) return <ViewLoading />;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Nombre del Estatus"
              name="nombre"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input placeholder="Escribir Nombre" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Clave de sentido"
              name="clave"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input placeholder="Escribir clave" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{xs:10,sm:10,md:10,lg:10}}>
          <Col
            xs={{span:24,offset:0}}
            sm={{span:24,offset:0}}
            md={{span:6,offset:18}}
            lg={{span:6,offset:18}}
            xxl={{span:6,offset:18}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >Guardar</Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default EstatusRecursoDetalle;
