import React from "react";
import {
  Col,
  Row,
  Menu,
  Avatar,
  Layout, Tooltip,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../hooks";
import { dashboardRoutes } from "../../routers/routes";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import "../../styles/DashboardLayout.less";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

const rootSubmenuKeys = ["sub1", "sub2", "sub3"];

const DashboardLayout = ({ children: PanelContent }) => {

  const [state, setState] = React.useState({
    collapsed: false,
  });
  const [openKeys, setOpenKeys] = React.useState([""]);
  const [selectedKey, setSelectedKey] = React.useState("");
  const location = useLocation();
  const { user, userLoading, signOut } = useAuth();
  const history = useHistory();

  const { collapsed } = state;

  const onCollapse = (collapsed) => {
    setState({ collapsed });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const sidebarMapper = (route) => {
    if (route.sidebar === "single") {
      return (
        <Menu.Item
          key={route.path}
          onClick={() => setSelectedKey(route.path)}
          icon={route.icon}
          hidden={
            route.rol ? !route?.rol.includes(user?.rol) : (route.ver ? !user?.permisoExtra?.includes(route.ver) : false)
          }
        >
          <NavLink to={route.path}>{route.name}</NavLink>
        </Menu.Item>
      );
    } else if (route.sidebar === "collapse") {
      const innerMap = (r) => ({ ...r, path: route.path + r.path });
      const finalKey = "collapse-" + route.path;
      return (
        <SubMenu
          key={finalKey}
          icon={route.icon}
          title={route.name}
          hidden={
            route.rol ? !route?.rol.includes(user?.rol) : (route.ver ? !user?.permisoExtra?.includes(route.ver) : false)
          }
        >
          {route?.routes?.map(innerMap).map(sidebarMapper)}
        </SubMenu>
      );
    }
    return null;
  };

  React.useEffect(() => {
    const flatter = (r) =>
      r?.routes
        ? [
          r,
          ...r?.routes
            .map((sub) => ({ ...sub, path: r.path + sub.path }))
            .flatMap(flatter),
        ]
        : r;
    const flatted = dashboardRoutes.flatMap(flatter);

    const paths = flatted.map((r) => r.path);
    const key = paths.find((path) => path === location.pathname);
    // TODO: Hay algo extraño con las rutas base, revisar
    // const sub = key.substr(0, key.lastIndexOf("/"));
    // if (paths.some((p) => p.includes(sub))) {
    setSelectedKey(key);
    // }
    const tmpOpenKeys = flatted
      .filter(
        (r) => r.sidebar === "collapse" && location.pathname.includes(r.path)
      )
      .map((r) => "collapse-" + r.path);
    let _ret = collapsed ? [''] : tmpOpenKeys;
    setOpenKeys(_ret);
  }, [collapsed, location.pathname]);

  if (!user && userLoading) return null;
  
  return (
    <Layout style={{minHeight: "100vh"}}>
      <Sider
        id="auth-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
        width={256}
      >
        <img src={collapsed ? "/logoCollapsed.png" : "/logo.png"} style={{padding: 10 }} width={ collapsed ? "100%" : "200px"} alt="Logo" />
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          className="sider-menu"
          selectedKeys={selectedKey}
          inlineIndent={18}
        >
          {dashboardRoutes.map(sidebarMapper)}
          <Menu.Item
            key="single-/0"
            icon={<LogoutOutlined />}
            onClick={signOut}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0, zIndex: 9 }}>
          <Row justify="space-between" style={{ padding: "0px 10px" }}>
            <Col>
              <span style={{ fontSize: 10, color: "gray" }}> </span>
            </Col>
            <Col>
              <Row>
                <Col style={{ marginLeft: 10 }}>{user?.nombreCompleto}</Col>
                <Col style={{ marginLeft: 20 }}>{user?.correo}</Col>
                <Col style={{ marginLeft: 10 }}>
                  <Avatar 
                    style={{cursor: "pointer"}}
                    onClick={() => history.push("/perfil")}
                    size={42}
                    src={user?.urlFoto}
                    icon={<UserOutlined />}
                  />
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Tooltip
                    title={"Cerrar Sesión"}
                    placement="bottomRight"
                  >
                    <Avatar
                      style={{cursor: "pointer"}}
                      onClick={signOut}
                      shape="square"
                      size={42}
                      icon={<LogoutOutlined />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content style={{margin: "0 16px"}}>
          {PanelContent}
        </Content>
        <Footer style={{textAlign: "center"}}>
          SIAD { new Date().getFullYear() } - Sonora, México
        </Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
