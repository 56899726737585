import React, {useEffect, useState} from 'react';
import {DefaultLayout} from '../../components/layouts';
import {
  Form,
  Row,
  Col,
  Typography,
  Input,
  Divider,
  Upload,
  Tooltip,
} from 'antd';
import {SnippetsOutlined, UploadOutlined} from '@ant-design/icons';
import {useModel, useQuery, useApp} from '../../hooks';
import {Upload as UploadComponent, ViewLoading} from '../../components';
import moment from 'moment';

const {REACT_APP_API_URL: baseUrl} = process.env;

const ExpedienteBajaDetalle = () => {

  const q = useQuery();
  const id = q.get("id");

  const [form] = Form.useForm();
  const {Title} = Typography;
  const _baseUrl = baseUrl.replace("v1/", "");
  const {token} = useApp();

  const [request, setRequest] = useState({});
  const [clasificacionValue, setClasificacionValue] = useState('');
  const [listaArchivos, setListaArchivos] = useState([]);

  const requestParams = React.useMemo(() => ({
    name: 'expediente',
    id: id,
    expand: 'area,archivos,subfondoarea,fondo,seccion,serie, ubicacion'
  }), [id])

  const {
    model,
    modelLoading
  } = useModel(request);


  const breadcrumbItems = [
    {
      name: 'Expedientes en Concentración',
      to: '/archivo/concentracion',
      icon: <SnippetsOutlined/>,
    },
    {
      name: 'Detalle',
      to: `/archivo/concentracion/detalle?id=${id}`,
      icon: <SnippetsOutlined/>,
    },
  ];

  const destinofinal = (val) => {
    if (val === "D")
      return "Baja";
    else if (val === "CC")
      return "Historico";
    else if (val === "CF")
      return "Muestreo";
  }

  const setFormData = React.useCallback((data) => {
    console.log(data)
    form.setFieldsValue({
      fondo: data?.fondo.titulo,
      area: data?.subfondoarea.titulo,
      seccion: `${data?.seccion.codigo} - ${data?.seccion.titulo}`,
      serie: `${data?.serie.codigo} - ${data?.serie.titulo}`,
      asunto: data?.asunto,
      descripcion: data?.descripcion,
      fechaApertura: moment(data?.fechaApertura).format('DD/MM/YYYY'),
      fechaCierre: moment(data?.fechaCierre).format('DD/MM/YYYY'),
      legajosExpediente: data?.legajosExpediente,
      ubicacion: data?.ubicacion?.titulo,
      fechaClasificacion: data?.fechaClasificacion ? moment(data?.fechaClasificacion).format('DD/MM/YYYY') : "",
      fundamentoLegal: data?.fundamentoLegal,
      partesClasificadas: data?.partesClasificadas,
      periodoReserva: data?.periodoReserva,
      vigenciaAt: `${data?.vigenciaAt} años`,
      vigenciaAC: `${data?.vigenciaAC} años`

    });


    form.setFieldValue('valoracionPrimaria', data?.valoracionPrimaria?.split(',').map((item) => {
      if (item === 'A') {
        return 'Administrativo'
      } else if (item === 'L') {
        return 'Legal'
      } else if (item === 'CF') {
        return 'Fiscal o Contable'
      }
    }).toString().replace(",", ", "));

    form.setFieldValue('valoracionSecundaria', data?.valoracionSecundaria?.split(',').map((item) => {
      if (item === 'INFO') {
        return 'Informativo'
      } else if (item === 'ET') {
        return 'Evidencial / Testimonial'
      }
    }).toString().replace(",", ", "));


    if (data?.clasificacionInformacion === 'R') {
      form.setFieldsValue({
        clasificacionInformacion: 'Reservado'
      })
    } else if (data?.clasificacionInformacion === 'C') {
      form.setFieldsValue({
        clasificacionInformacion: 'Confidencial'
      })
    } else if (data?.clasificacionInformacion === 'P') {
      form.setFieldsValue({
        clasificacionInformacion: 'Público'
      })
    }

    if (data?.tipoExpediente === 'PO') {
      form.setFieldValue('tipoExpediente', 'Papel/Original')
    } else if (data?.tipoExpediente === 'DE') {
      form.setFieldValue('tipoExpediente', 'Digital/Electrónico')
    } else if (data?.tipoExpediente === 'AU') {
      form.setFieldValue('tipoExpediente', 'Audiovisual')
    }
    form.setFieldValue('idUbicacion', data?.ubicacion?.descripcion)


    setClasificacionValue(data?.clasificacionInformacion);
  }, [form])

  useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);

  useEffect(() => {
    if (model) {
      setFormData(model);
      let _listaArchivos = model?.archivos?.map(item => {
        return {
          name: item?.descripcion,
          uid: item?.uuid,
          url: `${_baseUrl}/v1/descarga/documento?uuid=${item?.uuid}&access-token=${token}&t=exp`,
          status: 'done',
          thumbUrl: `${item?.ruta}`,
        }
      }).filter(function (x) {
        return x !== undefined;
      });

      setListaArchivos(_listaArchivos !== undefined ? _listaArchivos : []);
    }
  }, [_baseUrl, model, setFormData, token])

  if (modelLoading) return (<ViewLoading/>);

  return (
    <>
      <DefaultLayout
        title={`Detalle de Expediente ${model?.folio}`}
        breadcrumbItems={breadcrumbItems}
      >
        <Form
          form={form}
          name="form"
          layout='vertical'
        >
          <Row gutter={10}>
            <Col span={12}>
              <Title level={4}>
                Folio: {model?.folio}
              </Title>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 12, offset: 0}}
              lg={{span: 12, offset: 0}}
              xxl={{span: 12, offset: 0}}
            >
              <Form.Item
                label='Fondo'
                name='fondo'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 12, offset: 0}}
              lg={{span: 12, offset: 0}}
              xxl={{span: 12, offset: 0}}
            >
              <Form.Item
                label='Área'
                name='area'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row gutter={[6, 0]}>
            <Col
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 12, offset: 0}}
              lg={{span: 12, offset: 0}}
              xxl={{span: 12, offset: 0}}
            >
              <Form.Item
                label='Sección'
                name='seccion'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 12, offset: 0}}
              lg={{span: 12, offset: 0}}
              xxl={{span: 12, offset: 0}}
            >
              <Form.Item
                label='Serie'
                name='serie'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 24, offset: 0}}
              lg={{span: 24, offset: 0}}
              xxl={{span: 24, offset: 0}}
            >
              <Form.Item
                name='asunto'
                label='Asunto'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col
              xs={{span: 24, offset: 0}}
              sm={{span: 24, offset: 0}}
              md={{span: 24, offset: 0}}
              lg={{span: 24, offset: 0}}
              xxl={{span: 24, offset: 0}}
            >
              <Form.Item
                name="descripcion"
                label="Descripción del Expediente"
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row gutter={[6, 0]}>
            <Col
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 12, offset: 0}}
              lg={{span: 12, offset: 0}}
              xxl={{span: 12, offset: 0}}
            >
              <Form.Item
                label='Fecha de Apertura'
                name='fechaApertura'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 12, offset: 0}}
              lg={{span: 12, offset: 0}}
              xxl={{span: 12, offset: 0}}
            >
              <Form.Item
                label='Fecha de Cierre'
                name='fechaCierre'
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          {/* Número de Fojas | Legajos */}
          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={[12]}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                  <Title level={5}><Tooltip
                    title={<p>Es el conjunto de documentos que están unidos, deberá asignarle un numero consecutivo
                      dependiendo de la cantidad de legajos que requiera el expediente.<br/><br/>
                      La cantidad total de legajos que se integran en el expediente, por ejemplo: legajo 1 de 3
                    </p>}>Legajos</Tooltip></Title>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Title level={5}><Tooltip title='Cantidad de hojas que contiene el legajo'>Número de
                    Fojas</Tooltip></Title>
                </Col>
                <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                </Col>
              </Row>
              <Form.List name="legajosExpediente">
                {(fields, {add, remove}) => (
                  <>
                    {fields.map(({key, name, ...restField}) => (
                      <Row gutter={[12]} key={"legajosExpediente-" + key}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "legajos"]}
                          >
                            <Input
                              size='large'
                              placeholder="Legajos"
                              readOnly bordered={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "numeroHojas"]}
                          >
                            <Input
                              size='large'
                              placeholder="Número de Fojas"
                              readOnly bordered={false}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Divider/>
          {/* Valoración primaria | Ubicación del Expiendente | [+] */}
          <Row gutter={10}>
            <Col className="gutter-row" span={7}>
              <Form.Item
                label={<Tooltip title='Seleccionar de la lista  el tipo de valor que tiene la documentación que integra el
              expediente, para determinar su utilidad y asignarle el tiempo y espacio de conservación en los diferentes
              archivos'>Valoración Primaria</Tooltip>}
                name="valoracionPrimaria"
              >
                {/*<Select
                  size="large"
                  loading={modelLoading}
                  disabled={modelLoading}
                  mode='multiple'
                  placeholder="Seleccionar Valoración primaria"
                >
                  {opcionesValoracion.map((item) => (
                    <Option key={item?.key} value={item?.value}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>*/}
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={{span: 12, offset: 0}}
              sm={{span: 12, offset: 0}}
              md={{span: 8, offset: 0}}
              lg={{span: 8, offset: 0}}
              xxl={{span: 8, offset: 0}}
              //hidden={mostrarVS}
            >
              <Form.Item
                label={<Tooltip title='Seleccionar de la lista  el tipo de valor que tiene la documentación que integra el
              expediente, para determinar su utilidad y asignarle el tiempo y espacio de conservación en los diferentes
              archivos'>Valoración Secundaria</Tooltip>}
                name="valoracionSecundaria"
              >
                {/*<Select
                  mode="multiple"
                  size="large"
                  options={selectValSec}
                />*/}
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={7}>
              <Form.Item
                label="Soporte"
                name="tipoExpediente"
              >
                {/*<Select
                  size="large"
                  optionFilterProp="children"
                  mode="multiple"
                  showSearch
                  allowClear
                  listItemHeight={10}
                  listHeight={250}
                  style={{width: "100%"}}
                  options={[
                    {value: "PO", label: "Papel/Original"},
                    {value: "DE", label: "Digital/Electrónico"},
                    {value: "AU", label: "Audiovisual"}
                  ].map(item => ({key: item.value, value: item.value, label: item.label}))}
                />*/}
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label={<Tooltip title={<>Especificar la ubicación con las siguientes denominaciones:<br/>
                  A- archivero<br/>
                  C. Cajón 1 (se cuenta de arriba hacia abajo)<br/>
                  E- estante<br/>
                  CR- credenza<br/>
                  L – Librero</>}
                >Signatura Topográfica</Tooltip>}
                name="idUbicacion"
              >
                { /*<Select
                  size="large"
                  optionFilterProp="children"
                  showSearch={true}
                  disabled={!((model?.idCreador === user?.idUsuario) || !editing)}
                  allowClear={true}
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input?.toLowerCase())
                  }
                  listItemHeight={10}
                  listHeight={250}
                  placeholder="Seleccionar una Ubicación"
                  onSearch={buscarUbicaciones}
                  style={{width: "100%"}}
                >
                  {ubicacionesSelect?.map((item, index) => (
                    <Option key={index} value={item?.idUbicacion}>
                      {item?.titulo}
                    </Option>
                  ))}
                </Select>*/}
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          {/* Clasificación de la información | Periodo de reserva */}
          <Row gutter={10}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Clasificación de la Información" name="clasificacionInformacion">
                { /*<Select
                  size="large"
                  loading={modelLoading}
                  disabled={modelLoading}
                  placeholder="Selecciona una Clasificación"
                  onChange={setClasificacionInformacion}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {clasificaciones.map((i) => (
                    <Option key={i.key} value={i.value}>
                      {i.label}
                    </Option>
                  ))}
                </Select>*/}
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Destino Final">
                <Input readOnly value={destinofinal(model?.serie?.destinoFinal)} bordered={false}/>
              </Form.Item>
            </Col>
            {clasificacionValue !== "P" &&
            clasificacionValue !== "C" && (
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label="Periodo de reserva (meses)"
                  name="periodoReserva"
                  rules={[
                    {
                      required: true,
                      message: "Escriba el periodo de reserva.",
                    },
                  ]}
                >
                  {/*<InputNumber
                    size="large"
                    min={0}
                    max={60}
                    autoComplete="off"
                    style={{width: "100%"}}
                    onChange={(v) => {
                      if (v) {
                        setPeriodoReserva(v)
                      }
                    }}
                  />*/}
                  <Input readOnly bordered={false}/>
                </Form.Item>
              </Col>
            )}
          </Row>
          {/* Fecha de clasificación | Fundamento Legal | Partes o secciones clasificadas */}
          {clasificacionValue !== "P" && (
            <Row gutter={10}>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Fecha de Clasificación"
                  name="fechaDesclasificacion"
                >
                  <Input readOnly bordered={false}/>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Fecha de Clasificación"
                  name="fechaClasificacion"
                >
                  <Input readOnly bordered={false}/>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item label="Fundamento Legal" name="fundamentoLegal">
                  {/*<Select
                    size="large"
                    mode="multiple"
                    placeholder="Fundamento Legal"
                    autoComplete="off"
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    listItemHeight={10}
                    listHeight={250}
                    options={
                      padres.filter(padre => clasificacionInformacion === "C" ? padre.titulo === "Artículo 108" : padre.titulo === "Artículo 96").map(padre => ({
                        label: padre?.titulo,
                        options: articulos
                          .filter(f => f?.idArticuloPadre === padre?.id)
                          .map(articulo => ({
                            label: `${articulo?.titulo} - ${articulo?.descripcion}`,
                            value: articulo?.id,
                            key: articulo?.id
                          }))
                      }))
                    }
                  />*/}
                  <Input readOnly bordered={false}/>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Partes o secciones clasificadas"
                  name="partesClasificadas"
                >
                  {/*  <Input
                    size="large"
                    placeholder="Partes o secciones clasificadas"
                    autoComplete="off"
                  /> */}
                  <Input readOnly bordered={false}/>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider/>
          {/* Vigencia archivo trámite | Vicengia archivo de concentración */}
          <Row gutter={10}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Vigencia archivo en Trámite" name={'vigenciaAt'}>
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Vigencia archivo de Concentración"
                name={'vigenciaAC'}
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          {/* Upload */}
          {/* Upload */}
          <Row gutter={10}>
            <Col span={24}>
              <Tooltip title={<p>Anexar información digital que tenga el expediente. Especificarla en descripción y en
                el tipo de expediente</p>}>
                <UploadComponent
                  fileList={listaArchivos}
                  listType="picture-card"
                  mostrarSubida={false}
                  text={<><UploadOutlined/> Subir archivos</>}
                />
              </Tooltip>
            </Col>
          </Row>
          <Divider/>
        </Form>
      </DefaultLayout>
    </>
  );
}

export default ExpedienteBajaDetalle