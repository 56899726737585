import React, {useState, useMemo} from 'react'

import {
  Modal
} from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  ProfileOutlined,
  PlusOutlined, CloseCircleOutlined, SettingOutlined, DatabaseOutlined,
} from '@ant-design/icons';

import {useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import StatusResponse from "../../../services/statusResponse";
import {Tabla as TableComponent} from "../../../components";

const UbicacionListado = () => {

  const titulo = "Ubicaciones";
  const orden = "tipoExpediente"
  const url = '/gestion-general/archivo/ubicacion';
  const primaryKey = "idUbicacion";

  const tablaRef = React.useRef();
  const history = useHistory();
  const [buscar, setBuscar] = useState(undefined);

  const extraParamsMemo = useMemo(() => {
    let params = {}
    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }
    return params

  }, [buscar]);

  const breadcrumbItems = [
    {
      name: 'Administración',
      icon: <SettingOutlined/>
    },
    {
      name: 'Archivo',
      icon: <DatabaseOutlined/>
    },
    {
      name: titulo,
      to: url,
      icon: <ProfileOutlined/>,
    },
  ];

  const buttonData = {
    text: "Agregar Ubicación",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const defaultText = (prop, item) => prop ? <a onClick={() => history.push(`${url}/editar?id=${item[primaryKey]}`)}>
    {prop}</a> : <span style={{color: '#c7c3c3'}}>---</span>;
  ;

  const columns = [

    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item.idUbicacion}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: '#',
      dataIndex: 'idUbicacion',
      key: 'idUbicacion',
      render: defaultText,
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      render: defaultText,
    },
    {
      title: 'Area Archivo',
      dataIndex: 'idSubFondoArea',
      key: 'idSubFondoArea',
      render: (_, item) =>  defaultText(item?.subFondoArea?.titulo, item),
    },
    {
      title: 'Tipo de ubicacion',
      dataIndex: 'tipoExpediente',
      key: 'tipoExpediente',
      render: defaultText,
    },
  ];

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar la partida "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("/ubicacion/eliminar", {idPartida: valor.idPartida});
        return new Promise((resolve, reject) => {
          try {
            if (valor.idPartida > 0) {
              if (res && res.status === 200) {
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar la partida",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const onSearch = async (search) => {
    setBuscar(search);
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      onSearchClicked={onSearch}
      emptyText='Aún no hay Ubicaciones registradas.'
      children={
        <>
          <TableComponent
            nameURL='ubicacion'
            columns={columns}
            rowKey={primaryKey}
            innerRef={tablaRef}
            order={orden}
            expand={'area, subFondoArea'}
            extraParams={extraParamsMemo}
          />
        </>
      }
    />
  )
}

export default UbicacionListado