import {Modal, message, notification} from "antd";
import {DeleteOutlined, CloseCircleOutlined} from '@ant-design/icons';
import StatusResponse from "../services/statusResponse";
import {InformacionArchivos} from "./InformacionArchivos";
import {RenderEstatusSolicitudPrimaria} from "./RenderEstatusSolicitudPrimaria";
import {AgregarFaltantes} from "./AgregarFaltantes";
import {Respuestas} from "./Respuestas";
import {ValidarPermisosVista} from "./ValidarPermisosVista";
import {inventarioConcentracion} from "./inventarioConcentracion";
import {estatusExpediente} from "./estatusExpediente";
import {obtenerExtensionImagen} from "./obtenerExtencionImagen";


const capitalizeFirst = (string) => {
  const split = string.split("-");
  let palabraUnida = "";
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1);
  });
  return palabraUnida;
};

const lastPathName = () => {
  const url = window.location.pathname;
  return {
    lastPath: url.split("/").pop(), // cambiar por pathname
    beforePath: url.split("/")[url.split("/").length - 2]
  };
};

const agregarFaltantes = (data, newData, campo) => {
  let ids = data.map(item => item[campo]);
  let aux = [...data];
  for (let i in newData) {
    let modelo = newData[i];
    if (!modelo || !modelo[campo]) {
      continue
    }
    const indice = ids.indexOf(modelo[campo]);
    if (modelo && indice === -1) {
      aux.push(modelo);
    } else {
      aux[indice] = modelo;
    }
  }
  return aux;
};

const modalDelete = (nombre, body, url, refreshModels) => {
  if (!body) return;
  Modal.confirm({
    title: "Eliminar",
    content: `¿Está seguro de eliminar "${nombre}"?`,
    icon: <DeleteOutlined style={{color: '#ff0000'}}/>,
    okText: 'Eliminar',
    okButtonProps: {
      type: 'danger',
    },
    cancelText: 'Cancelar',
    onOk: async () => {
      try {
        const res = await StatusResponse.delete(url, body)
        if (res && res.status === 200) {
          message.success(res?.mensaje);
          refreshModels();
        } else if (res?.status === 400) {
          Modal.warning({
            title: "Atención: no se logro eliminar el registro.",
            content: res?.mensaje,
          });
        } else if (res?.status === 500) {
          Modal.error({
            title: "Error: no se logro eliminar el registro.",
            content: res?.mensaje,
          });
        }
      } catch (error) {
        console.log(error);
        message.error(`Error al guardar: ${error} `);
      }
    },
  });
};

const cambiarEstatusExpediente = async (modelo, estatus, refreshModels) => {
  try {
    let body = {
      ...modelo,
      estatus: estatus,
    }

    const res = await StatusResponse.post('expediente/guardar', body);
    if (Respuestas(res)) {
      refreshModels()
    }
  } catch (e) {
    Respuestas();
    console.log(e)
  }
}

const modalEliminar = (idProp, idVal, descripcion, endpoint, tablaRef,) => {
  if (!idProp) return;
  if (!idVal) return;
  Modal.confirm({
    title: "Eliminar",
    icon: <CloseCircleOutlined style={{color: "red"}}/>,
    content: `¿Desea eliminar "${descripcion}"?`,
    okText: "Sí",
    cancelText: "No",
    okButtonProps: {danger: true},
    closable: true,
    onOk: async () => {
      const res = await StatusResponse.delete(`${endpoint}/eliminar`, {[idProp]: idVal});
      return new Promise((resolve, reject) => {
        try {
          if (res && res.status === 200) {
            tablaRef?.current?.refresh();
            resolve();
          } else if (res?.status === 400) {
            Modal.error({
              title: "Error: no se logro eliminar la ponencia",
              content: res?.mensaje,
            });
            reject();
          }
          reject();

        } catch (error) {
          console.log(error);
        }
      })
    },
  });
};


const eliminarRegistro = (nombre, id, url, alTerminar) => {
  if (!id) return;
  Modal.confirm({
    title: "Eliminar",
    content: `¿Está seguro de eliminar "${nombre}"?`,
    icon: <DeleteOutlined style={{color: '#ff0000'}}/>,
    okText: 'Eliminar',
    okButtonProps: {
      type: 'danger',
    },
    cancelText: 'Cancelar',
    onOk: async () => {
      try {
        const res = await StatusResponse.delete(url, {id: id});
        if (res && res.status === 200) {
          notification.success({
            message: 'Éxito',
            description: res?.mensaje
          });
          alTerminar && alTerminar();
        } else if (res?.status === 400) {
          notification.error({
            message: "Atención",
            description: res?.mensaje,
          });
        }
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Error',
          description: error,
        });
        return 'error';
      }
    },
  });
};

const respuestas = (res) => {
  if (res?.status === 400 && res?.errores !== null) {
    const newArray = Object.values(res?.errores);
    Modal.error({
      title: res?.mensaje,
      content: (
        <div>
          {newArray.map((m, i) => (
            <span key={i + 1}> -{m} <br/></span>
          ))}
        </div>
      ),
    });
    return false;
  } else if (res?.status === 400 && res?.errores === null) {
    message.error({
      content: res?.mensaje,
    });
    return false;
  } else if (res?.status === 200) {
    message.success({
      content: res?.mensaje,
    });
    return true;
  }
};

const roles = [
  {key: 'ADMIN', label: 'ADMINISTRADOR'},
  {key: 'RGA', label: 'RESPONSABLE GENERAL DE ARCHIVO'},
  {key: 'RAC', label: 'RESPONSABLE DE ARCHIVO DE CONCENTRACION'},
  {key: 'RAT', label: 'RESPONSABLE DE ARCHIVO DE TRÁMITE'},
  {key: 'USUARIO', label: 'USUARIO'},
];


const GetMesTexto = (number, corto = false) => {
  let mes = "";
  let mesCorto = "";
  let numero = parseInt(number)

  if (numero === 1) {
    mes = "Enero";
    mesCorto = "Ene";
  }
  if (numero === 2) {
    mes = "Febrero";
    mesCorto = "Feb";
  }
  if (numero === 3) {
    mes = "Marzo";
    mesCorto = "Mar";
  }
  if (numero === 4) {
    mes = "Abril";
    mesCorto = "Abr";
  }
  if (numero === 5) {
    mes = "Mayo";
    mesCorto = "May";
  }
  if (numero === 6) {
    mes = "Junio";
    mesCorto = "Jun";
  }
  if (numero === 7) {
    mes = "Julio";
    mesCorto = "Jul";
  }
  if (numero === 8) {
    mes = "Agosto";
    mesCorto = "Ago";
  }
  if (numero === 9) {
    mes = "Septiembre";
    mesCorto = "Sep";
  }
  if (numero === 10) {
    mes = "Octubre";
    mesCorto = "Oct";
  }
  if (numero === 11) {
    mes = "Noviembre";
    mesCorto = "Nov";
  }
  if (numero === 12) {
    mes = "Diciembre";
    mesCorto = "Dic";
  }

  if (corto)
    return mesCorto;
  else
    return mes;
}


export {
  capitalizeFirst,
  modalDelete,
  eliminarRegistro,
  respuestas,
  InformacionArchivos,
  Respuestas,
  cambiarEstatusExpediente,
  roles,
  estatusExpediente,
  GetMesTexto,
  modalEliminar,
  agregarFaltantes,
  lastPathName,
  AgregarFaltantes, ValidarPermisosVista,
  RenderEstatusSolicitudPrimaria,
  inventarioConcentracion,
  obtenerExtensionImagen,
};
